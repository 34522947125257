import React, { useEffect, useState, Fragment } from "react";
import { Modal, Spin, message, Breadcrumb, Empty } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import cx from "classnames";
import styles from "./style.less";

export default function RankModal(props) {
  console.log(props, "props");
  const [breadList, setBreadList] = useState([]);
  const [records, setRecords] = useState([]);
  const {
    modalVisible,
    handleClose,
    detailParams,
    modalProps: { items, canDrillDown, name, schemeId },
  } = props;
  const {
    cycleType,
    cycleValue,
    employId: dataId,
    startTime,
    endTime,
    structId,
  } = detailParams;
  const handleOpenPersonInfo = (item, schemeInfo) => {
    const { schemeId } = schemeInfo;
    const { name, dataId = "yin.zengfeng@scimall.org.cn" } = item;
    window.open(
      `/front/rank-achi-detail?cycleType=${cycleType}&cycleValue=${cycleValue}&employId=${dataId}&schemeId=${schemeId}&name=${name}&startTime=${startTime}&endTime=${endTime}&structId=${structId}`
    );
  };
  const renderBasicScheme = (items) => {
    return (
      <div className={styles.basicScheme}>
        {items.length ? (
          <Fragment>
            {" "}
            <div className={styles.name}>{name}</div>
            {items.map((it, ind) => {
              return (
                <div className={styles.personInfo}>
                  <div className={styles.person}>{it.name}</div>
                  <div
                    className={styles.score}
                    onClick={() => {
                      handleOpenPersonInfo(it, { schemeId });
                    }}
                  >
                    {it.score}
                  </div>
                </div>
              );
            })}
          </Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Empty style={{ width: "100%" }} />
          </div>
        )}
      </div>
    );
  };
  const getDetail = (params = {}, isSaveBreadList) => {
    props.ajax
      .post("/api/achievement/scheme/drillDown", {
        schemeId: params?.schemeId,
        cycleType,
        cycleValue,
        dataId: dataId,
      })
      .then((res) => {
        if (!res.data) {
          message.info("暂无数据");
          return false;
        }
        const { records = [] } = res.data || [];
        if (isSaveBreadList) {
          setBreadList([
            ...breadList,
            {
              schemeId: params.schemeId,
              cycleType,
              cycleValue,
              dataId,
              canDrillDown: params.canDrillDown,
              name: params.name,
            },
          ]);
        }
        setRecords(records);
      });
  };
  const handleClickBread = (item, index) => {
    const currentList = breadList.slice(0, index + 1);
    setBreadList(currentList);
    handleDrill(item, false);
    if (index !== 0) {
      setTimeout(() => {
        setBreadList(currentList);
      }, 200);
    }
  };
  const handleDrill = (item, isSaveBreadList) => {
    const { schemeId, name, canDrillDown } = item;
    const params = {
      schemeId,
      name,
      canDrillDown,
    };
    if (canDrillDown) {
      getDetail(params, isSaveBreadList);
    }
  };
  useEffect(() => {
    const params = {
      schemeId: schemeId,
    };
    getDetail(params);
  }, []);

  const renderContent = () => {
    const renderItems = (it) => {
      const { items = [], canDrillDown, schemeId } = it;
      if (!canDrillDown) {
        if (items.length) {
          return (
            <div>
              {it.items.map((item, index) => {
                return (
                  <div key={index} className={styles.person}>
                    <span className={styles.child}>{item.name}</span>
                    <span
                      className={styles.score}
                      onClick={() => {
                        handleOpenPersonInfo(item, { schemeId });
                      }}
                    >
                      {item.score}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        }
      }
    };
    return (
      <div className={styles.content}>
        {records.length ? (
          <Fragment>
            {records.map((it, ind) => {
              return (
                <div className={styles.item} key={ind}>
                  <div
                    className={cx(
                      styles.name,
                      it.canDrillDown ? styles.canDrillDown : ""
                    )}
                  >
                    {it.canDrillDown ? (
                      <div>
                        <span
                          style={{
                            color: "#000",
                            cursor: "default",
                          }}
                        >
                          {it.name}：
                        </span>
                        <span
                          style={{
                            color: "#000",
                            cursor: "default",
                            marginRight: 10,
                          }}
                        >
                          {it.score}
                        </span>
                        <DoubleRightOutlined
                          onClick={() => {
                            handleDrill(it, true);
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <span>{it.name}</span>
                      </div>
                    )}
                  </div>
                  {renderItems(it)}
                </div>
              );
            })}
          </Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Empty style={{ width: "100%" }} />
          </div>
        )}
      </div>
    );
  };
  /**
   *  渲染面包屑数据
   */
  const renderBread = () => {
    return (
      <Breadcrumb separator="/">
        {breadList.map((item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                handleClickBread(item, index);
              }}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };
  return (
    <Modal
      width={800}
      title="绩效方案钻取"
      visible={modalVisible}
      onCancel={handleClose}
      footer={null}
    >
      {canDrillDown ? (
        <div className={styles.achievementDetailContent}>
          <div>{renderBread()}</div>
          <div>{renderContent()}</div>
        </div>
      ) : (
        <div>{renderBasicScheme(items)}</div>
      )}
    </Modal>
  );
}
