import React, { useEffect, useState } from 'react';
import styles from './style.less';
import { Spin, Empty, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatNewChartViewData } from './utils';
import { handeNewDrillData } from '../../../profile-detail/utils';
import { UniveralEcharts } from '../../../../components';

export default function ChartItem(props) {
	const {
		requestParams = {},
		itemProps: { pageChartId, name, type },
		profileTitle,
		activeInd,
		itemProps,
	} = props;
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState({});
	useEffect(() => {
		setLoading(true);
		// 这里会根据每个图表的id去请求数据,然后去处理
		props.ajax
			.post('/profile/chart/data', {
				pageChartId: pageChartId,
				startTime: moment(requestParams.dates[0]).format('YYYY-MM-DD HH:mm:ss'),
				endTime: moment(requestParams.dates[1]).endOf('D').format('YYYY-MM-DD HH:mm:ss'),
				interval: Number(requestParams.cycleType),
				departmentId: requestParams?.departmentId || '',
				employees: requestParams?.employees || [],
			})
			.then(res => {
				if (res && res.code === 0 && res?.data?.series?.length !== 0) {
					setLoading(false);
					const { data = {}, map = {} } = res;
					data.type = type;
					let finalData;
					// 饼图不需要处理数据
					if (type !== 'pie' && type !== 'barAndLineMix') {
						finalData = handeNewDrillData(data, map);
					} else {
						finalData = data;
					}
					const { option = {} } = formatNewChartViewData(finalData, map);
					setOptions({ ...option });
				} else {
					setLoading(false);
					setOptions({});
				}
			});
	}, [pageChartId, props.ajax, requestParams, type]);
	const renderEmpty = () => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					height: '100%',
					width: '100%',
				}}
			>
				<Empty style={{ width: '100%' }} description="暂无数据" />
			</div>
		);
	};
	return (
		<div className={styles.chartItem}>
			<Spin spinning={loading}>
				<div
					className={styles.chartTitle}
					onClick={() => {
						const startTime = moment(requestParams.dates[0]).format('YYYY-MM-DD');
						const endTime = moment(requestParams.dates[1]).format('YYYY-MM-DD');
						const interval = Number(requestParams.cycleType);
						const departmentId = requestParams.departmentId || '';
						const employees = requestParams.employees?.join(',');
						window.open(
							`/front/profile-detail?pageChartId=${itemProps.pageChartId}&type=${itemProps.type}&activeInd=${activeInd}&startTime=${startTime}&endTime=${endTime}&name=${itemProps.name}&profileTitle=${profileTitle}&interval=${interval}&departmentId=${departmentId}&employees=${employees}`,
							'_blank'
						);
					}}
				>
					<Tooltip title="点我下钻">
						<span className={styles.name}>{name}</span>
						<RightOutlined className={styles.rightArrow} />
					</Tooltip>
				</div>
				<div className={styles.chartContent}>
					{Object.keys(options).length === 0 ? (
						renderEmpty()
					) : (
						<UniveralEcharts
							style={{ height: '90%', width: '100%' }}
							clickEvent={params => {
								console.log(params, 'killport 端口号');
							}}
							option={options}
						/>
					)}
				</div>
			</Spin>
		</div>
	);
}
