import React, { useEffect, useRef, useState } from "react";
import styles from "./style.less";
import { Graph, Cell, Node, Path, Markup } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import "@antv/x6-react-shape";
import { handleData, hanldeRoot, handleAssembleData } from "./utils";

const TopicNode = (props) => {
  console.log(props, "props");
  const { node } = props;
  const data = node?.getData();
  console.log(data, "data");
  return (
    <div className={styles.parentNode}>
      {/* <img src={image.logo} alt="logo" /> */}
      <span className="label">topic</span>
    </div>
  );
};
const TopicCHNode = (props) => {
  const { node } = props;
  const data = node?.getData();

  return (
    <div className={styles.childNode}>
      {/* <img src={image.logo} alt="logo" /> */}
      <span className="label">23782738</span>
    </div>
  );
};

export default function NewGraph(props) {
  const { dataSource } = props;
  const containerRef = useRef(null);
  const graphRef = useRef(null);
  const data = handleAssembleData(dataSource);
  Graph.registerConnector(
    "mindmap",
    (sourcePoint, targetPoint, routerPoints, options) => {
      const midX = sourcePoint.x + 80;
      const midY = sourcePoint.y;
      const ctrX = (targetPoint.x - midX) / 5 + midX;
      const ctrY = targetPoint.y;
      const pathData = `
           M ${sourcePoint.x} ${sourcePoint.y}
           L ${midX} ${midY}
           Q ${ctrX} ${ctrY} ${targetPoint.x} ${targetPoint.y}
          `;
      return options.raw ? Path.parse(pathData) : pathData;
    },
    true
  );
  // 边
  Graph.registerEdge(
    "mindmap-edge",
    {
      inherit: "edge",
      router: {
        name: "manhattan",
        args: {
          padding: 1,
        },
        startDirections: ["right"],
        endDirections: ["left"],
      },
      connector: {
        name: "normal",
      },
      attrs: {
        line: {
          targetMarker: "",
          stroke: "blue",
          strokeWidth: 2,
        },
      },
      zIndex: 0,
    },
    true
  );
  Graph.registerEdge(
    "bpmn-edge",
    {
      inherit: "edge",
      attrs: {
        line: {
          stroke: "#A2B1C3",
          strokeWidth: 1,
          sourceMarker: {
            args: { r: 2 },
            name: "circle",
          },
          targetMarker: {
            args: { size: 6 },
            name: "",
          },
        },
      },
    },
    true
  );
  const render = () => {
    const result = Hierarchy.mindmap(data, {
      direction: "H",
      getHeight(d) {
        return d.height;
      },
      getWidth(d) {
        return d.width;
      },
      getHGap() {
        return 40;
      },
      getVGap() {
        return 20;
      },
      getSide: () => {
        return "right";
      },
    });
    console.log(result, "result");
    const cells = [];
    console.log(graphRef.current, "graphRef.current");
    const traverse = (hierarchyItem) => {
      if (hierarchyItem) {
        const { data, children } = hierarchyItem;
        cells.push(
          graphRef.current.createNode({
            id: data.id,
            shape: data.type === "topic-child" ? "topic-child" : "topic",
            x: hierarchyItem.x,
            y: hierarchyItem.y,
            width: data.width,
            height: data.height,
            label: data.label,
            type: data.type,
          })
        );
        if (children) {
          children.forEach((item) => {
            const { id, data } = item;
            cells.push(
              graphRef.current.createEdge({
                shape: "bpmn-edge",
                source: {
                  cell: hierarchyItem.id,
                  anchor:
                    data.type === "topic-child"
                      ? {
                          name: "right",
                          args: {
                            dx: -16,
                          },
                        }
                      : {
                          name: "center",
                          args: {
                            dx: "25%",
                          },
                        },
                },
                target: {
                  cell: id,
                  anchor: {
                    name: "left",
                  },
                },
              })
            );
            traverse(item);
          });
        }
      }
    };
    console.log("first", graphRef.current);
    if (graphRef.current) {
      console.log("first");
      traverse(result);
      graphRef.current.resetCells(cells);
      graphRef.current.centerContent();
    }
  };
  useEffect(() => {
    render();
  }, [data]);

  useEffect(() => {
    Graph.registerNode("topic", {
      inherit: "react-shape",
      x: 200,
      width: 150,
      height: 100,
      component: <TopicNode />,
    });
    Graph.registerNode("topic-child", {
      inherit: "react-shape",
      x: 200,
      width: 150,
      height: 100,
      component: <TopicCHNode />,
    });
    let graph = new Graph({
      container: containerRef.current,
      // width: 800,
      // height: 600,
      scroller: true,
      connecting: {
        anchor: "midSide",
        connectionPoint: "boundary",
        connector: {
          name: "rounded",
          args: {
            radius: 4,
          },
        },
        router: {
          name: "manhattan",
          args: {
            padding: 20,
            startDirections: ["right"],
            endDirections: ["left"],
          },
        },
      },
      selecting: {
        enabled: true,
      },
      keyboard: {
        enabled: true,
      },
    });
    graphRef.current = graph;
    console.log(graph, "graph");
    render();
    return () => {
      console.log("ssss");
      // 在组件清理阶段解绑节点
      Graph.unregisterNode("topic");
      Graph.unregisterNode("topic-child");
    };
  }, []);

  return <div ref={containerRef} className={styles.graph}></div>;
}
