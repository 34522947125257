import { match } from "path-to-regexp";
// import {getSubApps, isActiveApp} from '../qiankun';
import { BASE_NAME, HASH_ROUTER, CONFIG_ENV } from "../config";
import {
  getMainApp,
  isLoginPage,
  convertToTree,
  checkSameField,
  sort,
} from "@ra-lib/admin";
import pageConfigs from "src/pages/page-configs";
import { Icon } from "src/components";

/**
 * 浏览器跳转，携带baseName hash等
 * @param href
 * @returns {string|*}
 */
export function locationHref(href) {
  if (href?.startsWith("http")) return (window.location.href = href);

  if (href && BASE_NAME && href.startsWith(BASE_NAME))
    href = href.replace(BASE_NAME, "");

  const hash = HASH_ROUTER ? "#" : "";
  return (window.location.href = `${BASE_NAME}${hash}${href}`);
}

/**
 * 进入首页
 */
export function toHome() {
  // 跳转页面，优先跳转上次登出页面
  // let lastHref = window.sessionStorage.getItem('last-href') || '/';
  let lastHref = "/front";

  // 如果上次是登录页面，直接跳转首页
  // if (isLoginPage(lastHref)) lastHref = '/';

  locationHref(lastHref);
}

/**
 * 跳转到登录页面
 */
export function toLogin() {
  let loginPath = "/front/login";
  if (window.location.search) {
    loginPath =
      loginPath +
      "?from=" +
      encodeURIComponent(window.location.pathname + window.location.search);
  }
  if (CONFIG_ENV === "shumeng") {
    loginPath = "/front/empty-login";
  }
  // 判断当前页面是否已经是login页面，如果是，直接返回，不进行跳转，防止出现跳转死循环
  if (isLoginPage()) return null;

  // 清除相关数据
  window.sessionStorage.clear();
  window.localStorage.clear();
  // window.sessionStorage.setItem('last-href', window.location.href);

  const mainAppToLogin = getMainApp()?.toLogin;

  if (mainAppToLogin) return mainAppToLogin();

  locationHref(loginPath);

  return null;
}

/**
 * 跳转到登录页面
 */
export function outToLogin() {
  let loginPath = "/front/login";
  if (CONFIG_ENV === "shumeng") {
    loginPath = "/front/empty-login";
  }
  // 判断当前页面是否已经是login页面，如果是，直接返回，不进行跳转，防止出现跳转死循环
  if (isLoginPage()) return null;

  // 清除相关数据
  window.sessionStorage.clear();
  window.localStorage.clear();
  // window.sessionStorage.setItem('last-href', window.location.href);

  const mainAppToLogin = getMainApp()?.toLogin;

  if (mainAppToLogin) return mainAppToLogin();

  locationHref(loginPath);

  return null;
}

/**
 * 检测路由配置冲突
 * @param result
 * @returns {string|boolean}
 */
export async function checkPath(result) {
  // const subApps = await getSubApps();

  const hasHome = result.some(({ path }) => path === "/front");
  if (!hasHome)
    throw Error(
      `必须含有首页路由，path: '/'， 如果需要其他页面做首页，可以进行 Redirect`
    );

  result
    .filter(({ path }) => !!path)
    .forEach(({ path, filePath }) => {
      // 是否与子项目配置冲突
      // const app = subApps.find(item => isActiveApp(item, path));
      // if (app) throw Error(`路由地址：「${path}」 与 子项目 「${app.title || app.name}」 激活规则配置冲突，对应文件文件如下：\n${filePath}`);

      // 自身路由配置是否冲突
      const exit = result.find(({ filePath: f, path: p }) => {
        if (f === filePath) return false;

        if (!p || !path) return false;

        if (p === path) return true;

        return (
          match(path, { decode: decodeURIComponent })(p) ||
          match(p, { decode: decodeURIComponent })(path)
        );
      });
      if (exit)
        throw Error(
          `路由地址：「${path}」 与 「${exit.path}」 配置冲突，对应文件文件如下：\n${filePath}\n${exit.filePath}`
        );
    });
}

/**
 * 基于 window.location.pathname pageConfig 获取当前页面config高级组件参数
 * @returns {{}|*}
 */
export function getCurrentPageConfig() {
  let { pathname, hash } = window.location;
  if (HASH_ROUTER) {
    pathname = hash.replace("#", "").split("?")[0];
  } else if (BASE_NAME) {
    pathname = pathname.replace(BASE_NAME, "");
  }

  const config = pageConfigs.find(
    ({ path }) => path && match(path, { decode: decodeURIComponent })(pathname)
  );

  return config || {};
}

/**
 * 处理菜单数据
 * @param menus
 * @returns {*}
 */
export function formatMenus(menus) {
  // 检测是否有重复id
  const someId = checkSameField(menus, "id");
  if (someId) throw Error(`菜单中有重复id 「 ${someId} 」`);

  menus.forEach((item) => {
    item.id = `${item.id}`;
    item.parentId = `${item.parentId}`;
  });

  // 排序 order降序， 越大越靠前
  return loopMenus(convertToTree(sort(menus, (a, b) => b.order - a.order)));
}

/**
 * 菜单数据处理函数{}
 * @param menus
 * @param basePath
 */
function loopMenus(menus, basePath) {
  menus.forEach((item) => {
    let { icon, path, target, children } = item;

    // 保存原始target数据
    item._target = target;

    // 树状结构bashPath向下透传
    if (basePath && !("basePath" in item)) item.basePath = basePath;

    // 乾坤子项目约定
    if (target === "qiankun") item.basePath = `/${item.name}`;

    // 拼接基础路径
    if (
      basePath &&
      path &&
      (!path.startsWith("http") || !path.startsWith("//"))
    ) {
      item.path = path = `${basePath}${path}`;
    }

    // 图标处理，数据库中持久换存储的是字符串
    if (icon) item.icon = <Icon type={icon} />;

    // 第三方页面处理，如果target为iframe，内嵌到当前系统中
    if (target === "iframe") {
      // 页面跳转 : 内嵌iFrame
      item.path = `/iframe_page_/${encodeURIComponent(path)}`;
    }

    if (!["_self", "_blank"].includes(target)) {
      Reflect.deleteProperty(item, "target");
    }

    if (children?.length) loopMenus(children, item.basePath);
  });

  return menus;
}
//ArrayBuffer转字符串
export function ab2str(u, f) {
  var b = new Blob([u]);
  var r = new FileReader();
  r.readAsText(b, "utf-8");
  r.onload = function () {
    if (f) f.call(null, r.result);
  };
}
//字符串转字符串ArrayBuffer
export function str2ab(s, f) {
  var b = new Blob([s], { type: "text/plain;charset=utf-8" });
  var r = new FileReader();
  r.readAsArrayBuffer(b);
  r.onload = function () {
    if (f) f.call(null, r.result);
  };
}
