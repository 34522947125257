// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bar_19ofX {\n  width: 400px;\n}\n.bar_19ofX .content_319Qz {\n  display: flex;\n}\n.bar_19ofX .content_319Qz .right_2jOZQ {\n  flex: 1;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/bar-chart/styles.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAFA;EAIQ,aAAA;AACR;AALA;EASY,OAAA;EACA,WAAA;AADZ","sourcesContent":[".bar{\n    width: 400px;\n    // height: 200px;\n    .content{\n        display: flex;\n        .left{\n            // width: 40px;\n        }\n        .right{\n            flex: 1;\n            width: 100%;\n        }\n    }\n}\n@packageName: farcloud;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "bar_19ofX",
	"content": "content_319Qz",
	"right": "right_2jOZQ"
};
export default ___CSS_LOADER_EXPORT___;
