// 获取指定字体和size下的文本下的宽度
function getActualWidthOfChars(text, options = {}) {
    const { size = 14, family = "Microsoft YaHei" } = options;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = `${size}px ${family}`;
    const metrics = ctx.measureText(text);
    const actual =
      Math.abs(metrics.actualBoundingBoxLeft) +
      Math.abs(metrics.actualBoundingBoxRight);
    return Math.max(metrics.width, actual);
}
function getOptionWidth (options) {
  const labels = options.map(item=>item.label)
  let widths = []
  for(let i =0;i<labels.length;i++){
    const label = labels[i]
    widths.push(getActualWidthOfChars(label))
  }
  if(widths.length === 0){
    return 40
  }else{
    return parseInt(Math.max(...widths)+40)
  }
}
const MINLIMIT = -2100000000
export  {
    getActualWidthOfChars,
    getOptionWidth,
    MINLIMIT
}