import React, { useEffect, useState } from "react";
import rankText from "../../images/ranking-text.png";
import {tranColor,subName } from '../../utils/index'
import { Progress } from "antd";
import th1 from "../../images/top-header-1.png";
import tf1 from "../../images/top-footer-1.png";
import th2 from "../../images/top-header-2.png";
import tf2 from "../../images/top-footer-2.png";
import th3 from "../../images/top-header-3.png";
import tf3 from "../../images/top-footer-3.png";
import styles from "./style.less";
export default function RankingList({
  parentProps,
  fullData:list,
  handleClickItem,
}) {
  const [topThree, settopThree] = useState([]);
  const [leftList, setleftList] = useState([]);
  const detailRankData = (list) => {
    const currentTopThree = list.slice(0, 3);
    const currentLeftList = list.slice(3, list.length);
    settopThree(currentTopThree);
    setleftList(currentLeftList);
  };
  useEffect(() => {
    detailRankData(list);
  }, [list]);
  return (
    <div className={styles.rankContainer}>
      <img src={rankText} alt="text" className={styles.rankText} />
      <div className={styles.rankContext}>
        <div className={styles.topThree}>
          <div
            className={styles.topLeft}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                achievementId:topThree[1]?.achievementId,
                employId:topThree[1]?.employeeId,
                employeeName:topThree[1]?.employeeName,
              })
            }}
          >
            <img src={th2} alt="th2" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[1]?.employeeName)}
            </div>
            <img src={tf2} alt="tf2" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[1]?.employeeName}</p>
            <p className={styles.score}>
              {topThree[1]?.avgScore || topThree[1]?.totalScore.toFixed(2)}
            </p>
          </div>
          <div
            className={styles.topCenter}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                achievementId:topThree[0]?.achievementId,
                employId:topThree[0]?.employeeId,
                employeeName:topThree[0]?.employeeName,
              })
            }}
          >
            <img src={th1} alt="th1" className={styles.topIcon} />
            <div className={styles.topRankWarp}>
              <div className={styles.portrait}>
                {subName(topThree[0]?.employeeName)}
              </div>
            </div>
            <img src={tf1} alt="tf1" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[0]?.employeeName}</p>
            <p className={styles.score}>
              {topThree[0]?.avgScore || topThree[0]?.totalScore.toFixed(2)}
            </p>
          </div>
          <div
            className={styles.topRight}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                achievementId:topThree[2]?.achievementId,
                employId:topThree[2]?.employeeId,
                employeeName:topThree[2]?.employeeName,
              })
            }}
          >
            <img src={th3} alt="th3" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[2]?.employeeName)}
            </div>
            <img src={tf3} alt="tf3" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[2]?.employeeName}</p>
            <p className={styles.score}>
              {topThree[2]?.avgScore || topThree[2]?.totalScore.toFixed(2)}
            </p>
          </div>
        </div>
        <div className={styles.other}>
          {leftList.map((item, index) => (
            <div
              className={styles.otherRankItem}
              style={{
                cursor: "pointer",
              }}
              key={index}
              onClick={() => {
                handleClickItem({
                achievementId:item?.achievementId,
                employId:item?.employeeId,
                employeeName:item.employeeName,
              })
              }}
            >
              {/* <div className={styles.num}>{item.order}</div> */}
              <div className={styles.num}>{index + 4}</div>
              <div
                className={styles.avtor}
                style={{
                  border: `1px solid ${tranColor(item.employeeName).colorStr}`,
                  backgroundColor: tranColor(item.employeeName).backColorStr,
                  color: tranColor(item.employeeName).colorStr,
                }}
              >
                {subName(item.employeeName)}
              </div>
              <div className={styles.name}>{item.employeeName}</div>
              <div className={styles.pro}>
                <Progress
                  percent={item?.avgScore || item?.totalScore}
                  showInfo={false}
                  strokeWidth={4}
                />
              </div>
              <div className={styles.score}>
                {item?.avgScore || item.totalScore.toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
