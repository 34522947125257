import { Fragment } from "react";import { RightOutlined } from "@ant-design/icons";
import { chartbarAndLineMix } from '../report/components/chartItem/utils'
import { UniveralEcharts } from "../../components";
import { Empty, Tooltip } from "antd";
import style from "./style.less";
export default function BarAndLineItem({ item, getDrillData }) {
  const { title, canDrillDown, drillDownKey, indexId, structId ,baseline,markLine} = item;
  const params = {
    data: {
      canDrillDown,
      drillDownKey,
      indexId,
      title,
      structId,
    },
  };
  const newData = {series:[item]}
  const newOption = chartbarAndLineMix(newData,{
    baseline,
    markLine,
  },{})  
  const handleClickDrill = () => {
    getDrillData(params, true);
  };
  // 饼图当到最后一层节点需要点击图表下钻
  // const handleDrill = (oldParams, isAddBread) => {
  //   if(!canDrillDown){
  //     getDrillData(params, isAddBread);
  //   }
  // };
  const option = {...newOption};
  const renderEmpty = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Empty style={{ width: "100%" }} description="暂无数据" />
      </div>
    );
  };
  return (
    <div style={{ width: "100%", height: "100%" }} className={style.pieStyle}>
      {[1].length ? (
        <Fragment>
          <div className={style.title} 
          onClick={() => handleClickDrill()}
          >
            <Tooltip title="点我下钻">
              <span>{title}</span>
              <RightOutlined className={style.rightArrow} />
            </Tooltip>
          </div>

          <div style={{ width: "100%", height: "80%" }}>
            <UniveralEcharts
              style={{ height: "100%", width: "100%" }}
              option={option}
              // clickEvent={(params) => handleDrill({}, true)}
            />
          </div>
        </Fragment>
      ) : (
        renderEmpty()
      )}
    </div>
  );
}
