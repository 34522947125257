// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root_1XNcT {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.action_1JJY1 {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n.avatar_w_9rO {\n  margin-right: 4px;\n}\n.userName_xsyVR {\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://src/components/header/style.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AAEA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAOA;EACI,iBAAA;AALJ;AAQA;EACI,iBAAA;AANJ","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.action {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n\n    &:hover {\n        //opacity: .7;\n    }\n}\n\n.avatar {\n    margin-right: 4px;\n}\n\n.userName {\n    margin-right: 4px;\n}\n\n@packageName: farcloud;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root_1XNcT",
	"action": "action_1JJY1",
	"avatar": "avatar_w_9rO",
	"userName": "userName_xsyVR"
};
export default ___CSS_LOADER_EXPORT___;
