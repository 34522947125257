// 获取模型id
export const getModelId = (id, categories = []) => {
    const categoryPath = [];

    (function calcPath(id, categories= []) {
      return categories.some(category => {
        if (category.id === id) {
          categoryPath.push(category.id)
          return true
        }
        if (calcPath(id, category.children)) {
          categoryPath.unshift(category.id)
          return true
        }
      })
    })(id, categories)
    return categoryPath
};
