export const dateButtonType = [
    {
        type:'currentWeek',
        name:'本周'
    },
    {
        type:'lastWeek',
        name:'上周'
    },
    {
        type:'currentMonth',
        name:'本月'
    },
    {
        type:'lastMonth',
        name:'上月'
    },
    {
        type:'currentQuarter',
        name:'本季度'
    },
    {
        type:'lastQuarter',
        name:'上季度'
    },
]