import { useEffect, useRef } from "react";
import * as echarts from "echarts";

export default function Echarts({ item }) {
  let chartInstance = null;
  const { abscissas, chartName, topItemResponses } = item;
  const seriesOptions = topItemResponses.map((item) => {
    return {
      name: item.indexName,
      type: "line",
      data: item.indexDataList,
    };
  });
  const chartRef = useRef(null);

  const option = {
    title: {
      text: chartName,
      padding:0,
      left:0 ,
      top:20,
      textAlign: "left",
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: abscissas,
      axisLabel: {
        //x轴文字的配置
        show: true,
        // interval: 0,//使x轴文字显示全
      },
      axisTick: {
        show: false
     }
    },
    yAxis: {
      type: "value",
    },
    series: seriesOptions,
    legend: {
      orient: "horizontal",
      bottom: "0",
    },
  };
  useEffect(() => {
    chartInstance = echarts.init(chartRef?.current);
    chartInstance.setOption(option);
  }, [topItemResponses]);
  return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
}
