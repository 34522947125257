import { useEffect, useRef } from "react";
import * as echarts from "echarts";

export default function Echarts({ option }) {
    const chartRef = useRef(null);

    useEffect(() => {
        let chartInstance = echarts.init(chartRef?.current);
        chartInstance.setOption(option);
        chartInstance.resize()
    });

    return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
}

