import { useState } from "react";
import config from "src/commons/config-hoc";
import {
    Tabs,
    Progress,
    Modal,
    Select,
    Checkbox,
    Popover,
    Space,
    Tooltip,
} from "antd";
import { PageContent } from "@ra-lib/admin";
import style from "src/pages/pm/style.less";
import { Echarts } from "src/components";
import {
    FlowChart,
    lineOption,
    barOption,
    // barRowOption,
} from "src/pages/pm/FlowChart";
import zoomOut from "src/pages/pm/images/zoom-out.png";
import zoomIn from "src/pages/pm/images/zoom-in.png";
import move from "src/pages/pm/images/move.png";

const TabPane = Tabs.TabPane;
export default config({
    path: "/front/pm",
    title: "流程挖掘",
})(function Pm(props) {
    const [wayList] = useState([
        { no: 1, name: "路径1", num: 226, max: 240 },
        { no: 2, name: "路径2", num: 193, max: 240 },
    ]);
    const [visible, setvisible] = useState(false);
    const onChange = () => {};

    const content = (
        <Checkbox.Group>
            <Space direction="vertical">
                <Checkbox>创建</Checkbox>
                <Checkbox>评审</Checkbox>
                <Checkbox>排期</Checkbox>
            </Space>
        </Checkbox.Group>
    );

    return (
        <PageContent fitHeight>
            <div className={style.warp}>
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="需求流程" key="1">
                        <div className={style.container}>
                            <div className={style.left}>
                                <Popover
                                    content={content}
                                    title={null}
                                    placement="bottom"
                                    overlayStyle={{ width: 158 }}
                                >
                                    <div className={style.activeWrap}>
                                        <Checkbox>只看活跃</Checkbox>
                                        <div className={style.arrowDown}></div>
                                    </div>
                                </Popover>
                                <div className={style.zoomBtnWarp}>
                                    <Tooltip
                                        title="鼠标左键按住流程图来移动"
                                        placement="left"
                                    >
                                        <img
                                            src={move}
                                            alt="move"
                                            className={style.zoomIcon}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title="向下滑动鼠标滚轮来放大"
                                        placement="left"
                                    >
                                        <img
                                            src={zoomOut}
                                            alt="outIcon"
                                            className={style.zoomIcon}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title="向上滑动鼠标滚轮来缩小"
                                        placement="left"
                                    >
                                        <img
                                            src={zoomIn}
                                            alt="inIcon"
                                            className={style.zoomIcon}
                                        />
                                    </Tooltip>
                                </div>
                                <FlowChart
                                    clickNode={(e) => {
                                        console.log(e, "e");
                                        setvisible(true);
                                    }}
                                />
                            </div>
                            <div className={style.right}>
                                <div className={style.infoWarp}>
                                    <div className={style.infoItem}>
                                        <p className={style.title}>数据量</p>
                                        <p className={style.value}>468</p>
                                    </div>
                                    <div className={style.deLine}></div>
                                    <div className={style.infoItem}>
                                        <p className={style.title}>
                                            平均吞吐时间
                                        </p>
                                        <p className={style.value}>468</p>
                                    </div>
                                    <div className={style.deLine}></div>
                                    <div className={style.infoItem}>
                                        <p className={style.title}>
                                            平均步骤数
                                        </p>
                                        <p className={style.value}>5</p>
                                    </div>
                                    <div className={style.deLine}></div>
                                    <div className={style.infoItem}>
                                        <p className={style.title}>路径数量</p>
                                        <p className={style.value}>226</p>
                                    </div>
                                    <div className={style.deLine}></div>
                                    <div className={style.infoItem}>
                                        <p className={style.title}>
                                            平均返工率
                                        </p>
                                        <p className={style.value}>20%</p>
                                    </div>
                                </div>
                                <div className={style.flowWay}>
                                    <p className={style.flowTitle}>流程路径</p>
                                    <div className={style.flowContent}>
                                        <div className={style.flowItemRow}>
                                            <div className={style.flowNum}>
                                                <div
                                                    className={style.numWarp}
                                                    style={{
                                                        background: "#fff",
                                                    }}
                                                ></div>
                                            </div>
                                            <div
                                                className={style.flowItemTitle}
                                            >
                                                路径名称
                                            </div>
                                            <div
                                                className={style.flowItemTitle}
                                                style={{ width: "100%" }}
                                            >
                                                数量
                                            </div>
                                        </div>
                                        {wayList.map((item, index) => (
                                            <div
                                                className={style.flowItemRow}
                                                key={index}
                                            >
                                                <div className={style.flowNum}>
                                                    <div
                                                        className={
                                                            style.numWarp
                                                        }
                                                    >
                                                        {item.no}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        style.flowItemName
                                                    }
                                                >
                                                    {item.name}
                                                </div>
                                                <div
                                                    className={
                                                        style.flowItemProgress
                                                    }
                                                >
                                                    <Progress
                                                        strokeColor="#6B9BFB"
                                                        strokeWidth={8}
                                                        status="active"
                                                        percent={Math.floor(
                                                            (item.num /
                                                                item.max) *
                                                                100
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={style.chartWarp}>
                                    <div className={style.chartItem}>
                                        <p className={style.chartTitle}>
                                            案例分布
                                        </p>
                                        <div className={style.chartContainer}>
                                            <Echarts option={lineOption} />
                                        </div>
                                    </div>
                                    <div className={style.chartItem}>
                                        <p className={style.chartTitle}>
                                            步骤分布
                                        </p>
                                        <div className={style.chartContainer}>
                                            <Echarts option={barOption} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="生成缺陷流程" key="2">
                            Content of Tab Pane 2
                        </TabPane>
                        <TabPane tab="测试流程" key="3">
                            Content of Tab Pane 3
                        </TabPane> */}
                </Tabs>
                <Modal
                    visible={visible}
                    footer={null}
                    title={
                        <div className={style.titleWarp}>
                            <Select
                                bordered={false}
                                onChange={(value) => {}}
                                style={{ width: 190 }}
                                defaultValue="1"
                            >
                                <Select.Option key="1" value="1">
                                    等待时长 &gt; 5 天
                                </Select.Option>
                            </Select>
                            <div className={style.splitLine}></div>
                            <p className={style.total}>186条</p>
                        </div>
                    }
                    onCancel={() => setvisible(false)}
                    width={875}
                    bodyStyle={{ height: 462, padding: 0 }}
                >
                    <div className={style.modalContent}>
                        <div className={style.left}></div>
                        <div className={style.right}>
                            <div className={style.topTitle}>
                                紧急更改云客网和金融频道投诉方式
                            </div>
                            <div className={style.dateFieldWarp}>
                                <span>审核时间：2022.03.25</span>
                                <span style={{ marginLeft: 42 }}>
                                    排期时间：2022.04.18
                                </span>
                            </div>
                            <div className={style.fieldWarp}></div>
                        </div>
                    </div>
                </Modal>
            </div>
        </PageContent>
    );
});
