/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import config from "src/commons/config-hoc";
import {
  Select,
  Form,
  TreeSelect,
  Table,
  Button,
  Breadcrumb,
  Spin,
  Tabs,
  Input,
} from "antd";
import { PageContent } from "@ra-lib/admin";
import moment from "moment";
import profileIcon from "../../image/profile_title.png";
import RankingList from "./components/RankingList";
import RankListDetail from "./components/RankListDetail";
import RankTable from "./components/RankTable";
import cx from "classnames";
import { getActualWidthOfChars, getOptionWidth } from "src/utils";
import { useThrottleEffect } from "ahooks";
import styles from "./styles.less";
import { async } from "@antv/x6/lib/registry/marker/async";

const loop = (nodes) =>
  nodes.map((node) => {
    const { title, id, children } = node;
    return {
      key: id,
      value: id,
      title,
      children: children ? loop(children) : undefined,
    };
  });

export default config({
  path: "/front/achievement",
  title: "绩效榜",
})(function RankAchi(props) {
  const [loading, setLoading] = useState(false);
  const [rankTableLoading,setRankTableLoading] = useState(false)
  const [dateCycle, setDateCycle] = useState("");
  const [detailParams, setDetailParams] = useState({});
  // type为1 详情 type为2 table type为3  排行视图
  const [viewType, setViewType] = useState({ type: 3 });
  const [activeKey, setActiveKey] = useState(0);
  const [fullData, setFullData] = useState([]);
  const [tableParams, setTableParams] = useState("");
  const [downloadLoading, setdownloadLoading] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);
  const [modelId, setModelId] = useState("");
  const [cycleOptions, setCycleOptions] = useState([]);
  const [cycleId, setCycleId] = useState("");
  const [optionWidth, setOptionWidth] = useState(0);
  const [positionId, setPositionId] = useState();
  const [structId, setStructId] = useState("");
  const [stuctName, setStructName] = useState("");
  const [strcutIdOptions, setStructIdOptions] = useState([]);
  const [timeCycleOptions, setTimeCycleOptions] = useState([]);
  const [timeCycle, setTimeCycle] = useState("");
  const [positions, setPositions] = useState([]);
  const [departmentId, setDepartmentId] = useState();
  const [schemeName,setSchemeName] = useState('')
  const [schemeList, setSchemeList] = useState([]);
  const [depts, setDepts] = useState([]);

  const getRankList = async () => {
    if (loading) return;
    if(!cycleId) return;
    try {
      setLoading(true);
      const query = {
        schemeId: modelId,
        cycleType: cycleId,
        cycleValue: timeCycle,
        departmentId,
        positionId,
      };
      const res = await props.ajax.post(
        `/api/achievement/scheme/queryRanking`,
        {
          ...query,
        },
        {
          // errorTip: false,
        }
      );
      setLoading(false);
      if (res.data && res.data.length) {
        if (viewType.type === 1) {
          handleClickItem({
            employId: res.data[0]?.dataId,
            employeeName: res.data[0]?.dataName,
            finalScore:res.data[0]?.finalScore
          });
        }
        setFullData([...res.data]);
      }
    } catch (error) {
      setFullData([]);
      setLoading(false);
      console.log(error);
    }
  };
  useThrottleEffect(
    () => {
      if (timeCycle) {
        getRankList();
      }
    },
    [positionId, departmentId, timeCycle,cycleId],
    {
      wait: 200,
    }
  );
  // 获取方案列表
  useEffect(async () => {
    setRankTableLoading(true)
    if (cycleId) {
      const query = {
        schemeId: modelId,
        cycleType: cycleId,
        cycleValue: timeCycle,
      };
      try {
        const res = await props.ajax.post(
          `/api/achievement/scheme/querySchemeCalculate`,
          {
            ...query,
          },
          {
            errorTip: false,
          }
        );
        if (res.data && res.data.length) {
          setSchemeList(res.data);
          setActiveKey(0);
          setTableParams({
            departmentId,
            positionId,
            structId,
            dateCycle,
            cycleType: cycleId,
            cycleValue: timeCycle,
            ...res.data[0],
          });
          setRankTableLoading(false)
        } else {
          setSchemeList([]);
          setRankTableLoading(false)
        }
      } catch (error) {
        setRankTableLoading(false)
        console.log(error);
      }
    }
  }, [timeCycle, cycleId]);
  const handleTabChange = (key) => {
    setActiveKey(key);
    setTableParams({
      ...tableParams,
      ...schemeList[key],
    });
  };
  // 查询岗位
  useEffect(() => {
    (async () => {
      if (positionId) setPositionId("");
      if (cycleId) {
        const res = await props.ajax.post(
          `/api/achievement/scheme/schemeQueryPosition`,
          {
            schemeId: modelId,
            cycleType: cycleId,
            cycleValue: timeCycle,
            departmentId: departmentId,
          },
          {
            errorTip: false,
          }
        );
        setPositions(
          res.data.map((p) => ({
            value: p.positionId,
            label: p.positionName,
          }))
        );
      }
    })();
  }, [departmentId, cycleId]);

  // 查询组织
  useEffect(() => {
    (async () => {
      if (modelId && cycleId && timeCycle) {
        if (departmentId) setDepartmentId("");
        const res = await props.ajax.post(
          `/api/achievement/scheme/queryStruct`,
          {
            schemeId: modelId,
            cycleType: cycleId,
            cycleValue: timeCycle,
          },
          {
            errorTip:false,
          }
        );
        setDepts(loop(res.data));
      }
    })();
  }, [modelId, cycleId, timeCycle]);
  // 查询已发布绩效
  useEffect(() => {
    (async () => {
      const res = await props.ajax.get(
        `/api/achievement/scheme/queryReleaseScheme`
      );
      if (res?.data?.length) {
        const modelResponseList = res?.data.map((item) => {
          return {
            label: item.schemeName,
            value: item.schemeId,
            structName: item.structName,
            structId: item.structId,
          };
        });
        const width = getOptionWidth(modelResponseList);
        setOptionWidth(width);
        setModelOptions(modelResponseList);
        setModelId(modelResponseList[0].value);
        setSchemeName(modelResponseList[0].label)
        setStructId(modelResponseList[0].structId);
        setStructName(modelResponseList[0].structName);
      }
    })();
  }, []);
  // 查询周期
  useEffect(() => {
    (async () => {
      if (modelId) {
        if (cycleId) setCycleId("");
        const res = await props.ajax.get(
          `/api/achievement/scheme/queryTimeCycleType/${modelId}`,
          {},
          {
            errorTip: false,
          }
        );
        if (res?.data?.length) {
          const cycleOptions = res?.data.map((item) => {
            return {
              label: item.cycleTypeName,
              value: item.cycleType,
              timeCycleList: item.timeCycleLisit.map((it) => {
                return {
                  label: it.cycleTypeName,
                  value: it.cycleValue,
                  dateCycle: `${it.timeCycleStartDate}~${it.timeCycleEndDate}`,
                };
              }),
            };
          });
          setCycleOptions(cycleOptions);
          setTimeCycleOptions(cycleOptions[0].timeCycleList);
          if (cycleOptions[0].timeCycleList.length) {
            setTimeCycle(cycleOptions[0].timeCycleList[0].value);
            setDateCycle(cycleOptions[0].timeCycleList[0].dateCycle);
          }
          setCycleId(cycleOptions[0].value);
        } else {
          setCycleOptions([]);
          setTimeCycle("");
          setDateCycle("");
          setCycleId("");
          setFullData([]);
        }
      }
    })();
  }, [modelId]);
  const handleClickItem = (item) => {
    const { employId, employeeName ,finalScore} = item;
    const [startTime, endTime] = dateCycle.split("~");
    setDetailParams({
      ...detailParams,
      schemeId:modelId,
      cycleType:cycleId,
      cycleValue:timeCycle,
      structId,
      employeeName,
      startTime: moment(moment(startTime, "YYYY-MM-DD").toDate()).format(
        "YYYY-MM-DD"
      ),
      endTime: moment(moment(endTime, "YYYY-MM-DD").endOf("D").toDate()).format(
        "YYYY-MM-DD"
      ),
      employId,
      finalScore,
    });
    setViewType({ type: 1 });
  };
  // 下载功能
  const handleDownload = () => {
    setdownloadLoading(true);
    props.ajax
      .post(
        "/api/achievement/scheme/exportCalculateDetail",
        {
          schemeId: modelId,
          departmentId,
          positionId,
          cycleType: cycleId,
          cycleValue: timeCycle,
        },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res) {
          const blob = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          const href = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = href;
          a.download = `${schemeName}-${timeCycle}.xlsx`;
          a.click();
          URL.revokeObjectURL(a.href);
        }
        setdownloadLoading(false);
      })
      .catch(() => {
        setdownloadLoading(false);
      });
  };
  const renderHeaderBtn = () => {
    switch (viewType.type) {
      case 1:
        return (
          <div style={{ marginBottom: 10 }}>
            <Button
              onClick={() => setViewType({ type: 3 })}
              type="primary"
              style={{ background: "#165DFF", marginRight: "20px" }}
            >
              排行视图
            </Button>
            <Button onClick={() => {
              setViewType({ type: 2 })
              setActiveKey(0);
            }}>表格视图</Button>
          </div>
        );
      case 2:
        return (
          <div style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              style={{ background: "#165DFF", marginRight: "20px" }}
              loading={downloadLoading}
              onClick={() => handleDownload()}
            >
              下载
            </Button>
            <Button
              onClick={() => {
                setViewType({ type: 3 });
                setActiveKey(0);
              }}
            >
              排行视图
            </Button>
          </div>
        );
      case 3:
        return (
          <div style={{ marginBottom: 10 }}>
            <Button onClick={() => setViewType({ type: 2 })}>表格视图</Button>
          </div>
        );
    }
  };
  const renderHeader = () => {
    return (
      <div className={styles.headerTop}>
        <div className={styles.headerTopLeft}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <img src={profileIcon} className={styles.img} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Select
                bordered={false}
                style={{ width: optionWidth }}
                onChange={(id) => {
                  const name = modelOptions.filter(
                    (item) => item.value === id
                  )[0].structName;
                  const newStructId = modelOptions.filter(
                    (item) => item.value === id
                  )[0].structId;
                  const newSchemeName = modelOptions.filter(
                    (item) => item.value === id
                  )[0].label;
                  setSchemeName(newSchemeName)
                  setStructId(newStructId)
                  setStructName(name);
                  setModelId(id);
                }}
                value={modelId}
                options={modelOptions}
              />
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.headerStructure}>
            <span>{stuctName}</span>
          </div>
        </div>
        <div className={styles.headerTopRight}>{renderHeaderBtn()}</div>
      </div>
    );
  };
  const renderOperation = () => {
    return (
      <Fragment>
        <div className={styles.filterWarpTop}>
          <div className={styles.filterItem}>
            <div className={styles.lable}>周期类型</div>
            <Select
              style={{ width: 100, background: "#F2F3F5" }}
              onChange={(cycleId) => {
                const options = cycleOptions.filter(
                  (item) => item.value === cycleId
                )[0];
                setCycleId(cycleId);
                setTimeCycleOptions(options.timeCycleList);
                if (options.timeCycleList && options.timeCycleList.length) {
                  setTimeCycle(options.timeCycleList[0].value);
                  setDateCycle(options.timeCycleList[0].dateCycle);
                }
                setActiveKey(0)
                setDetailParams({
                    ...detailParams,
                    cycleType:cycleId
                  });
              }}
              value={cycleId}
              options={cycleOptions}
              bordered={false}
            />
          </div>
          <div className={styles.filterItem}>
            <div className={styles.lable}>周期</div>
            <Select
              style={{ width: 200, background: "#F2F3F5" }}
              onChange={(timeCycle) => {
                const cycleDate = timeCycleOptions.filter(
                  (item) => item.value === timeCycle
                )[0];
                setTimeCycle(timeCycle);
                // setActiveKey(0);
                setDateCycle(cycleDate.dateCycle);
                  setDetailParams({
                    ...detailParams,
                    cycleValue:timeCycle
                  });
              }}
              value={timeCycle}
              options={timeCycleOptions}
              bordered={false}
              filterOption={false}
            />
          </div>
          <div className={styles.filterWarpBottom}>
            <div className={styles.filterWarpBottomLeft}>
              <div
                className={styles.lable}
                style={{ color: "#2b2e34", fontWeight: 500 }}
              >
                组织
              </div>
              <TreeSelect
                placeholder="请选择组织"
                showSearch
                style={{
                  width: 200,
                  marginRight: 20,
                  background: "#F2F3F5",
                  marginLeft: 20,
                }}
                allowClear
                value={departmentId}
                bordered={false}
                dropdownMatchSelectWidth={false}
                onChange={(departmentId) => {
                  setDepartmentId(departmentId);
                  setTableParams({
                    ...tableParams,
                    departmentId,
                  });
                  setDetailParams({
                    ...detailParams,
                    departmentId,
                  });
                }}
                treeData={depts}
              ></TreeSelect>

              <div className={styles.filterItem}>
                <div className={styles.lable}>岗位</div>
                <Select
                  placeholder="请选择岗位"
                  onChange={(positionId) => {
                    setPositionId(positionId);
                    setTableParams({
                      ...tableParams,
                      positionId,
                    });
                    setDetailParams({
                    ...detailParams,
                    positionId,
                  });
                  }}
                  value={positionId}
                  options={positions}
                  allowClear
                  style={{ width: 200, background: "#F2F3F5" }}
                  bordered={false}
                />
              </div>
              <div className={styles.lable}>周期</div>
              <span style={{ marginLeft: 10 }}>{dateCycle}</span>
            </div>
            <div></div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <PageContent fitHeight>
      {renderHeader()}
      <div className={styles.rankInner}>
        <div className={styles.filterWarpRank}>{renderOperation()}</div>
        {/*表格视图*/}
        {viewType.type === 2 && (
          <Spin spinning={rankTableLoading}>
          <div className={styles.tableWrapScheme}>
            <Tabs type="line" defaultActiveKey="0" onChange={handleTabChange}>
              {schemeList.map((item, index) => (
                <Tabs.TabPane key={index} tab={item.schemeName}>
                    <RankTable
                      parentProps={props}
                      tableParams={tableParams}
                      detailParams={detailParams}
                      schemeName={schemeName}
                      handleClickItem={handleClickItem}
                    />
                </Tabs.TabPane>
              ))}
            </Tabs>
            {/* <Table
              size="small"
              bordered
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              rowKey="employeeId"
              onChange={handleTableChange}
              scroll={{ x: "max-content", y: "calc(100vh - 320px)" }}
              pagination={{
                pageNum,
                pageSize,
                pageSizeOptions: [200, 500, 1000],
                total,
                showSizeChanger: true,
                showTotal: (tot) => `共${tot}条数据`,
              }}
              summary={() =>
                total ? (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      {columns.map((o, i) => (
                        <Table.Summary.Cell key={i} index={i}>
                          {o.dataIndex === "ranking"
                            ? "平均值"
                            : average[o.dataIndex] ?? ""}
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                ) : null
              }
            /> */}
          </div>
          </Spin>
        )}
        {/*详情视图*/}
        {viewType.type === 1 && (
          <div className={styles.bottomWarp}>
            <Spin spinning={loading}>
              <RankListDetail
                parentProps={props}
                fullData={fullData}
                handleClickItem={handleClickItem}
                detailParams={detailParams}
                schemeName={schemeName}
              ></RankListDetail>
            </Spin>
          </div>
        )}
        {/*排行视图*/}
        {viewType.type === 3 && (
          <div className={styles.rankingListContainer}>
            <Spin spinning={loading}>
              <RankingList
                fullData={fullData}
                parentProps={props}
                handleClickItem={handleClickItem}
              ></RankingList>
            </Spin>
          </div>
        )}
      </div>
    </PageContent>
  );
});
