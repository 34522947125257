import React from "react";
import { Empty } from "antd";
import config from 'src/commons/config-hoc';

export default config({
  path: "/front/empty",
  title: "暂无数据",
})(function EmptyComponent(props) {
  return(
    <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent:'center',
      height:'100vh',
    }}
  >
    <Empty style={{width:'100%'}}/>
  </div>
  )
  
});
