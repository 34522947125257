export const handleTagList = (item, requireLabelList) => {
  let list = [];
  let requiredIds = [];
  if (item.value === -1) {
    list = requireLabelList.map((item) => {
      if (item.value === -1) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    requiredIds = [-1];
    console.log(list, "list");
  } else {
    list = requireLabelList.map((it) => {
      if (it.value === item.value) {
        return {
          ...item,
          selected: !item.selected,
        };
      }
      return it;
    });
    const firstList = list.slice(0, 1);
    const otherList = list.slice(1);
    // 需要判断其他是否全部选中
    const isAllSelected = otherList.map((item) => item.selected).includes(true);
    if (isAllSelected) {
      requiredIds = otherList
        .filter((item) => item.selected)
        .map((it) => it.value);
      firstList[0].selected = false;
    } else {
      requiredIds = [-1];
      firstList[0].selected = true;
    }
    list = firstList.concat(otherList);
  }
  return {
    list,
    requiredIds,
  };
};
