import React, { useEffect, useState } from "react";
import { Table } from "antd";
const RankTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(200);
  const [pageNum, setPageNum] = useState(1);
  const [sortIndexKey, setSortIndexKey] = useState("");
  const [sortMode, setSortMode] = useState(0);
  const { dataSource, columns ,average} = props;
  const handleTableChange = (pagination, _filters, sorter) => {
    // setPageSize(pagination.pageSize);
    // setPageNum(pagination.current);
    // console.log("sorter", sorter);
    // setSortIndexKey(sorter.field);
    // if (sorter.order) setSortMode(sorter.order === "ascend" ? 1 : 2);
    // else setSortMode(0);
  };
  useEffect(() => {
    // 监听到方案id 变了加载数据
  }, []);
  return (
    <Table
      size="small"
      bordered
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey="employeeId"
      onChange={handleTableChange}
      scroll={{ x: "max-content", y: "calc(100vh - 380px)" }}
      pagination={{
        pageNum,
        pageSize,
        pageSizeOptions: [200, 500, 1000],
        // total,
        showSizeChanger: true,
        showTotal: (tot) => `共${tot}条数据`,
      }}
        summary={() =>
          true ? (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {columns.map((o, i) => (
                  <Table.Summary.Cell key={i} index={i}>
                    {o.dataIndex === "ranking"
                      ? "平均值"
                      : average[o.dataIndex] ?? ""}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          ) : null
        }
    />
  );
};

export default RankTable;
