import React, { useEffect, useState } from "react";
import ECharts from "../../../../components/echarts";
import { tranColor, subName } from "../../utils/index";
import styles from "./style.less";
import cx from "classnames";
import { UniveralEcharts } from "../../../../components";
import { achievementChartOptions, averageChartOptions } from "./utils";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Progress, message, notification, Spin, Col, Row } from "antd";
import th1 from "../../images/top-header-1.png";
import userImg from "../../../../image/profile_user.png";
import tf1 from "../../images/top-footer-1.png";
import th2 from "../../images/top-header-2.png";
import tf2 from "../../images/top-footer-2.png";
import th3 from "../../images/top-header-3.png";
import tf3 from "../../images/top-footer-3.png";

export default function RankListDetail(props) {
  const { parentProps, detailParams, fullData, handleClickItem } = props;
  const [lineOption, setlineOption] = useState({});
  const [radarOption, setradarOption] = useState({});
  const [indexRadarOption, setIndexRaderOption] = useState({});
  const [portraitIndexMap, setportraitIndexMap] = useState([]);
  const [IsShowLeftList, setIsShowLeftList] = useState(true);
  const [portraitMap, setportraitMap] = useState([]);
  const [topThree, settopThree] = useState([]);
  const [structId, setstructId] = useState("");
  const [leftList, setleftList] = useState([]);
  const [specialTargetData, setspecialTargetData] = useState([]);
  const [detailLoading, setDetailLoading] = useState(false);
  const [selectedId, setSelectId] = useState("");
  const detailRankData = (list) => {
    const currentTopThree = list.slice(0, 3);
    const currentLeftList = list.slice(3, list.length);
    settopThree(currentTopThree);
    setleftList(currentLeftList);
  };
  const getDetail = (params) => {
    setDetailLoading(true);
    parentProps.ajax
      .post("/api/ranking/employeeDetail", { ...params })
      .then((res) => {
        if (!res.data) {
          message.info("暂无数据");
          return false;
        }
        const pivotalIndexMap = res.data?.pivotalIndexResult || [];
        const portraitMap = res.data?.portraitMap || [];
        const rankingHistory = res.data?.rankingHistory || {};
        const currentPortraitIndexMap = res.data?.portraitIndexMap || [];
        const currentStructId = res.data?.statisticsId || "organization";

        let resArr = pivotalIndexMap.map((item) => ({
          name: item.indexName,
          value: item.indexResult,
          id: item.indexId,
        }));

        const lineCategory = [];
        const lineSeriesData = [];
        for (const key in rankingHistory) {
          const ele = rankingHistory[key];
          lineCategory.push(key);
          lineSeriesData.push(ele);
        }

        const currentLineOption = {
          color: "#7196FE",
          tooltip: {
            show: true,
            trigger: "axis",
          },
          grid: {
            top: "8%",
            left: "2%",
            right: "2%",
            bottom: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: lineCategory,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            type: "value",
            inverse: true,
          },
          series: [
            {
              data: lineSeriesData,
              type: "line",
            },
          ],
        };

        if (res.data.error) {
          notification.info({
            message: "提示",
            description: res.data.error,
            duration: 2,
          });
        }
        setspecialTargetData(resArr);
        setlineOption(currentLineOption);
        setportraitMap(portraitMap);
        setradarOption(handleRadarOption(portraitMap, "维度画像"));
        setIndexRaderOption(
          handleRadarOption(currentPortraitIndexMap, "指标画像")
        );
        setportraitIndexMap(currentPortraitIndexMap);
        setstructId(currentStructId);
        setDetailLoading(false);
      })
      .catch(() => {
        setDetailLoading(false);
      });
  };
  const handleRadarOption = (dataArr, title) => {
    let indicator =
      dataArr.length > 0
        ? dataArr.map((item) => ({
            name: item.dimensionName,
            max: 100,
          }))
        : [{ name: "", max: 100 }];
    const radarData = [0, 0];
    radarData[0] = {
      value: dataArr.length > 0 ? dataArr.map((item) => item.indexScore) : [0],
      name: "得分",
      areaStyle: {
        normal: {
          opacity: 0.9,
          color: "#4D7CFE70",
        },
      },
      lineStyle: {
        color: "#4D7CFE",
      },
      itemStyle: {
        color: "#4D7CFE",
      },
      symbolSize: 3,
    };
    radarData[1] = {
      value:
        dataArr.map((item) => item.indexScoreAvg).length > 0
          ? dataArr.map((item) => item.indexScoreAvg)
          : [0],
      name: "全员平均值",
      lineStyle: {
        type: "dashed",
        color: "#E2675B",
        width: 1,
      },
      symbol: "none",
      itemStyle: {
        color: "#E2675B",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    radarData[2] = {
      value:
        dataArr.map((item) => item.departmentScoreAvg).length > 0
          ? dataArr.map((item) => item.departmentScoreAvg)
          : [0],
      name: "部门平均值",
      lineStyle: {
        type: "dashed",
        color: "#EAB308",
        width: 1,
      },
      symbol: "none",
      itemStyle: {
        color: "#EAB308",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    return {
      title: {
        text: title,
      },
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        axisPointer: {
          axis: "x",
          snap: true,
        },
        confine: true,
      },
      legend: {
        data: ["得分", "全员平均值", "部门平均值"],
        bottom: 0,
      },
      radar: {
        center: ["50%", "45%"],
        radius: "65%",
        tooltip: {
          show: true,
        },
        indicator,
      },
      series: [
        {
          type: "radar",
          data: radarData,
        },
      ],
    };
  };
  useEffect(() => {
    if (Object.keys(detailParams).length) {
      console.log(detailParams, "detailParams");
      const { employId } = detailParams;
      setSelectId(employId);
      getDetail(detailParams);
      detailRankData(fullData);
    }
  }, [detailParams, fullData]);
  const renderRankorTar = () => {
    let node = (
      <React.Fragment>
        <div className={styles.rankTab}>
          <div className={styles.chartRadar}>
            <div className={styles.chartInfo}>
              <img src={userImg} alt="画像" className={styles.img}></img>
              <span className={styles.title}>
                {detailParams.employeeName}画像
              </span>
            </div>
            <div className={styles.radarProfile}>
              <div className={cx(styles.radarLeft)}>
                <ECharts option={indexRadarOption} />
              </div>
              <div className={styles.radarRight}>
                <ECharts option={radarOption} />
              </div>
            </div>
          </div>
          {detailParams.cycleId !== 0 && (
            <div className={styles.chartWarp}>
              <div className={styles.chartItem} style={{ borderRight: "none" }}>
                <div className={styles.chartContent}>历史排名</div>
                <div style={{ height: "80%", width: "100%" }}>
                  <ECharts option={lineOption} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.specialTarget}>
          <div className={styles.chartContent}>绩效指标</div>
          <div className={styles.tableWarp}>
            {specialTargetData.map((item, index) => (
              <div className={styles.tableItem} key={index}>
                <div className={styles.tableTh}>
                  <div className={styles.text}>{item.name}</div>
                </div>
                <div
                  className={styles.tableTd}
                  onClick={() =>
                    window.open(
                      `/front/target-detail?name=${
                        detailParams.employeeName
                      }&structId=${detailParams.structId}&indexIds=${
                        item.id
                      }&userId=${detailParams.employId}&dateCycle=${
                        detailParams.startTime + "~" + detailParams.endTime
                      }`,
                      "_blank"
                    )
                  }
                >
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );

    return node;
  };

  return (
    <div className={styles.rankDetailContainer}>
      {IsShowLeftList ? (
        <div className={styles.leftRank}>
          <div className={styles.rankFoldOutIcon}>
            <MenuFoldOutlined
              onClick={() => {
                setIsShowLeftList(false);
              }}
              style={{ fontSize: "20px", color: "rgb(22, 93, 255)" }}
            ></MenuFoldOutlined>
          </div>
          <div className={styles.topThree}>
            <div
              className={`${styles.topLeft} ${
                selectedId === topThree[1]?.employeeId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  achievementId: topThree[1]?.achievementId,
                  employId: topThree[1]?.employeeId,
                  employeeName: topThree[1]?.employeeName,
                })
              }
            >
              <img src={th2} alt="th2" className={styles.topIcon} />
              <div className={styles.portrait}>
                {subName(topThree[1]?.employeeName)}
              </div>
              <img src={tf2} alt="tf2" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[1]?.employeeName}</p>
              <p className={styles.score}>
                {topThree[1]?.avgScore || topThree[1]?.totalScore.toFixed(2)}
              </p>
            </div>
            <div
              className={`${styles.topCenter} ${
                selectedId === topThree[0]?.employeeId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  achievementId: topThree[0]?.achievementId,
                  employId: topThree[0]?.employeeId,
                  employeeName: topThree[0]?.employeeName,
                })
              }
            >
              <img src={th1} alt="th1" className={styles.topIcon} />
              <div className={styles.topRankWarp}>
                <div className={styles.portrait}>
                  {subName(topThree[0]?.employeeName)}
                </div>
              </div>
              <img src={tf1} alt="tf1" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[0]?.employeeName}</p>
              <p className={styles.score}>
                {topThree[0]?.avgScore || topThree[0]?.totalScore.toFixed(2)}
              </p>
            </div>
            <div
              className={`${styles.topRight} ${
                selectedId === topThree[2]?.employeeId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  achievementId: topThree[2]?.achievementId,
                  employId: topThree[2]?.employeeId,
                  employeeName: topThree[2]?.employeeName,
                })
              }
            >
              <img src={th3} alt="th3" className={styles.topIcon} />
              <div className={styles.portrait}>
                {subName(topThree[2]?.employeeName)}
              </div>
              <img src={tf3} alt="tf3" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[2]?.employeeName}</p>
              <p className={styles.score}>
                {topThree[2]?.avgScore || topThree[2]?.totalScore.toFixed(2)}
              </p>
            </div>
          </div>
          <div className={styles.other}>
            {leftList.map((item, index) => (
              <div
                className={`${styles.otherRankItem} ${
                  selectedId === item.employeeId ? styles.otherActive : ""
                }`}
                key={index}
                onClick={() =>
                  handleClickItem({
                    employId: item?.employeeId,
                    employeeName: item.employeeName,
                  })
                }
              >
                <div className={styles.num}>{index + 4}</div>
                <div
                  className={styles.avtor}
                  style={{
                    border: `1px solid ${
                      tranColor(item.employeeName).colorStr
                    }`,
                    backgroundColor: tranColor(item.employeeName).backColorStr,
                    color: tranColor(item.employeeName).colorStr,
                  }}
                >
                  {subName(item.employeeName)}
                </div>
                <div className={styles.name}>{item.employeeName}</div>
                <div className={styles.pro}>
                  <Progress
                    percent={item?.avgScore || item?.totalScore}
                    showInfo={false}
                    strokeWidth={4}
                  />
                </div>
                <div className={styles.score}>
                  {item?.avgScore || item.totalScore.toFixed(2)}
                </div>
                {selectedId === item.employeeId && (
                  <div className={styles.arrow}></div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.bottomMask}></div>
        </div>
      ) : (
        <div style={{ cursor: "pointer", marginTop: 10 }}>
          <MenuUnfoldOutlined
            style={{
              fontSize: "20px",
              color: "rgb(22, 93, 255)",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsShowLeftList(true);
            }}
          />
        </div>
      )}
      <div className={cx(styles.reportRight)}>
        {/* <Spin spinning={detailLoading}>{renderRankorTar()}</Spin> */}
        <Spin spinning={detailLoading}>
          <div className={styles.reportRightContent}>
            <div className={styles.personInfo}>
              <img src={userImg} alt="画像" className={styles.img}></img>
              <span className={styles.title}>
                {detailParams.employeeName}画像
              </span>
            </div>
            <div className={styles.detailInfo}>
              <Row>
                <Col span={8}>
                  <span className={styles.title}>排名：</span>
                  <span className={styles.title}>1/5</span>
                </Col>
                <Col span={8}>
                  <span className={styles.title}>平均分：</span>
                  <span className={styles.title}>80</span>
                </Col>
                <Col span={8}>
                  <span className={styles.title}>综合得分中位值：</span>
                  <span className={styles.title}>80</span>
                </Col>
              </Row>
            </div>
            <div className={styles.chartProfile}>
              <div className={cx(styles.chartLeft)}>
                <ECharts option={averageChartOptions()} />
              </div>
              <div className={styles.chartRight}>
                <UniveralEcharts
                  style={{ height: "100%", width: "100%" }}
                  clickEvent={(params) => {
                    console.log(params, "killport 端口号");
                  }}
                  option={achievementChartOptions()}
                />
              </div>
            </div>
            <div className={styles.chartWarp}>
              <div className={styles.chartItem} style={{ borderRight: "none" }}>
                <div className={styles.chartContent}>历史排名</div>
                <div style={{ height: "80%", width: "100%" }}>
                  <ECharts option={lineOption} />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}
