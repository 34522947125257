import moment from "moment";
export const handleData = (data) => {
  let dataSource = [];
  const names = data[0].chart[0].chartItem.map((item) => item.label);
  const axisNames = data[0].chart.map((item) => item.chartName);
  for (let i = 0; i < data.length; i++) {
    const single = data[i];
    let finalData = [];
    let viewData = [];
    for (let j = 0; j < names.length; j++) {
      for (let k = 0; k < single.chart.length; k++) {
        const item = single.chart[k];
        const chartItems = single.chart[k].chartItem;
        for (let i = 0; i < chartItems.length; i++) {
          if (chartItems[i].label === names[j]) {
            finalData.push({
              id: names[j],
              name: item.chartName,
              blockIndexId: item.blockIndexId,
              nodeId: single.nodeId,
              down: single.down,
              indexId: chartItems[i].indexId,
              nodeName: single.nodeName,
              structId: single.structId,
              value: (Number(chartItems[i].value) + 7).toFixed(2),
            });
          }
        }
      }
    }
    for (let m = 0; m < names.length; m++) {
      const needData = finalData.filter((item) => item.id === names[m]);
      viewData.push({
        name: names[m],
        data: needData,
      });
    }
    dataSource.push({
      title: single.nodeName,
      viewData,
      type: "barXAxisStacked",
      axisNames,
      date: "aaaa",
    });
  }
  return dataSource;
};

export const handleDate = (date) => {
  const currentMonth = moment(date).month()
  const newDay = new Date().getDate();
  const newMonth = new Date().getMonth();
  let startDate;
  let endDate = moment(date).endOf("day").format("YYYY-MM-DD");
  if (newDay === 1) {
    startDate = moment()
      .subtract(1, "months")
      .startOf("M")
      .format("YYYY-MM-DD");
    return [startDate, endDate];
  }else if(currentMonth!==newMonth){
    startDate = moment(date).startOf("M").format("YYYY-MM-DD");
    return [startDate, endDate];
  } else {
    startDate = moment().startOf("M").format("YYYY-MM-DD");
    return [startDate, endDate];
  }
};

export const targetData = [
  {
    nodeId: "x898kkXDqnFusRQYmSN",
    nodeType: 0,
    nodeName: "研发驾驶舱",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "1358",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: "3",
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "896",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: "3",
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: "11",
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: "5",
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: "11",
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: "5",
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f01e7dd86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "DSC开发部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "555",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: "2",
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "387",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: "2",
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: "1",
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: "1",
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f06f57d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "DSC+开发部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "373",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "248",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: "7",
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: "7",
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f02419d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "基础开发部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "167",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: "1",
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "94",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: "1",
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: "3",
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: "4",
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: "3",
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: "4",
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f01481d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "行业智能IDST",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "143",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "91",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f0094ad86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "IFP产品线",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "88",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "52",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f00851d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "数链DSC产品线",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "28",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "21",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24eff5f8d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "产品部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "2",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f05555d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "战略行业事业部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f03ca0d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "运作质量部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: "1",
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f05d78d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "其他",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f0087ed86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "解决方案体系",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f027e9d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "品牌宣策部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
  {
    nodeId: "24f05597d86111ed8383005056b7eac9",
    nodeType: 0,
    nodeName: "政府创新事业部",
    down: true,
    structId: "x898kkXDqnFusRQYmSN",
    chart: [
      {
        blockIndexId: "83idwushazUTocvPkL3",
        chartName: "未完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ih0YLJREtCdBuLRw0Vi5",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "icDhyqwOk86shQxnuldu",
          },
        ],
      },
      {
        blockIndexId: "fHPpYBLTc40IUnKKowl",
        chartName: "未完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i7n2jdlbvmkOsuebWgpS",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iRXxjNgqJrehkBpibPxa",
          },
        ],
      },
      {
        blockIndexId: "QsTwIzyYwMns2EAo46j",
        chartName: "完成需求",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "i3OrKfa0N9BhU21kO46w",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "idkorTGf08EGsmmUI2nr",
          },
        ],
      },
      {
        blockIndexId: "TebV5GxAAHhPrD12geC",
        chartName: "完成需求（高）",
        chartItem: [
          {
            value: 0,
            label: "逾期数",
            indexId: "ipt2HEEXxs3pTd2HPaVm",
          },
          {
            value: 0,
            label: "按期数",
            indexId: "iKlneXfhlN50tAzFPfmv",
          },
        ],
      },
    ],
  },
];


export const getParamsDate = () => {
  const newDay = new Date().getDay();
  let startDate;
  if (newDay === 1) {
    startDate = moment()
    .subtract(1, "months")
    .endOf("M")
    .format("YYYY-MM-DD HH:mm:ss");
  }else{
    startDate = moment()
    .endOf("M")
    .format("YYYY-MM-DD HH:mm:ss");
  }
  return startDate
}
