// 下钻前需要先处理数据
import { MINLIMIT } from 'src/utils'

export const handeNewDrillData = (data = {}, map = {}) => {
  const { drillDownKey, canDrillDown, indexId, structId } = map;
  for (let i = 0; i < data.series.length; i++) {
    const single = data.series[i].data.map((item) => {
      return {
        value: item < MINLIMIT ? 0 : item,
        isTrue: item < MINLIMIT ? true : false,
        drillDownKey,
        canDrillDown,
        indexId,
        structId,
      };
    });
    data.series[i].data = single;
  }
  return data;
};
// 这里需要对不同类型的图处理下钻参数
export const handelDrillParams = (type = "bar", params = {}) => {
  const {
    name,
    seriesName,
    data: { canDrillDown, drillDownKey, title, indexId, structId },
  } = params;
  switch (type) {
    case "bar":
      return {
        name,
        canDrillDown,
        drillDownKey,
        indexId,
        structId,
      };
    case "line":
      return {
        name: seriesName || name,
        canDrillDown,
        drillDownKey,
        indexId,
        structId,
      };
    case "pie":
      return {
        name: name || title,
        canDrillDown,
        drillDownKey,
        indexId,
        structId,
      };
    default:
      return {
        name: name || title,
        canDrillDown,
        drillDownKey,
        indexId,
        structId,
      };
  }
};
const getSumPieData = (data) => {
  return data
    .map((item) => (item.value < MINLIMIT ? 0 : item.value))
    .reduce((pre, next) => {
      return pre + next;
    }, 0);
};
// 饼图需要单独再次处理数据
export const handlePieData = (finalData = {}, map = {}) => {
  const { canDrillDown, drillDownKey, indexId, structId } = map;
  const unit = map.unit ===null ?'':map.unit
  const newData = finalData.series.map((it) => {
    const sum = getSumPieData(it.data);
    return {
      title: it.timeline,
      indexId: indexId,
      canDrillDown,
      drillDownKey,
      structId,
      data: it.data.map((item) => {
        // 需要去判断真假0
        const value = item.value < MINLIMIT ? 0 : item.value;
        let percent =
          sum === 0
            ? Number(0 * 100).toFixed(2)
            : Number((value / sum) * 100).toFixed(2);
        return {
          canDrillDown: canDrillDown,
          drillDownKey: drillDownKey,
          value: value,
          name: item.name,
          percent: percent,
          unit,
          title: it.timeline,
        };
      }),
      type: "pie",
    };
  });
  return newData;
};
export const handleBarAndLineMixData = (finalData = {}, map = {}) => {
  const { canDrillDown, drillDownKey, indexId, structId ,baseline,markLine} = map;
  const newData = finalData.series.map(item=>{
    return {
      ...item,
      indexId: indexId,
      canDrillDown,
      drillDownKey,
      structId,
      markLine,
      baseline,
      type:'barAndLineMix'
    }
  })
  return newData
}
// 渲染页面提示语
export const renderTooltip = (type) => {
  let message = "";
  switch (type) {
    case "bar":
      message = "点击图表图形可向下钻取";
      break;
    case "line":
      message = "点击图表图形折线上的点可向下钻取";
      break;
    case "pie":
      message = "点击图表标题可向下钻取";
      break;
    case "barAndLineMix":
      message = "点击图表标题可向下钻取";
      break;
    default:
      message = "点击图表图形可向下钻取";
      break;
  }
  return message;
};
