import moment from "moment";
import { MINLIMIT } from "src/utils";
/**
 * 公共颜色池
 */
const globalColor = [
  "#7196FE",
  "#28BF7E",
  "#F4BB15",
  "#6fecf6",
  "#8f61c0",
  "#ED7C2F",
  "#e55858",
  "#4A5BDC",
  "#7E79FF",
  "#B0D14A",
  "#67E0E3",
  "#4CD698",
  "#3984B6",
  "#B5BFD9",
  "#BCE684",
];

/**
 *  图表模板-新版报告使用
    1、line（折线图，多条数据可设置多坐标轴）；
	2、barXAxis（单坐标轴条形图）；
	3、barYAxis（双坐标轴柱状图）
	4、barMix（条形图组合，正负轴，交付质量和交付效率）；
	5、ranks（综合排名）；
	6、pie（饼图，圆环）；
	7、gauge（仪表盘）；
	8、reportScore（综合得分，报告中）；
	9、radar（雷达图，综合能力）；
	10、barAndLineMix（柱状图和折线图组合，可设置多坐标轴）；
	11、lineMix（首页产出总数、资源点数和工作量组合）；
 */
/**
 * line（折线图，多条数据可设置多坐标轴）
 */
// 把周的日期格式处理为日期
const handleWeekDate = (categories) => {
  let newCategories = [];
  if (categories.some((item) => item.includes("周"))) {
    newCategories = categories.filter(Boolean).map((item) => {
      if (item !== "null") {
        const [yearStr, weekNumStr] = item.split("-");
        const year = parseInt(yearStr);
        const weekNum = parseInt(weekNumStr.replace("周", ""));
        const mondayOfWeek = moment()
          .year(year)
          .week(weekNum)
          .startOf("isoWeek");
        return mondayOfWeek.format("MM-DD");
      }
    });
    return newCategories;
  } else {
    newCategories = categories;
    return categories;
  }
};
// 处理柱状图最大值显示问题（为了基准线问题）
const getbarXAxisMaxValue = (map, data) => {
  let max = 0;
  let arr = [];
  if (map.markLine && map.markLine.length) {
    map.markLine.forEach((item) => {
      arr.push(item.value);
    });
  }
  arr.push(map.maxValue);
  for (let i = 0; i < data.series.length; i++) {
    const singleData = data.series[i].data;
    for (let j = 0; j < singleData.length; j++) {
      const item = singleData[j];
      arr.push(item.value);
    }
  }
  return Math.max(...arr)+1;
};
const getBarXAxixMinValue = (map,data) => {
  let arr = [];
  if (map.markLine && map.markLine.length) {
    map.markLine.forEach((item) => {
      arr.push(item.value);
    });
  }
  for (let i = 0; i < data.series.length; i++) {
    const singleData = data.series[i].data;
    for (let j = 0; j < singleData.length; j++) {
      const item = singleData[j];
      arr.push(item.value);
    }
  }
  return Math.min(...arr)||0;
}
const getSum = (data) => {
  return data
    .reduce((pre, next) => {
      return pre + next;
    }, 0)
    .toFixed(2);
};

// 处理饼状图数据需要加上占比
const handlePieData = (series) => {
  for (let i = 0; i < series.length; i++) {
    const sum = getSum(
      series[i].data.map((item) => (item.value < MINLIMIT ? 0 : item.value))
    );
    const singleData = series[i].data;
    for (let j = 0; j < singleData.length; j++) {
      const value = singleData[j].value < MINLIMIT ? 0 : singleData[j].value;
      singleData[j].value = value;
      if (sum === 0) {
        singleData[j].percent = `${(0 * 100).toFixed(2)}%`;
      } else {
        singleData[j].percent = `${((value / sum) * 100).toFixed(2)}%`;
      }
    }
  }
  return series;
};
const chartLine = (data, map, params = {}) => {
  const { isDetail = false } = params;
  let extremumObj = {
    max: ((getbarXAxisMaxValue(map, data) * 110) / 100).toFixed(0),
    min: 0,
  };
  let option = {
    animation: true,
    color: globalColor,
    tooltip: {
      trigger: "axis",
      triggerOn: "mousemove",
      axisPointer: {
        axis: "x",
        snap: true,
      },
      valueFormatter: (value) => {
        const unit = map.unit === null ? "" : map.unit;
        return `${value}${unit}`;
      },
      position: function (point, params, dom, rect, size) {
        const axisValue = params[0].axisValue;
        dom.style.text = `${axisValue}\n${dom.style.text}`;
      },
      show: true,
      confine: true,
      backgroundColor: "rgba(0,0,0,0.8)",
      extraCssText: "box-shadow: 0 0 3px rgba(255, 255, 255, 0.87);",
      textStyle: {
        color: "#fff",
      },
      borderWidth: 0,
    },
    grid: {
      top: 20,
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
    },
    textStyle: {
      fontSize: 11,
    },
    xAxis: [
      {
        type: "category",
        axisLabel: {
          show: true,
          color: "#8A94A6",
          fontSize: 9,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#9BA1A8",
          },
        },
        boundaryGap: false,
        data: handleWeekDate(data?.xAxis?.data ?? data.categories),
      },
    ],
    yAxis: [
      {
        type: "value",
        ...extremumObj,
        axisLabel: {
          show: data.yAxis === "false" ? false : true,
          color: "#8A94A6",
          fontSize: 12,
          formatter: function (value) {
            if (value.length > 7) {
              return value.substring(0, 7) + "...";
            } else {
              return value;
            }
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#9BA1A8",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#E0E6F1",
          },
        },
        min: function (value) {
          if (value.min < 0) {
            return value.min;
          } else {
            return 0;
          }
        },
      },
    ],
  };
  if (data?.xAxis) {
    option.legend = {
      data: data?.categories,
      textStyle: {
        color: "#8A94A6",
      },
      type: "scroll",
    };
    option.grid = {
      top: 30,
      left: "3%",
      right: "6%",
      bottom: "3%",
      containLabel: true,
    };
  } else {
    option.legend = {
      left: -10000,
      data: [],
    };
  }
  for (let i = 0; i < data.series.length; i++) {
    data.series[i].type = data.type;
    data.series[i].smooth = true;
    data.series[i].cursor = isDetail ? "pointer" : "default";
  }
  let newMarkLine = [];
  if (map.markLine && map.markLine.length) {
    newMarkLine.push(map.markLine);
    map.markLine = newMarkLine;
  }
  if (map.markLine && map.markLine.length !== 0) {
    for (let i = 0; i < map.markLine.length; i++) {
      let markLineData = [];
      let markColor = ["#93CC74", "#EF6667", "#F4BB15"];
      for (let j = 0; j < map.markLine[i].length; j++) {
        //  平均线
        if (map.markLine[i][j].type === "01") {
          map.markLine[i][j].color = "";
        }
        markLineData.push({
          yAxis: map.markLine[i][j].value,
          name: "standard",
          label: {
            formatter: map.markLine[i][j].name
              ? map.markLine[i][j].name
              : "{c}",
            position: "end",
          },
          lineStyle: {
            color: map.markLine[i][j].color
              ? map.markLine[i][j].color
              : markColor[j],
          },
        });
      } //  目标值

      if (map.markLine[i].length > 2) {
        map.markLine[i][2].color = markColor[2];
        map.markLine[i][1].color = markColor[1];
      } else if (map.markLine[i].length === 2) {
        if (map.markLine[i][0].type === "01") {
          map.markLine[i][1].color = markColor[1];
          map.markLine[i][0].color = markColor[0];
        } else {
          map.markLine[i][1].color = markColor[2];
          map.markLine[i][0].color = markColor[1];
        }
      } else {
        if (map.markLine[i][0].type === "01") {
          map.markLine[i][0].color = markColor[0];
        } else {
          map.markLine[i][0].color = markColor[0];
        }
      }
      // 设置markLine中位线
      data.series[i].markLine = {
        symbol: ["none", "none"],
        silent: true,
        data: markLineData,
      };
    }
  } // 设置grid
  if (isDetail) {
    option.chartHeight = 395;
  }
  option.series = data.series;
  return option;
};

/**
 * barXAxis（条形图）
 */
const chartbarXAxis = (data, map, params = {}) => {
  const { isDetail = false } = params;
  let newMarkLine = [];
  let maxObj = {
    max: getbarXAxisMaxValue(map, data) * 1.2,
    // min: getBarXAxixMinValue(map,data),
  };
  if (map.markLine && map.markLine.length) {
    newMarkLine.push(map.markLine);
    map.markLine = newMarkLine;
  }

  let option = {
    animation: true,
    color: globalColor,
    grid: {
      top: "2%",
      left: "3%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
    },
    // legend: {
    // 	left: -10000,
    // 	data: chartLegend(data),
    // },
    textStyle: {
      fontSize: 11,
    },
    xAxis: [
      {
        type: "value",
        axisLabel: {
          show: false,
          color: "#8A94A6",
          fontSize: 12,
        },
        ...maxObj,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "category",
        axisLabel: {
          color: "#8A94A6",
          fontSize: 11,
          formatter: function (value) {
            if (value.length > 8) {
              return (
                value.substring(0, value.length / 2 + 1) +
                "\n" +
                value.substring(value.length / 2 + 1, value.length)
              );
            } else {
              return value;
            }
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        data: isDetail ? data.categories : data.categories.slice(0, 10),
        inverse: true,
      },
    ],
  };
  // series数据组装
  for (let i = 0; i < data.series.length; i++) {
    // 设置柱条宽度
    data.series[i].showBackground = false;
    data.series[i].type = "bar";
    data.series[i].cursor = isDetail ? "pointer" : "default";
    data.series[i].barMinHeight = 20;
    data.series[i].barMaxWidth = 25;
    data.series[i].label = {
      show: true,
      color: "#8A94A6",
      position: "right",
      fontSize: 14,
      formatter: function (e) {
        const unit = map.unit === null ? "" : map.unit;
        return `${e.data.value}${unit}`;
      },
    };

    data.series[i].itemStyle = {
      borderRadius: [0, 2, 2, 0],
      normal: {
        color: function (params) {
          return params.data.isTrue ? "#A5B8BD" : "#7196FE";
        },
      },
    };
    // if (map.markLine && map.markLine.length) {
    //   if (map.markLine[0].length > 1) {

    //     data.series[i].itemStyle = {
    //       ...data.series[i].itemStyle,
    //       color: (params) => {
    //         let markLine = map.markLine;
    //         let lowData;
    //         let heightData;
    //         markLine.forEach((item) => {
    //           item.forEach((ele, idx) => {
    //             if (idx === 0) {
    //               lowData = Number(ele.value);
    //             }
    //             if (idx === 1) {
    //               heightData = Number(ele.value);
    //             }
    //           });
    //         });
    //         console.log(params,'params')
    //         // if (params.value > heightData) {
    //         //   return "#EF6667";
    //         // }
    //         // if (params.value > lowData && params.value <= heightData) {
    //         //   return "#5671C5";
    //         // }
    //         // if (params.value < lowData) {
    //         //   return "#93CC74";
    //         // }
    //         return "#5671C5";
    //       },
    //     };
    //   }
    // }
  }

  // 设置markLine中位线
  if (map.markLine && map.markLine.length !== 0) {
    // 如果有中位线重新设置grid
    option.grid = {
      top: "2%",
      left: "3%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
      // top: "4%",
      // left: "3%",
      // right: "4%",
      // bottom: 0,
      // containLabel: true,
    };
    const markLinePosition = {
      0: "insideEndTop",
      1: "insideEnd",
      2: "insideEndBottom",
    };
    for (let i = 0; i < map.markLine.length; i++) {
      let markLineData = [];
      let markColor = ["#93CC74", "#EF6667", "#F4BB15"];
      for (let j = 0; j < map.markLine[i].length; j++) {
        markLineData.push({
          xAxis: map.markLine[i][j].value,
          // name: "standard",
          // symbol:'none',
          // symbol: ['circle', 'circle'],
          label: {
            formatter: map.markLine[i][j].name
              ? map.markLine[i][j].name
              : "{c}",
            position: "end",
            // position: markLinePosition[i],
            // distance:[-100,0]
          },
          lineStyle: {
            color: map.markLine[i][j].color
              ? map.markLine[i][j].color
              : markColor[j],
          },
        });
      }
      data.series[i].markLine = {
        symbol: ["none", "none"],
        data: markLineData,
        silent: true,
      };
    }
  }
  option.series = data.series;
  option.chartHeight = option.yAxis[0].data.length * 40;
  if (isDetail) {
    option.chartHeight = option.yAxis[0].data.length * 40 + 100;
  }
  if (option.yAxis[0].data.length > 11) {
    option.grid.top = "0%";
    option.grid.bottom = "3%";
    option.chartHeight = option.yAxis[0].data.length * 40;
  }
  return option;
};

/**
 * pie（基础饼图）
 */
const chartPieDefault = (data, map, params = {}) => {
  const unit = map.unit === null ? "" : map.unit;
  const { isDetail = false } = params;
  let option = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return `${params.name}(${params.percent || 0}% ${params.value}${unit})`;
      },
    },
    animation: false,
    color: globalColor,
    grid: {
      left: "3%",
      right: "3%",
      containLabel: true,
    },
    title: {
      show: true,
      textStyle: {
        fontSize: 0,
        width: 0,
        height: 0,
        lineHeight: 0,
        color: "#8A94A6",
      },
    },
    legend: {
      textStyle: {
        color: "#8A94A6",
      },
      data: data.categories,
      left: -10000,
    },
  };

  // series数据组装
  data.series[0].label = {
    formatter: (params) => {
      return `${params.name}(${params.percent || 0}% ${params.value}${unit})`;
    },
    backgroundColor: "transparent",
  };
  data.series[0].radius = ["40%", "70%"];
  data.series[0].type = "pie";
  data.series[0].cursor = isDetail ? "pointer" : "default";

  option.series = handlePieData(data.series);
  return option;
};

/***
 * barAndLineMix（柱状图和折线图组合，可设置双坐标轴）
 */
export const chartbarAndLineMix = (data, map, params = {}) => {
  let xAxisCategory = [];
  let newMarkLine = [];
  if (map.markLine && map.markLine.length) {
    newMarkLine.push(map.markLine);
    map.markLine = newMarkLine;
  }
  let markLineData = []
  if (map.markLine && map.markLine.length !== 0) {
    for (let i = 0; i < map.markLine.length; i++) {
    
      let markColor = ["#93CC74", "#EF6667", "#F4BB15"];
      const poitions = ['insideStartTop','insideStartBottom','middle']
      for (let j = 0; j < map.markLine[i].length; j++) {
        //  平均线
        if (map.markLine[i][j].type === "01") {
          map.markLine[i][j].color = "";
        }
        markLineData.push({
          yAxis: map.markLine[i][j].value,
          name: "standard",
          label: {
            formatter: map.markLine[i][j].name
              ? map.markLine[i][j].name
              : "{c}",
            position: poitions[j],
          },
          lineStyle: {
            color: map.markLine[i][j].color
              ? map.markLine[i][j].color
              : markColor[j],
          },
        });
      } //  目标值

      if (map.markLine[i].length > 2) {
        map.markLine[i][2].color = markColor[2];
        map.markLine[i][1].color = markColor[1];
      } else if (map.markLine[i].length === 2) {
        if (map.markLine[i][0].type === "01") {
          map.markLine[i][1].color = markColor[1];
          map.markLine[i][0].color = markColor[0];
        } else {
          map.markLine[i][1].color = markColor[2];
          map.markLine[i][0].color = markColor[1];
        }
      } else {
        if (map.markLine[i][0].type === "01") {
          map.markLine[i][0].color = markColor[0];
        } else {
          map.markLine[i][0].color = markColor[0];
        }
      }
      // 设置markLine中位线
    }
  } 
  const newSeries = data.series[0].datas.map((item, index) => {
    if (item.length) {
      xAxisCategory = item.map((it) => it.name);
    }
    return {
      type: data.series[0].chartTypes[index],
      unit: data.series[0].units[index],
      name: data.series[0].names[index],
      barMinHeight:5,
      cursor:'default',
      markLine:index === map.baseline?{
        symbol: ["none", "none"],
        silent: true,
        data: markLineData,
      }:{
        data:[]
      },
      data: item.map((it, ind) => {
        return {
          ...it,
          value: it.value < MINLIMIT ? 0 : it.value,
          unit: data.series[0].units[index],
        };
      }),
      tooltip: {
        valueFormatter: function (value) {
          return value + data.series[0].units[index];
        },
      },
    };
  });
  let spaceNum = 5; //间隔数（刻度）
  function calMax(arr) {
    //计算最大值
    let maxA = Math.ceil(Math.max(...arr)); // 最大值A
    let maxint = Math.ceil(maxA / spaceNum); //最大平均值（向上取整）= 最大值A / 间隔数
    let maxB = maxint * spaceNum; // 最大值B = 最大平均值 * 间隔数  （注：最大值B>最大值A）
    return Math.max(maxB,5); // 为了防止maxB数据为0时，Y轴不显示，给个默认最大值10
  }
  const yAxis = newSeries.map((item) => {
    const maxArr = item.data.map(item=>item.value).concat(item.markLine.data.map(it=>it.yAxis)).filter(Boolean)
    const maxValue = maxArr.length? calMax(maxArr):0
    return {
      type: "value",
      min: 0,
      data:item.data,
      max: maxValue,
      splitNumber: 6,
      // interval: parseInt((maxValue - minValue) / 6),
      interval:maxValue/spaceNum ,
      axisLabel: {
        formatter: function (value, index) {
          return `${value}`;
        },
      },
    };
  }).filter(item=>item.data.length!==0);
  const maxyAxis = Math.max(...yAxis.map((item,index)=>index>0?item.max:0))
  const maxIndex = yAxis.findIndex(it=>it.max === maxyAxis)
  if(maxIndex === 2) {
    // 需要换位置
    let tmp 
   tmp =  yAxis[1]
   yAxis[1] =  yAxis[2]
   yAxis[2] =tmp
  }
  let finalSeries
  if(yAxis.length === 1){
      finalSeries = newSeries.map(item=>{
        return{
          ...item,
          yAxisIndex:0
        }
      })
  }else if(yAxis.length === 2){
    finalSeries = newSeries.map((item,index)=>{
      return{
        ...item,
        yAxisIndex:index === 0 ? 0:1
      }
    })
  }else if(yAxis.length === 3){
    finalSeries = newSeries.map((item,index)=>{
      return{
        ...item,
        yAxisIndex:index === 0 ? 0:1
      }
    })
  }
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    animation: true,
    color: globalColor,
    grid: {
      top: "15%",
      left: "5%",
      right: "5%",
      bottom: 15,
      containLabel: true,
    },
    textStyle: {
      fontSize: 11,
    },
    legend: {
      left: "center",
      top: "top",
      itemWidth: 9,
      itemHeight: 6,
      textStyle: {
        color: "#8A94A6",
      },
      show: true,
      type: "scroll",
      data: newSeries.map((item) => item.name).filter(Boolean),
    },
    xAxis: [
      {
        type: "category",
        axisLabel: {
          show: true,
          color: "#8A94A6",
          fontSize: 9,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#9BA1A8",
          },
        },
        data: handleWeekDate(xAxisCategory),
      },
    ],
    yAxis: yAxis.length >= 2 ? yAxis.slice(0, 2) : yAxis,
    series: finalSeries,
  };
  return option;
};
export const formatNewChartViewData = (data, map, params) => {
  switch (data.type) {
    case "line": // 折线图
      data.option = chartLine(data, map, params);
      break;
    case "bar": // 条形图
      data.option = chartbarXAxis(data, map, params);
      break;
    case "pie": // 饼图 默认形式
      data.option = chartPieDefault(data, map, params);
      break;
    case "barAndLineMix": // 柱状图和折线图组合，可设置多坐标轴
      data.option = chartbarAndLineMix(data, map, params);
      break;
    default:
      break;
  }
  return data;
};
