import moment from "moment";
export const handleAvaterOptions = (data) => {
  let options = [];
  data.forEach((item) => {
    if (item.resourceId === "11") {
      options = item.children;
    }
  });
  if (options) {
    return options.map((item) => {
      return {
        label: item.name,
        value: JSON.parse(item.config).link,
      };
    });
  }
  return [];
};
export const getDate = (type) => {
  let date = [];
  let startDate;
  let endDate;
  switch (type) {
    case "currentWeek":
      date = [moment().startOf("W"), moment().endOf("W")];
      break;
    case "lastWeek":
      date = [
        moment().subtract(1, "weeks").startOf("W"),
        moment().subtract(1, "weeks").endOf("W"),
      ];
      break;
    case "currentMonth":
      date = [moment().startOf("M"), moment().endOf("M")];
      break;
    case "lastMonth":
      date = [
        moment().subtract(1, "months").startOf("M"),
        moment().subtract(1, "months").endOf("M"),
      ];
      break;
    case "currentQuarter":
      date = [moment().startOf("Q"), moment().endOf("Q")];
      break;
    case "lastQuarter":
      startDate = moment()
        .quarter(moment().quarter() - 1)
        .startOf("quarter");
      endDate = moment()
        .quarter(moment().quarter() - 1)
        .endOf("quarter");
      date = [startDate, endDate];
      break;
    case "lastSevenDays":
      endDate = moment().subtract(1, "days").endOf("D");
      startDate = moment().subtract(7, "days").startOf("D");
      date = [startDate, endDate];
      break;
    case "lastThirtyDays":
      endDate = moment().subtract(1, "days").endOf("D");
      startDate = moment().subtract(30, "days").startOf("D");
      date = [startDate, endDate];
      break;
    default:
      break;
  }
  return date;
};
