import React from "react";
import { Button ,Result} from "antd";
import config from "src/commons/config-hoc";

export default config({
  path: "/front/error-login",
  auth: false,
  layout: false,
})(function ErrorLogin(props) {
  const goBack = () => {
    window.close()
  };
  return (
    <Result
      status="404"
      title="登录失败"
      subTitle="请联系管理员确认登录账号信息"
      extra={<Button type="primary" onClick={()=>goBack()}>退出登录</Button>}
    />
  );
});
