import React, { useEffect, useState } from "react";
import config from "src/commons/config-hoc";
import { PageContent, getQuery } from "@ra-lib/admin";
import ECharts from "../../components/echarts";
import userImg from "../../image/profile_user.png";
import cx from "classnames";
import { message, notification, Button } from "antd";
import moment from "moment";
import styles from "./style.less";
import { rankLineOptions } from "../rank/components/RankListDetail/utils";
import classNames from "classnames";
export default config({
  path: "/front/rank-achi-detail",
  title: "员工画像",
})(function RankAchiDetail(props) {
  let parms = props.match.params;
  const {
    name,
    cycleType,
    cycleValue,
    schemeId,
    employId,
    startTime,
    endTime,
    structId,
  } = getQuery();
  const [indexRadarOption, setIndexRaderOption] = useState({});
  const [detailLoading, setDetailLoading] = useState(false);
  const [radarOption, setradarOption] = useState({});
  const [specialTargetData, setspecialTargetData] = useState([]);
  const [lineOption, setlineOption] = useState({});

  const handleRadarOption = (dataArr, title) => {
    let indicator =
      dataArr.length > 0
        ? dataArr.map((item) => ({
            name: item.dimension,
            max: 100,
          }))
        : [{ name: "", max: 100 }];
    const radarData = [0, 0];
    radarData[0] = {
      value: dataArr.length > 0 ? dataArr.map((item) => item.indexScore) : [0],
      name: "得分",
      areaStyle: {
        normal: {
          opacity: 0.9,
          color: "#4D7CFE70",
        },
      },
      lineStyle: {
        color: "#4D7CFE",
      },
      itemStyle: {
        color: "#4D7CFE",
      },
      symbolSize: 3,
    };
    radarData[1] = {
      value:
        dataArr.map((item) => item.wholeAverageScore).length > 0
          ? dataArr.map((item) => item.wholeAverageScore)
          : [0],
      name: "全员平均值",
      lineStyle: {
        type: "dashed",
        color: "#E2675B",
        width: 1,
      },
      symbol: "none",
      itemStyle: {
        color: "#E2675B",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    radarData[2] = {
      value:
        dataArr.map((item) => item.schemeAverageScore).length > 0
          ? dataArr.map((item) => item.schemeAverageScore)
          : [0],
      name: "部门平均值",
      lineStyle: {
        type: "dashed",
        color: "#EAB308",
        width: 1,
      },
      symbol: "none",
      itemStyle: {
        color: "#EAB308",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    return {
      title: {
        text: title,
      },
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        axisPointer: {
          axis: "x",
          snap: true,
        },
        confine: true,
      },
      legend: {
        data: ["得分", "全员平均值", "部门平均值"],
        bottom: 0,
      },
      radar: {
        center: ["50%", "45%"],
        radius: "65%",
        tooltip: {
          show: true,
        },
        indicator,
      },
      series: [
        {
          type: "radar",
          data: radarData,
        },
      ],
    };
  };
  useEffect(() => {
    setDetailLoading(true);
    props.ajax
      .post("/api/achievement/scheme/drillDown", {
        schemeId,
        cycleType,
        cycleValue,
        dataId: employId,
      })
      .then((res) => {
        if (!res.data) {
          message.info("暂无数据");
          return false;
        }
        const {
          canDrillDown,
          indexResults = [],
          indexTags = [],
          indexValues = [],
          ranks,
          records = [],
        } = res.data || {};
        setradarOption(handleRadarOption(indexTags, "维度画像"));
          setIndexRaderOption(handleRadarOption(indexResults, "指标画像"));
          let resArr = indexValues.map((item) => ({
            name: item.indexName,
            value: item.value,
            id: item.indexId,
          }));
          setspecialTargetData(resArr);
          setlineOption(rankLineOptions(ranks));
          setDetailLoading(false);
      });
  }, [employId]);
  
  const renderRankorTar = () => {
    let node = (
      <React.Fragment>
        <div className={styles.rankTab}>
          <div className={styles.chartRadar}>
            <div className={styles.chartInfo}>
              <img src={userImg} alt="画像" className={styles.img}></img>
              <span className={styles.title}>{name}画像</span>
            </div>
            <div className={styles.radarProfile}>
              <div className={cx(styles.radarLeft)}>
                <ECharts option={indexRadarOption} />
              </div>
              <div className={styles.radarRight}>
                <ECharts option={radarOption} />
              </div>
            </div>
          </div>
          {
            <div className={styles.chartWarp}>
              <div className={styles.chartItem} style={{ borderRight: "none" }}>
                <div className={styles.chartContent}>历史排名</div>
                <div style={{ height: "80%", width: "100%" }}>
                  <ECharts option={lineOption} />
                </div>
              </div>
            </div>
          }
        </div>
        <div className={styles.specialTarget}>
          <div className={styles.chartContent}>绩效指标</div>
          <div className={styles.tableWarp}>
            {specialTargetData.map((item, index) => (
              <div className={styles.tableItem} key={index}>
                <div className={styles.tableTh}>
                  <div className={styles.text}>{item.name}</div>
                </div>
                <div
                  className={styles.tableTd}
                  onClick={() =>
                    window.open(
                      `/front/target-detail?name=${
                        name
                      }&structId=${structId}&indexIds=${
                        item.id
                      }&userId=${employId}&dateCycle=${
                        startTime + "~" + endTime
                      }`,
                      "_blank"
                    )
                  }
                >
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );

    return node;
  };
  return (
    <PageContent fitHeight className={styles.reportDetail}>
      {renderRankorTar()}
    </PageContent>
  );
});
