import React, { useEffect, useRef, useState,Fragment } from "react";
import ECharts from "../../../../components/echarts";
import { tranColor, subName } from "../../utils/index";
import styles from "./style.less";
import cx from "classnames";
import moment from "moment";
import {
  rankLineOptions,
  handleRadarChartData,
} from "./utils";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Progress, Button, message, notification, Spin, Col, Row ,Tooltip} from "antd";
import RankModal from "../RankModal";
import th1 from "../../images/top-header-1.png";
import userImg from "../../../../image/profile_user.png";
import tf1 from "../../images/top-footer-1.png";
import th2 from "../../images/top-header-2.png";
import tf2 from "../../images/top-footer-2.png";
import th3 from "../../images/top-header-3.png";
import tf3 from "../../images/top-footer-3.png";

export default function RankListDetail(props) {
  const { parentProps, detailParams, fullData, handleClickItem,schemeName } = props;
  const [lineOption, setlineOption] = useState({});
  const [radarOption, setradarOption] = useState({});
  const [indexRadarOption, setIndexRaderOption] = useState({});
  const [isTarget, setisTarget] = useState(false);
  const [portraitIndexMap, setportraitIndexMap] = useState([]);
  const [IsShowLeftList, setIsShowLeftList] = useState(true);
  const [portraitMap, setportraitMap] = useState([]);
  const [topThree, settopThree] = useState([]);
  const [leftList, setleftList] = useState([]);
  const [specialTargetData, setspecialTargetData] = useState([]);
  const [detailLoading, setDetailLoading] = useState(false);
  const [canDrillDown, setCanDrillDown] = useState(false);
  const [selecedItem, setSelectedItem] = useState({});
  const [selectedId, setSelectId] = useState("");
  const [records, setRecords] = useState([]);
  const [modalProps,setModalProps] = useState({})
  const [structId,setStructId] = useState("")
  const [schemeRecordsId,setSchemeRecordsId] = useState("")
  const [modalVisible,setModalVisible] = useState(false)
  const [groupName,setGroupName] = useState([])
  const [isShowRaider,setIsShowRaider] = useState(true)
  const detailRankData = (list) => {
    const currentTopThree = list.slice(0, 3);
    const currentLeftList = list.slice(3, list.length);
    settopThree(currentTopThree);
    setleftList(currentLeftList);
  };
  const getDetail = (params,finalScore) => {
    const { employId } = params;
    setDetailLoading(true);
    parentProps.ajax
      .post("/api/achievement/scheme/drillDown", {
        ...params,
        dataId: employId,
      })
      .then((res) => {
        if (!res.data) {
          message.info("暂无数据");
          return false;
        }
        const {
          canDrillDown,
          indexResults = [],
          indexTags = [],
          indexValues = [],
          ranks,
          records = [],
          structId,
          schemeRecordsId
        } = res.data || {};
        console.log(indexResults,'indexResults')
        if(indexResults.length ===0){
          setIsShowRaider(false)
        }else{
          setIsShowRaider(true)
        }
        setCanDrillDown(canDrillDown);
        setSchemeRecordsId(schemeRecordsId)
        setStructId(structId)
        if (canDrillDown) {
          const averageItem = {
            name:'综合得分',
            score:finalScore
          }
          setRecords([averageItem,...records]);
          const dimensionChartData = handleRadarChartData(indexResults,true);
          console.log(dimensionChartData,'dimensionChartData')
          setGroupName(dimensionChartData.seriesNames||[])
          const indexChartData = handleRadarChartData(indexResults); 
          setradarOption(handleRadarOption(dimensionChartData));
          setIndexRaderOption(handleRadarOption(indexChartData));
        } else {
          const dimensionChart = handleRadarChartData(indexResults,true);
          const indexChart = handleRadarChartData(indexResults); 
          setGroupName(dimensionChart.seriesNames||[])
          setradarOption(handleRadarOption(dimensionChart));
          setIndexRaderOption(handleRadarOption(indexChart));
          let resArr = dimensionChart?.indexValues.map((item) => ({
            name: item.indexName,
            value: item.value,
            id: item.indexId,
          }));
          setspecialTargetData(resArr);
        }
        setlineOption(rankLineOptions(ranks));
        setDetailLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDetailLoading(false);
      });
  };
  const handleRadarOption = (chartData) => {
    const { radarData:data,title,indicator:indicators} = chartData;
    let indicator =
    indicators.length > 0
        ? indicators.map((item) => ({
            name: item.dimension,
            max: 100,
            color:item.color,
          }))
        : [{ name: "", max: 100 }];
    const radarData = [0, 0];
    radarData[0] = {
      value:data[0],
      name: "得分",
      areaStyle: {
        normal: {
          opacity: 0.9,
          color: "#4D7CFE70",
        },
      },
      lineStyle: {
        color: "#4D7CFE",
      },
      itemStyle: {
        color: "#4D7CFE",
      },
      symbolSize: 3,
    };
    radarData[1] = {
      value:data[1],
      name: "全员平均值",
      lineStyle: {
        type: "dashed",
        color: "#E2675B",
        width: 1,
      },
      // symbol: "none",
      itemStyle: {
        color: "#E2675B",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    radarData[2] = {
      value: data[2],
      name: "部门平均值",
      lineStyle: {
        type: "dashed",
        color: "#EAB308",
        width: 1,
      },
      symbol: "roundRect",
      itemStyle: {
        color: "#EAB308",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };
    return {
      title: {
        text: title,
      },
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        axisPointer: {
          axis: "x",
          snap: true,
        },
        confine: true,
      },
      legend: {
        data: ["得分", "全员平均值", "部门平均值"],
        bottom: 0,
      },
      radar: {
        center: ["50%", "45%"],
        radius: "65%",
        tooltip: {
          show: true,
        },
        indicator,
      },
      series: [
        {
          type: "radar",
          data: radarData,
        },
      ],
    };
  };
  useEffect(() => {
    if (Object.keys(detailParams).length) {
      console.log(detailParams, "detailParams");
      const { employId,finalScore } = detailParams;
      const item = fullData.filter((it) => it.dataId === employId)[0];
      setSelectedItem(item);
      setSelectId(employId);
      getDetail(detailParams,finalScore??item?.finalScore);
      detailRankData(fullData);
    }
  }, [detailParams, fullData]);
  const renderRankorTar = () => {
    let node = (
      <React.Fragment>
        <div className={styles.rankTab}>
          <div className={styles.chartRadar}>
            <div className={styles.chartInfo}>
              <img src={userImg} alt="画像" className={styles.img}></img>
              <span className={styles.title}>
                {detailParams.employeeName}画像
              </span>
            </div>
            <div className={styles.radarProfile}>
              <div className={cx(styles.radarLeft)}>
                <ECharts option={indexRadarOption} />
              </div>
              <div className={styles.radarRight}>
                <ECharts option={radarOption} />
              </div>
            </div>
          </div>
          {detailParams.cycleType !== "5" && (
            <div className={styles.chartWarp}>
              <div className={styles.chartItem} style={{ borderRight: "none" }}>
                <div className={styles.chartContent}>历史排名</div>
                <div style={{ height: "80%", width: "100%" }}>
                  <ECharts option={lineOption} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.specialTarget}>
          <div className={styles.chartContent}>绩效指标</div>
          <div className={styles.tableWarp}>
            {specialTargetData.map((item, index) => (
              <div className={styles.tableItem} key={index}>
                <div className={styles.tableTh}>
                <Tooltip title={item.name}>
                <div className={styles.text}>{item.name}</div>
                </Tooltip>
                </div>
                <div
                  className={styles.tableTd}
                  onClick={() =>
                    window.open(
                      `/front/target-detail?name=${
                        detailParams.employeeName
                      }&structId=${structId}&indexIds=${
                        item.id
                      }&userId=${detailParams.employId}&dateCycle=${
                        detailParams.startTime + "~" + detailParams.endTime
                      }&schemeRecordsId=${schemeRecordsId}`,
                      "_blank"
                    )
                  }
                >
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );

    return node;
  };
  const handleClickScheme = (item,index) => {
    if(index ===0 ){
      return false
    }
    setModalProps({...item})
    setModalVisible(true)
  }
  const handleDrill = (params = {}) => {
    console.log(detailParams,'detailParams')
    const {
      cycleType,
      cycleValue,
      employId,
      schemeId,
      structId,
      startTime,
      endTime,
      employeeName
    } = detailParams;
    window.open(
      `/front/achievement-detail?cycleType=${cycleType}&cycleValue=${cycleValue}&employId=${employId}&schemeId=${schemeId}&startTime=${startTime}&endTime=${endTime}&structId=${structId}&schemeName=${schemeName}&employeeName=${employeeName}`
    );
  };
  const renderGroupName = () => {
    return (
      groupName.map((item, index) => {
        return <div style={{display:'flex',alignItems:'center'}} key={index}>
          <span style={{background:item.color,display:'block',width:'10px',height:'10px',marginRight:'3px'}}></span>
          <span>{item.name}</span>
        </div>
      })
    )
  }
  const renderCanDrillContent = () => {
    return (
      <div className={styles.reportRightContent}>
        <div className={styles.personInfo}>
          <img src={userImg} alt="画像" className={styles.img}></img>
          <span className={styles.title}>{detailParams.employeeName}画像</span>
        </div>
       {
        isShowRaider &&  <div className={styles.combinationChart}>
        <div className={styles.combinationContentLeft}>
              <div className={cx(styles.radarLeft)}>
                <ECharts option={indexRadarOption} />
              </div>
              <div className={styles.seriesName}>
                {renderGroupName()}
              </div>
              </div>
              <div className={styles.combinationContentRight}>
              <div className={styles.radarRight}>
                <ECharts option={radarOption} />
              </div>
              <div className={styles.seriesName}>
              {renderGroupName()}
              </div>
              </div>
        </div>
       }
        <div className={styles.chartProfile}>
          <div className={styles.tableWarp}>
          <Fragment>{records.map((item, index) => (
              <div className={styles.tableItem} key={index}>
                <div className={styles.tableTh}>
                <Tooltip title={item.name}>
                <div className={styles.text}>{item.name}</div>
                </Tooltip>
                </div>
                <div
                  className={cx(styles.tableTd,styles.unActive)}
                  onClick={() => {
                    handleClickScheme(item,index);
                  }}
                >
                  {item.score}
                </div>
              </div>
            ))}</Fragment>
          </div>
          <Button type='primary'  onClick={() => {
                    handleDrill();
                  }}>分数构成</Button>
        </div>
        {detailParams.cycleType !== "5" && (
          <div className={styles.chartWarp}>
            <div className={styles.chartItem} style={{ borderRight: "none" }}>
              <div className={styles.chartContent}>历史排名</div>
              <div style={{ height: "80%", width: "100%" }}>
                <ECharts option={lineOption} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={styles.rankDetailContainer}>
      {IsShowLeftList ? (
        <div className={styles.leftRank}>
          <div className={styles.rankFoldOutIcon}>
            <MenuFoldOutlined
              onClick={() => {
                setIsShowLeftList(false);
              }}
              style={{ fontSize: "20px", color: "rgb(22, 93, 255)" }}
            ></MenuFoldOutlined>
          </div>
          <div className={styles.topThree}>
            <div
              className={`${styles.topLeft} ${
                selectedId === topThree[1]?.dataId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  employId: topThree[1]?.dataId,
                  employeeName: topThree[1]?.dataName,
                })
              }
            >
              <img src={th2} alt="th2" className={styles.topIcon} />
              <div className={styles.portrait}>
                {subName(topThree[1]?.dataName)}
              </div>
              <img src={tf2} alt="tf2" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[1]?.dataName}</p>
              <p className={styles.score}>{topThree[1]?.finalScore}</p>
            </div>
            <div
              className={`${styles.topCenter} ${
                selectedId === topThree[0]?.dataId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  employId: topThree[0]?.dataId,
                  employeeName: topThree[0]?.dataName,
                })
              }
            >
              <img src={th1} alt="th1" className={styles.topIcon} />
              <div className={styles.topRankWarp}>
                <div className={styles.portrait}>
                  {subName(topThree[0]?.dataName)}
                </div>
              </div>
              <img src={tf1} alt="tf1" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[0]?.dataName}</p>
              <p className={styles.score}>{topThree[0]?.finalScore}</p>
            </div>
            <div
              className={`${styles.topRight} ${
                selectedId === topThree[2]?.dataId ? styles.topActive : ""
              }`}
              onClick={() =>
                handleClickItem({
                  employId: topThree[2]?.dataId,
                  employeeName: topThree[2]?.dataName,
                })
              }
            >
              <img src={th3} alt="th3" className={styles.topIcon} />
              <div className={styles.portrait}>
                {subName(topThree[2]?.dataName)}
              </div>
              <img src={tf3} alt="tf3" className={styles.bottomIcon} />
              <p className={styles.name}>{topThree[2]?.dataName}</p>
              <p className={styles.score}>{topThree[2]?.finalScore}</p>
            </div>
          </div>
          <div className={styles.other}>
            {leftList.map((item, index) => (
              <div
                className={`${styles.otherRankItem} ${
                  selectedId === item.dataId ? styles.otherActive : ""
                }`}
                key={index}
                onClick={() =>
                  handleClickItem({
                    employId: item?.dataId,
                    employeeName: item.dataName,
                  })
                }
              >
                <div className={styles.num}>{index + 4}</div>
                <div
                  className={styles.avtor}
                  style={{
                    border: `1px solid ${tranColor(item.dataName).colorStr}`,
                    backgroundColor: tranColor(item.dataName).backColorStr,
                    color: tranColor(item.dataName).colorStr,
                  }}
                >
                  {subName(item.dataName)}
                </div>
                <div className={styles.name}>{item.dataName}</div>
                <div className={styles.pro}>
                  <Progress
                    percent={item.finalScore}
                    showInfo={false}
                    strokeWidth={4}
                  />
                </div>
                <div className={styles.score}>{item.finalScore}</div>
                {selectedId === item.dataId && (
                  <div className={styles.arrow}></div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.bottomMask}></div>
        </div>
      ) : (
        <div style={{ cursor: "pointer", marginTop: 10 }}>
          <MenuUnfoldOutlined
            style={{
              fontSize: "20px",
              color: "rgb(22, 93, 255)",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsShowLeftList(true);
            }}
          />
        </div>
      )}
      <div className={cx(styles.reportRight)}>
        <Spin spinning={detailLoading}>
          {canDrillDown ? renderCanDrillContent() : renderRankorTar()}
        </Spin>
      </div>
      {/* {
        modalVisible && <RankModal {...parentProps} modalVisible={modalVisible} handleClose={()=>setModalVisible(false)} modalProps={modalProps} detailParams={detailParams}></RankModal>
      } */}
    </div>
  );
}
