// import styles from './style.less';

export default function Footer(props) {
    return null;
    // return (
    //     <footer className={styles.root}>
    //         京公网安备00000000000001号 京ICP证000001号 ©2021 xxx
    //     </footer>
    // );
}
