import { notification } from 'antd';
import { toLogin, ab2str } from './index';

function getErrorTip(error, tip) {
    if (tip && tip !== true) return tip;

    // ajax返回的错误信息
    if (error?.response) {
        const { status, data } = error.response;
        if (Object.prototype.toString.call(error.response.data) === '[object ArrayBuffer]') {
            ab2str(data,tip => {
                notification.error({
                    message: '失败',
                    description: tip,
                    duration: 2,
                });
            });
            return false;
        }
        if (status === 401) {
            return toLogin();
        } else {
            return data;
        }
    }

    if (error?.msg) return error.msg;

    return '未知错误';
}

export default function handleError({ error, tip }) {
    const description = getErrorTip(error, tip);
    if (!description) return;

    // 避免卡顿
    setTimeout(() => {
        notification.error({
            message: '失败',
            description,
            duration: 2,
        });
    });

    if (error.status === 5000) {
        setTimeout(() => { 
            toLogin()
        }, 2000)
    } 
}
