export const achievementChartOptions = () => {
  const options = {
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#464646",
          },
        },
        axisLine: {
          lineStyle: {
            width: 40,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: [
          {
            value: 20,
            name: "Perfect",
            title: {
              offsetCenter: ["0%", "-30%"],
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "-10%"],
            },
          },
          {
            value: 40,
            name: "Good",
            title: {
              offsetCenter: ["0%", "10%"],
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "30%"],
            },
          },
        ],
        title: {
          fontSize: 14,
        },
        detail: {
          width: 50,
          height: 14,
          fontSize: 14,
          color: "inherit",
          borderColor: "inherit",
          borderRadius: 20,
          borderWidth: 1,
          formatter: "{value}%",
        },
      },
    ],
  };
  return options;
};
export const averageChartOptions = () => {
  const options =  {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        center: ["50%", "75%"],
        radius: "90%",
        min: 0,
        max: 100,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, "#FF6E76"],
              [0.5, "#FDDD60"],
              [0.75, "#58D9F9"],
              [1, "#7CFFB2"],
            ],
          },
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-60%"],
          itemStyle: {
            color: "auto",
          },
        },

        axisTick: {
          length: 12,
          lineStyle: {
            color: "auto",
            width: 2,
          },
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: "auto",
            width: 5,
          },
        },
        axisLabel: {
          color: "#464646",
          fontSize: 20,
          distance: -60,
          rotate: "tangential",
          formatter: function (value) {
            if (value === 87.5) {
              return "优";
            } else if (value === 62.5) {
              return "良";
            } else if (value === 37.5) {
              return "中";
            } else if (value === 12.5) {
              return "差";
            }
            return "";
          },
        },
        title: {
          offsetCenter: [0, "-10%"],
          fontSize: 20,
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, "-35%"],
          valueAnimation: true,
        //   formatter: function (value) {
        //     return Math.round(value * 100) + "";
        //   },
          color: "inherit",
        },
        data: [
          {
            value: 40,
            name: "综合得分",
          },
        ],
      },
    ],
  };
  return options
};

export const rankLineOptions = (ranks = []) => {
  const options = {
    color: "#7196FE",
    tooltip: {
      show: true,
      trigger: "axis",
    },
    grid: {
      top: "8%",
      left: "2%",
      right: "2%",
      bottom: "2%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ranks && ranks.length ? ranks.map(item=>item.name):[],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      inverse: true,
    },
    series: [
      {
        data: ranks && ranks.length ?ranks.map(item=>item.value):[],
        type: "line",
      },
    ],
  };
  return options
}

/**
 * 生成随机颜色
 *
 * @returns 返回一个随机颜色值，格式为 #XXXXXX
 */
function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
const globalColor = [
  "#7196FE",
  "#28BF7E",
  "#F4BB15",
  "#6fecf6",
  "#8f61c0",
  "#ED7C2F",
  "#e55858",
  "#4A5BDC",
  "#7E79FF",
  "#B0D14A",
  "#67E0E3",
  "#4CD698",
  "#3984B6",
  "#B5BFD9",
  "#BCE684",
];
/**
 * 处理雷达图数据
 *
 * @param chartData 雷达图数据
 * @param isDimension 是否维度
 */
export const handleRadarChartData = (chartData,isDimension) => {
  console.log(chartData,'chartData')
  if(chartData.length === 0){
    return {
      radarData :[0, 0, 0],
      indicator:[],
      indexValues:[],
      seriesNames:[],
      title:'暂无数据'
  }
 }
  const seriesNames = chartData.map((item,index)=>{
    return {
      name:item.name,
      color:globalColor[index]
    }
  });
  const seriesData = []
  const indexScore = []
  const schemeAverageScore = []
  const wholeAverageScore = []
  const indexValues = chartData[0].indexValues
  for(let i =0;i<chartData.length;i++){
    let item = []
    if(isDimension){
      item = chartData[i].indexTags;
    }else{
     item = chartData[i].indexResults;
    }
    for(let j =0 ;j<item.length;j++){
      seriesData.push({
        dimension:item[j].dimension,
        color:seriesNames[i].color
      })
      indexScore.push(item[j].indexScore)
      schemeAverageScore.push(item[j].schemeAverageScore)
      wholeAverageScore.push(item[j].wholeAverageScore)
    }
  }
  return {
    radarData :[indexScore, wholeAverageScore,schemeAverageScore],
    title:isDimension? '维度画像':'指标画像',
    indicator:seriesData,
    seriesNames: seriesNames,
    indexValues:indexValues
  }
}