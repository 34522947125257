export const tranColor = (name) => {
    let code = "";
    let colorStr = "";
    let backColorStr = "";
    if (name) {
      for (var i = 0; i < name.length; i++) {
        code += name.charAt(i).charCodeAt().toString(16).substring(1, 4);
      }
      if (code.length > 6) {
        code = code.substring(0, 6);
      }
      if (code.length < 6 && code.length > 3) {
        code = code.substring(0, 3);
      }
      if (code.length < 3) {
        code = "5680fa";
      }
      colorStr = `#${code}`;
    }

    backColorStr = `#${code}33`;

    return {
      colorStr,
      backColorStr,
    };
  };

export const subName = (name) => {
    let res = "";
    if (name) {
      res = name.substring(1, 0);
    }
    return res;
  };

  