import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';

import style from './style.less';

function FlowChart({ clickNode }) {
	const [left, setleft] = useState(0);
	const [top, settop] = useState(0);
	// const [isShow, setisShow] = useState(false);
	let canvas = null;

	// 圆角矩形流程框
	const drawRoundRect = (x, y, txt, num) => {
		const WEIGHT = 98;
		const HEIGHT = 33;
		const TOP = y + HEIGHT / 2;
		const LEFT = x + WEIGHT / 2;

		const rect = new fabric.Rect({
			top: TOP,
			left: LEFT,
			width: WEIGHT,
			height: HEIGHT,
			rx: 2,
			ry: 2,
			fill: '#6B9BFB',
			originX: 'center',
			originY: 'center',
		});

		// 文本
		const text = new fabric.Text(txt, {
			top: TOP - 5,
			left: LEFT,
			fontSize: 14,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#fff',
			originX: 'center',
			originY: 'center',
		});

		// 文本
		const textInner = new fabric.Text(num, {
			top: TOP + 8,
			left: LEFT,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#fff',
			originX: 'center',
			originY: 'center',
		});

		const group = new fabric.Group([rect, text, textInner], {
			top: TOP,
			left: LEFT,
			originX: 'center',
			originY: 'center',
		});

		group.selectable = false;
		group.node = {
			name: txt,
			value: 2,
		};
		canvas.add(group);
	};

	const RADIUS = 2.5;
	// 流程线 - 向下
	const drawDownLine = (x, y, hourText, hourText2) => {
		const LENGTH1 = 29;
		const LENGTH2 = 26;

		// 起点圆
		const circle = new fabric.Circle({
			top: y + RADIUS / 2,
			left: x + RADIUS / 2,
			radius: RADIUS,
			fill: '#81AAFB',
			originX: 'center',
			originY: 'center',
		});

		// 线段1
		const line = new fabric.Line(
			[x + RADIUS / 2, y + RADIUS, x + RADIUS / 2, y + RADIUS + LENGTH1],
			{
				stroke: '#81AAFB',
				strokeLineCap: 'round',
				originX: 'center',
				originY: 'top',
			}
		);

		// 文本
		const textInner = new fabric.Text(hourText, {
			top: y + 24,
			left: x + RADIUS,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'center',
		});

		const TEXT2TOP = y + 36;

		// 文本
		const textInner2 = new fabric.Text(hourText2, {
			top: TEXT2TOP,
			left: x - 2,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		const LINE2TOP = TEXT2TOP + 16;

		// 线段2
		const line2 = new fabric.Line(
			[x + RADIUS / 2, LINE2TOP, x + RADIUS / 2, LINE2TOP + LENGTH2],
			{
				stroke: '#81AAFB',
				strokeLineCap: 'round',
				originX: 'center',
				originY: 'top',
			}
		);

		const TRIANGLETOP = LINE2TOP + LENGTH2 + 3;

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: x + 1,
			top: TRIANGLETOP,
			angle: 180,
			originX: 'center',
		});

		const group = new fabric.Group(
			[circle, line, textInner, textInner2, line2, triangle],
			{
				top: y,
				left: x - RADIUS,
				originX: 'left',
				originY: 'top',
			}
		);

		group.selectable = false;
		canvas.add(group);
	};

	// 创建->搁置
	const drawCreate2ShelvedLine = (hourText1, hourText2) => {
		// 起点圆
		const circle = new fabric.Circle({
			top: 136 + RADIUS / 2,
			left: 333 + RADIUS / 2,
			radius: RADIUS,
			fill: '#81AAFB',
			originX: 'center',
			originY: 'center',
		});

		const path = new fabric.Path(
			'M 282 165 L 187 165 M 167 165 L 108 165 L 108 279'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 146,
			left: 190,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 156,
			left: 172,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 108,
			top: 285,
			angle: 180,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([
			circle,
			path,
			textInner,
			textInner2,
			triangle,
		]);

		group.selectable = false;
		canvas.add(group);
	};

	// 搁置->评审
	const drawShelved2ReviewLine = (hourText1, hourText2) => {
		const path = new fabric.Path('M 108 252 L 130 252 M 150 252 L 230 252');
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 232,
			left: 160,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 244,
			left: 137,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 233,
			top: 252,
			angle: 90,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2, triangle]);

		group.selectable = false;
		canvas.add(group);
	};

	// 排期->评审
	const drawSchedule2ReviewLine = (hourText1, hourText2) => {
		const path = new fabric.Path('M 182 252 L 182 297 M 182 317 L182 367');
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 277,
			left: 187,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 300,
			left: 178,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2]);

		group.selectable = false;
		canvas.add(group);
	};

	// 排期->搁置
	const drawSchedule2ShelvedLine = (hourText1, hourText2) => {
		const path = new fabric.Path('M 108 367 L 137 367 M 157 367 L237 367');
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 350,
			left: 159,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 360,
			left: 139,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2]);

		group.selectable = false;
		canvas.add(group);
	};

	// 开发->搁置
	const drawDev2ShelvedLine = (hourText1, hourText2) => {
		const path = new fabric.Path(
			'M 237 482 L 155 482 M 135 482 L108 482 L108 325'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 464,
			left: 159,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 475,
			left: 139,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 108,
			top: 323,
			angle: 0,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2, triangle]);

		group.selectable = false;
		canvas.add(group);
	};

	// 创建->开发
	const drawCreate2DevLine = (hourText1, hourText2) => {
		const path = new fabric.Path(
			'M 334 137 L 394 137  L 394 309 M 394 329 L 394 485 L 334 485'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 290,
			left: 400,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 311,
			left: 390,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2]);

		group.selectable = false;
		canvas.add(group);
	};

	// 评审->放弃
	const drawReview2AbandonLine = (hourText1, hourText2) => {
		const path = new fabric.Path(
			'M 330 251 L 426 251 M 446 251 L 492 251 L 492 286'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 230,
			left: 447,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 243,
			left: 433,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 492,
			top: 288,
			angle: 180,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([path, textInner, textInner2, triangle]);

		group.selectable = false;
		canvas.add(group);
	};

	// 排期->放弃
	const drawSchedule2AbandonLine = (hourText1, hourText2) => {
		// 起点圆
		const circle = new fabric.Circle({
			top: 367 + RADIUS / 2,
			left: 333 + RADIUS / 2,
			radius: RADIUS,
			fill: '#81AAFB',
			originX: 'center',
			originY: 'center',
		});

		const path = new fabric.Path(
			'M 334 368 L 445 368 M 465 368 L 465 368 L 465 368 L 492 368  L 492 325'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 348,
			left: 464,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 360,
			left: 452,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 492,
			top: 324,
			angle: 0,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([
			circle,
			path,
			textInner,
			textInner2,
			triangle,
		]);

		group.selectable = false;
		canvas.add(group);
	};

	// 开发->放弃
	const drawDev2AbandonLine = (hourText1, hourText2) => {
		// 起点圆
		const circle = new fabric.Circle({
			top: 484 + RADIUS / 2,
			left: 333 + RADIUS / 2,
			radius: RADIUS,
			fill: '#81AAFB',
			originX: 'center',
			originY: 'center',
		});

		const path = new fabric.Path(
			'M 334 485 L 445 485 M 465 485 L 465 485 L 465 485 L 492 485  L 492 325'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 468,
			left: 464,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 476,
			left: 452,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		const group = new fabric.Group([circle, path, textInner, textInner2]);

		group.selectable = false;
		canvas.add(group);
	};

	// 评审->开发
	const drawReview2DevLine = (hourText1, hourText2) => {
		// 起点圆
		const circle = new fabric.Circle({
			top: 250 + RADIUS / 2,
			left: 333 + RADIUS / 2,
			radius: RADIUS,
			fill: '#81AAFB',
			originX: 'center',
			originY: 'center',
		});

		const path = new fabric.Path(
			'M 364 251 L 364 317 M 364 337 L 364 477 L 334 477'
		);
		path.set({
			fill: 'transparent',
			stroke: '#81AAFB',
			strokeWidth: 1,
		});

		// 文本
		const textInner = new fabric.Text(hourText1, {
			top: 298,
			left: 370,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#FFC007',
			originX: 'left',
			originY: 'top',
		});

		// 文本2
		const textInner2 = new fabric.Text(hourText2, {
			top: 321,
			left: 360,
			fontSize: 12,
			fontFamily: 'PingFangSC-Regular, PingFang SC',
			fill: '#585858',
			originX: 'left',
			originY: 'top',
		});

		// 尾部三角箭头
		const triangle = new fabric.Triangle({
			width: 8,
			height: 6,
			fill: '#81AAFB',
			left: 330,
			top: 476,
			angle: 270,
			originX: 'center',
			originY: 'top',
		});

		const group = new fabric.Group([
			circle,
			path,
			textInner,
			textInner2,
			triangle,
		]);

		group.selectable = false;
		canvas.add(group);
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		canvas = new fabric.Canvas('canvas', {
			width: 603,
			height: 687,
			selection: false,
			hoverCursor: 'pointer',
		});
		canvas.setBackgroundColor('transparent', canvas.renderAll.bind(canvas));

		drawRoundRect(234, 120, '创建', '226');
		drawDownLine(282, 153, '3h', '7');
		drawRoundRect(234, 237, '评审', '226');
		drawDownLine(282, 270, '13h', '3');
		drawRoundRect(234, 353, '排期', '226');
		drawDownLine(282, 386, '12h', '5');
		drawRoundRect(234, 470, '开发', '226');
		drawDownLine(282, 501, '12h', '5');
		drawRoundRect(234, 584, '已完成', '226');
		drawCreate2ShelvedLine('12h', '7');
		drawRoundRect(60, 290, '搁置', '226');
		drawShelved2ReviewLine('5h', '6');
		drawSchedule2ReviewLine('5h', '8');
		drawSchedule2ShelvedLine('4h', '6');
		drawDev2ShelvedLine('3h', '6');
		drawCreate2DevLine('5h', '3');
		drawRoundRect(446, 290, '放弃', '15');
		drawReview2AbandonLine('8.2h', '3');
		drawSchedule2AbandonLine('7h', '3');
		drawDev2AbandonLine('6h', '5');
		drawReview2DevLine('3h', '5');

		canvas.on('mouse:wheel', function (opt) {
			let delta = opt.e.deltaY;
			let zoom = canvas.getZoom();
			zoom *= 0.999 ** delta;
			if (zoom > 5) zoom = 5;
			if (zoom < 0.5) zoom = 0.5;
			canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
			opt.e.preventDefault();
			opt.e.stopPropagation();
		});
		canvas.on('mouse:down', (opt) => {
			// 鼠标按下时触发
			let evt = opt.e;
			canvas.isDragging = true;
			canvas.lastPosX = evt.clientX;
			canvas.lastPosY = evt.clientY;
		});

		canvas.on('mouse:move', (opt) => {
			// 鼠标移动时触发
			if (canvas.isDragging) {
				let evt = opt.e;
				let vpt = canvas.viewportTransform; // 聚焦视图的转换
				vpt[4] += evt.clientX - canvas.lastPosX;
				vpt[5] += evt.clientY - canvas.lastPosY;
				canvas.requestRenderAll(); // 重新渲染
				canvas.lastPosX = evt.clientX;
				canvas.lastPosY = evt.clientY;
			}
		});

		canvas.on('mouse:up', () => {
			// 鼠标松开时触发
			canvas.setViewportTransform(canvas.viewportTransform); // 设置此画布实例的视口转换
			canvas.isDragging = false; // 关闭移动状态
		});

		canvas.on('mouse:down', (opt) => {
			const nodeInfo = opt.target ? opt.target.node : '';

			if (nodeInfo) {
				clickNode(nodeInfo);
			}
		});

		canvas.on('mouse:over', (opt) => {
			const nodeInfo = opt.target ? opt.target.node : '';
			setleft(-1000);
			settop(-1000);
			console.log(opt, 'opt');
			if (nodeInfo) {
				const currentLeft = opt.target.left;
				const currentTop = opt.target.top;
				// const zoomX = opt.target.zoomX;
				// const zoomY = opt.target.zoomY;

				setleft(currentLeft);
				settop(currentTop);
				settop(opt.target.top);
			}
		});
	}, []);

	return (
		<div className={style.flowWapper}>
			<div className={style.tip} style={{ left: left + 20, top: top + 10 }}>
				点击可查看详情
			</div>
			<canvas id='canvas' width='100%' height='100%'></canvas>
		</div>
	);
}

const lineOption = {
	color: '#7196FE',
	tooltip: {
		show: true,
		trigger: 'axis',
	},
	grid: {
		top: '8%',
		left: '2%',
		right: '2%',
		bottom: '2%',
		containLabel: true,
	},
	xAxis: {
		type: 'category',
		data: ['2022.3', '2022.4', '2022.5', '2022.6', '2022.7'],
		axisTick: {
			alignWithLabel: true,
		},
	},
	yAxis: {
		type: 'value',
	},
	series: [
		{
			data: [150, 230, 224, 218, 135, 147, 260],
			type: 'line',
		},
	],
};

const barOption = {
	color: '#7196FE',
	tooltip: {
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	grid: {
		top: '8%',
		left: '2%',
		right: '2%',
		bottom: '2%',
		containLabel: true,
	},
	xAxis: [
		{
			type: 'category',
			data: ['2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9'],
			axisTick: {
				alignWithLabel: true,
				show: false,
			},
		},
	],
	yAxis: [
		{
			type: 'value',
		},
	],
	series: [
		{
			name: 'Direct',
			type: 'bar',
			barWidth: '60%',
			data: [10, 52, 200, 334, 390, 330, 220],
		},
	],
};

const barRowOption = {
	color: '#7196FE',
	legend: {
		show: false,
	},
	tooltip: {
		trigger: 'axis',
		axisPointer: {
			type: 'shadow',
		},
	},
	grid: {
		top: '3%',
		left: '9%',
		right: '4%',
		bottom: '3%',
		containLabel: true,
	},

	xAxis: {
		type: 'value',
		splitLine: {
			show: false,
		},
	},
	yAxis: {
		type: 'category',
		axisLabel: {
			show: false,
			color: '#9DA4B1',
		},
		axisTick: {
			show: false,
		},

		data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World'],
	},
	series: [
		{
			name: '2012',
			type: 'bar',
			data: [19325, 23438, 31000, 121594, 134141, 681807],
		},
	],
};

export {
	FlowChart,
	lineOption,
	barOption,
	barRowOption,
};
