/**
 * TODO
 * 业务流程缺失，暂时手动设置ip和请求参数，部署时注意修改
 * mainUrl: 直连BI系统地址
 * reportParmas: 分享链接参数
 */

// module.exports = {
// 	//    测试环境域名
// 	mainUrl: 'https://demo.farcloud.com',
// 	reportParmas: '/report/r_Fnl2UzyVae1jasAagvT/d4f5ca5083b5cb10fa38ff3a01ca1bb9',

// 	//    生产环境ip端口
// 	mainUrl: 'http://172.16.143.231:8082',
// 	reportParmas: '/report/r_Fnl2UzyVae1jasAagvT/d4f5ca5083b5cb10fa38ff3a01ca1bb9',

// 	//    生产环境域名
// 	mainUrl: 'https://bi.farcloud.com',
// 	reportParmas: '/report/r_Fnl2UzyVae1jasAagvT/d4f5ca5083b5cb10fa38ff3a01ca1bb9'

// 	//    国泰君安
// 	mainUrl: 'http://10.187.225.0:8082',
// 	reportParmas: '/report/r_2hJA1HYVg4Sdri0II92/4fa183b61f636d2ac621dfd9d7c058d7'

// 	//    天道金科
// 	mainUrl: 'http://172.16.11.97:8082',
// 	reportParmas: '/report/r_4xZokoqmX5rU6t53enL/5df337a4d8852b86b7876fa9cc90cfe8'
// };
// }

export const avaterConfig = [
  {
    //    测试环境域名
    mainUrl: 'https://demo.farcloud.com',
    reportParmas: '/report/r_Fnl2UzyVae1jasAagvT/d4f5ca5083b5cb10fa38ff3a01ca1bb9',
  },
  {
    //    生产环境域名
    mainUrl: "http://172.16.143.231:8082",
    reportParmas:
      "/report/r_Fnl2UzyVae1jasAagvT/d4f5ca5083b5cb10fa38ff3a01ca1bb9",
    orgId: "vTxmny1iMkP9D4RMeQd",
  },
  {
    mainUrl: "http://172.16.143.231:8082",
    reportParmas:
      "/report/r_2bzd2Hc4Qq4YpZreswh/213b7cd27cc3d53eb2638f3d9b43e4bb",
    orgId: "nCzxSvotut1cyKUUMMO",
  },
];
