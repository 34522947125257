import React, { useState, useEffect } from "react";
import config from "src/commons/config-hoc";
import { Form, DatePicker, message, Spin, Empty } from "antd";
import { PageContent } from "@ra-lib/admin";
import LineChart from "../../components/line-chart";
import { handleData ,getParamsDate} from "./utils";
import { formatChartViewData } from "../../commons/chart-view-templete-new";
import { UniveralEcharts } from "../../components";
import TargetDetail from "./TargetDetail";
import moment from "moment";
import style from "./style.less";

export default config({
  path: "/front/cockpit",
  title: "驾驶舱",
})(function Cockpit(props) {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [detailVisible, setdetailVisible] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [targetCondition, settargetCondition] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [date, setdate] = useState(moment().startOf("M"));
  const [titleProps, setTitleProps] = useState("");
  const [selDate, setselDate] = useState(getParamsDate());
  const [tabSel, settabSel] = useState("");
  const disabledDate = (current) => {
    //  设置当前日期之后的不能选
    return current && current > moment().endOf("day");
  };
  const getData = (blockId) => {
    if(blockId){
      setContentLoading(true);
      return new Promise((reolve, reject) => {
        props.ajax
          .get("/cockpit/block", {
            endTime: selDate,
            blockId,
          })
          .then((res) => {
            if (res?.code === 0) {
              const chartList = handleData(res?.data);
              setDataSource(formatChartViewData(chartList));
              setContentLoading(false);
              reolve();
            } else {
              setDataSource([]);
              message.error(res?.message);
              setContentLoading(false);
              reject();
            }
          })
          .catch(() => {
            setDataSource([]);
            setContentLoading(false);
            reject();
          });
      });
    }
    
  };
  // 获取tab列表
  const getTabData = async () => {
    try {
      setTabLoading(true);
      const res = await props.ajax.get("/cockpit/blockNav", {});
      if (res?.code === 0) {
        setTabLoading(false);
        settabSel(res?.data[0]?.value);
        getData(res?.data[0]?.value);
        setTabList(res?.data || []);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      throw error;
    }
  };
  const getLineData = async () => {
    try {
      setLoading(true);
      const res = await props.ajax.get("/cockpit/top", { endTime: selDate });
      if (res?.code === 0) {
        setLoading(false);
        setLineChartData(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      throw error;
    }
  };
  const onChangeDate = (value) => {
    if (!value) return;
    const currentMonth = moment().month();
    const month = moment(value).month();
    if (currentMonth !== month) {
      setselDate(moment(value).endOf("M").format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setselDate(getParamsDate());
    }
    setdate(value);
  };
  const getDrillData = (params) => {
    setdetailVisible(true);
    const {
      data: { blockIndexId, nodeId, down },
    } = params;
    // 可以下钻
    if (down) {
      return new Promise((reolve, reject) => {
        props.ajax
          .get("/cockpit/block", {
            endTime: selDate,
            blockId: tabSel,
            blockIndexId,
            nodeId,
          })
          .then((res) => {
            if (res?.code === 0) {
              const chartList = handleData(res?.data);
              settargetCondition(formatChartViewData(chartList));
              reolve();
            } else {
              message.error(res?.message);
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      });
    }
  };
  const handleDrill = (params, title) => {
    setTitleProps({ data: params, title });
    getDrillData(params);
  };
  // 渲染空数据
  const renderEmpty = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Empty style={{ width: "100%" }} />
      </div>
    );
  };
  useEffect(() => {
    getLineData();
    getTabData();
  }, [selDate]);
  return (
    <PageContent fitHeight>
      <div className={style.cockpit}>
        <Form.Item label="时间">
          <DatePicker
            onChange={onChangeDate}
            disabledDate={disabledDate}
            picker="month"
            value={date}
          />
        </Form.Item>
        {lineChartData.length?<Spin spinning={loading}>
          <div className={style.chartsContent}>
            {lineChartData.map((item, index) => {
              return (
                <div className={style.line} key={item?.chartName}>
                  <LineChart item={item}></LineChart>
                </div>
              );
            })}
          </div>
        </Spin>:renderEmpty()}
        <Spin spinning={tabLoading}>
          <div className={style.tabContent}>
            {tabList.map((item, index) => (
              <div
                className={`${style.wTabItem} ${
                  tabSel === item?.value ? style.tabActive : ""
                }`}
                onClick={() => {
                  settabSel(item?.value);
                  getData(item?.value);
                }}
                key={index}
              >
                {item?.label}
              </div>
            ))}
          </div>
        </Spin>
        <Spin spinning={contentLoading}>
          {dataSource.length === 0 ? (
            <div className={style.emptyWrap}>{ renderEmpty()}</div>
          ) : (
            <div className={style.chartListWrap}>
              {dataSource.map((item, index) => (
                <div className={style.chartItem} key={index}>
                  <div className={style.chartTitle}>
                    <p
                      className={style.name}
                      onClick={() => {
                        // setdetailVisible(true);
                        // settargetCondition(item);
                      }}
                    >
                      {item?.title}
                    </p>
                  </div>
                  <div className={style.chartContent}>
                    {item?.option ? (
                      <UniveralEcharts
                        clickEvent={(params) => handleDrill(params, item.title)}
                        style={{ height: "90%", width: "100%" }}
                        option={item?.option}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Spin>
        <TargetDetail
          visible={detailVisible}
          date={selDate}
          tabSel={tabSel}
          targetCondition={targetCondition}
          titleProps={titleProps}
          onCancel={() => setdetailVisible(false)}
          {...props}
        />
      </div>
    </PageContent>
  );
});
