
        export default [{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/achievement-detail/component/ListDetail/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/achievement-detail/constant.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/achievement-detail/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/achievement-detail/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/achievement-detail",
  title: "绩效方案数据分析",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/cockpit/TargetDetail.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/cockpit/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/cockpit/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/cockpit",
  title: "驾驶舱",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/cockpit/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/employees-improvement/graph.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/employees-improvement/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/employees-improvement/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/employees-improvement",
  title: "员工持续改进",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/employees-improvement/newGraph.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/employees-improvement/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/empty/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/empty/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/empty",
  title: "暂无数据",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/empty-login/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/empty-login/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/empty-login",
  auth: false,
  layout: false,
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/error-login/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/error-login/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/error-login",
  auth: false,
  layout: false,
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/home/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/home/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
    path: "/",
    title: "首页",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/login/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/login/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/login",
  auth: false,
  layout: false,
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/page-configs.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/pm/FlowChart.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/pm/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/pm/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
    path: "/front/pm",
    title: "流程挖掘",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/profile",
  title: "画像",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile/url-config.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/barAndLineItem.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/constant.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
	path: '/front/profile-detail',
	title: '图表详情',
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/pieItem.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/profile-detail/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankAchi.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankAchi.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/rank-achi",
  title: "绩效榜",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankAchiNew.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankAchiNew.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/achievement",
  title: "绩效榜",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankGroup.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankGroup.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
	path: '/front/rank-group',
	title: '分组榜',
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/RankInner.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/component/RankListDetail/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/component/RankListDetail/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/component/RankTable/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/component/RankingList/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/components/RankListDetail/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/components/RankListDetail/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/components/RankModal/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/components/RankTable/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/components/RankingList/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/constant.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/rank",
  title: "排行榜",
  keepAlive: true,
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/utils/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank-achi-detail/index.js',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/rank-achi-detail/index.js'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/rank-achi-detail",
  title: "员工画像",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/components/chartItem/index.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/components/chartItem/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/constant.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: '/front',
  title: '画像',
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/test.jsx',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/url-config.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/report/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail/TargetInfo.jsx',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: "/front/target-detail",
  title: "指标数据详情",
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail-outside/TargetInfo.jsx',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail-outside/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/target-detail-outside/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
	path: '/front/td/:orgId/:echoJson',
	title: '指标数据详情',
	side: false,
	tab: false,
	auth: false,
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/work-file/constant.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        },{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/work-file/index.jsx',
            component: ()=> import('/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/work-file/index.jsx'),
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        
  path: '/front/work-file',
  title: '工作档案',
},{
            filePath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages/work-file/utils.js',
            
            pagesPath: '/Users/fangyp/Desktop/vbillgit/farcloud-front-achi/src/pages',
        }]
    