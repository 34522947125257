/**
 *  图表模板-新版报告使用
    1、line（折线图，多条数据可设置多坐标轴）；
	2、barXAxis（单坐标轴条形图）；
	3、barYAxis（双坐标轴柱状图）
	4、barMix（条形图组合，正负轴，交付质量和交付效率）；
	5、ranks（综合排名）；
	6、pie（饼图，圆环）；
	7、gauge（仪表盘）；
	8、reportScore（综合得分，报告中）；
	9、radar（雷达图，综合能力）；
	10、barAndLineMix（柱状图和折线图组合，可设置多坐标轴）；
	11、lineMix（首页产出总数、资源点数和工作量组合）；
 */

import moment from 'moment';

/**
 * 公共颜色池
 */
const globalColor = [
	'#F4BB15',
	'#28BF7E',
	'#7196FE',
	'#3BC27F',
	'#8978FF',
	'#6fecf6',
	'#8f61c0',
	'#ED7C2F',
	'#e55858',
	'#4A5BDC',
	'#7E79FF',
	'#B0D14A',
	'#67E0E3',
	'#4CD698',
	'#3984B6',
	'#B5BFD9',
	'#BCE684',
];

//  符合年月YYYY-MM的正则
const regexp = /^[1-2]\d{3}-((0[1-9])|(1[0-2]))$/;

/**
 * 组装legend数据
 */
const chartLegend = data => {
	let legendData = [];

	// 组装类目数据
	for (let i = 0; i < data.viewData.length; i++) {
		legendData.push(data.viewData[i].name);
	}

	return legendData;
};

/**
 * line（折线图）
 */
const chartLine = (flag, data) => {
	let extremumObj = {};

	if (data.yAxis !== 'false') {
		extremumObj = {
			max: data.maxValue ? data.maxValue : 0,
			min: data.minValue ? data.minValue : 0,
		};
	}

	let option = {
		animation: true,
		color: globalColor,
		tooltip: {
			trigger: 'axis',
			triggerOn: 'click',
			axisPointer: {
				axis: 'x',
				snap: true,
			},
			position: function (point, params, dom, rect, size) {
				const axisValue = params[0].axisValue;
				dom.style.text = `${axisValue}\n${dom.style.text}`;
			},
			show: true,
			confine: true,
			backgroundColor: 'rgba(0,0,0,0.8)',
			extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.87);',
			textStyle: {
				color: '#fff',
			},
			borderWidth: 0,
		},
		grid: {
			top: 20,
			left: '3%',
			right: '10%',
			bottom: '3%',
			containLabel: true,
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'category',
				axisLabel: {
					show: data.xAxis === 'false' ? false : true,
					color: '#8A94A6',
					fontSize: 12,
					interval: 0,
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: '#9BA1A8',
					},
				},
				boundaryGap: false,
				data: regexp.test(data.axisNames[0])
					? data.axisNames.map(item => moment(item).format('YY-MM'))
					: data.axisNames,
			},
		],
		yAxis: [
			{
				type: 'value',
				...extremumObj,
				axisLabel: {
					show: data.yAxis === 'false' ? false : true,
					color: '#8A94A6',
					fontSize: 12,
					formatter: function (value) {
						if (value.length > 7) {
							return value.substring(0, 7) + '...';
						} else {
							return value;
						}
					},
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: '#9BA1A8',
					},
				},
				splitLine: {
					show: true,
					lineStyle: {
						color: '#E0E6F1',
					},
				},
				min: function (value) {
					if (value.min < 0) {
						return value.min;
					} else {
						return 0;
					}
				},
			},
		],
	}; 
	// 图表tooltip弹出层提示信息设置
	// if (flag === 'TARGETDETAIL' || flag === 'FIGHT') {
	//     option.tooltip = {
	//         trigger: "axis",
	//         triggerOn: "click",
	//         axisPointer: {
	//             axis: "x",
	//             snap: true
	//         },
	//         position: function (point, params, dom, rect, size) {
	//             const axisValue = params[0].axisValue
	//             dom.style.text = `${axisValue}\n${dom.style.text}`
	//         },
	//         show: true,
	//         confine: true,
	//     }
	// }
	// 图表legend设置,战斗力中的折线图展示legend

	if (data.viewData.length > 1) {
		option.legend = {
			data: chartLegend(data),
			textStyle: {
				color: '#8A94A6',
			},
			type: 'scroll',
		};
		option.grid = {
			top: 30,
			left: '3%',
			right: '6%',
			bottom: '3%',
			containLabel: true,
		};
	} else {
		option.legend = {
			left: -10000,
			data: chartLegend(data),
		};
	}

	// series数据组装
	for (let i = 0; i < data.viewData.length; i++) {
		// 首页和报告详情，silent为true，去掉事件
		if (flag === 'HOME' || flag === 'REPORT') {
			data.viewData[i].silent = false;
		} // 设置折线图为平滑过渡效果

		data.viewData[i].smooth = true;
		data.viewData[i].type = 'line'; // 判断是否有平均线,如果有平均线给平均线设置固定颜色

		if (data.viewData[i].average) {
			data.viewData[i].lineStyle = {
				color: '#B6C2FF',
			};
			data.viewData[i].itemStyle = {
				color: '#B6C2FF',
			};
		}
	}
	// 设置markLine中位线

	if (data.markLine && data.markLine.length) {
		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];
			let markColor = ['#3BC27F', '#F56E53', '#7196FE'];
			for (let j = 0; j < data.markLine[i].length; j++) {
				//  平均线
				if (data.markLine[i][j].type === '01') {
					data.markLine[i][j].color = '';
				}

				markLineData.push({
					yAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].name
							? data.markLine[i][j].name
							: '{c}',
						position: 'middle',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: markColor[j],
					},
				});
			} //  目标值

			if (data.markLine[i].length > 2) {
				data.markLine[i][2].color = markColor[2];
				data.markLine[i][1].color = markColor[1];
			} else if (data.markLine[i].length === 2) {
				if (data.markLine[i][0].type === '01') {
					data.markLine[i][1].color = markColor[1];
					data.markLine[i][0].color = markColor[0];
				} else {
					data.markLine[i][1].color = markColor[2];
					data.markLine[i][0].color = markColor[1];
				}
			} else {
				if (data.markLine[i][0].type === '01') {
					data.markLine[i][0].color = markColor[0];
				} else {
					data.markLine[i][0].color = markColor[0];
				}
			} // 设置markLine中位线

			data.viewData[i].markLine = {
				silent: true,
				data: markLineData,
			};
		}
	} // 设置grid

	if (data.type === 'lineMix') {
		option.grid = {
			top: 8,
			left: 8,
			right: 8,
			bottom: 8,
			containLabel: true,
		};
		option.tooltip.show = false;
	}

	option.series = flag === 'INDI' ? data.lineMixData : data.viewData; // console.log(JSON.stringify(option), '折线图')
	return option;
};

/**
 * barXAxis（条形图）
 */
const chartbarXAxis = (data, otherOptions) => {
	let maxObj = {
		max: data.maxValue ? Number(data.maxValue) + 1 : 0,
	};

	let option = {
		animation: true,
		color: globalColor,
		grid: {
			top: '4%',
			left: '3%',
			right: '4%',
			bottom: 0,
			containLabel: true,
		},
		legend: {
			left: -10000,
			data: chartLegend(data),
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'value',
				axisLabel: {
					show: false,
					color: '#8A94A6',
					fontSize: 12,
				},
				...maxObj,
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				type: 'category',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 11,
					formatter: function (value) {
						if (value.length > 8) {
							return (
								value.substring(0, value.length / 2 + 1) +
								'\n' +
								value.substring(value.length / 2 + 1, value.length)
							);
						} else {
							return value;
						}
					},
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				data: data.axisNames,
				inverse: otherOptions.inverse || false,
			},
		],
	};

	// series数据组装
	for (let i = 0; i < data.viewData.length; i++) {
		// 设置柱条宽度
		data.viewData[i].showBackground = false;

		data.viewData[i].barMinHeight = 8;
		data.viewData[i].barMaxWidth = 25;

		data.viewData[i].label = {
			show: true,
			color: '#8A94A6',
			position: 'right',
			fontSize: 14,
			formatter: function (e) {
				return e.data.valueName;
			},
		};

		data.viewData[i].itemStyle = {
			borderRadius: [0, 2, 2, 0],
		};

		if (data.markLine && data.markLine.length) {
			if (data.markLine[0].length > 1) {
				data.viewData[i].itemStyle = {
					...data.viewData[i].itemStyle,
					color: params => {
						let markLine = data.markLine;
						let lowData;
						let heightData;
						markLine.forEach(item => {
							item.forEach((ele, idx) => {
								if (idx === 0) {
									lowData = Number(ele.value);
								}
								if (idx === 1) {
									heightData = Number(ele.value);
								}
							});
						});

						if (params.value > heightData) {
							return '#EF6667';
						}
						if (params.value > lowData && params.value <= heightData) {
							return '#5671C5';
						}
						if (params.value < lowData) {
							return '#93CC74';
						}
						return '#5671C5';
					},
				};
			}
		}
	}

	// 设置markLine中位线
	if (data.markLine && data.markLine.length) {
		// 如果有中位线重新设置grid
		option.grid = {
			top: '6%',
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		};

		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];
			let markColor = ['#93CC74', '#EF6667'];

			for (let j = 0; j < data.markLine[i].length; j++) {
				markLineData.push({
					xAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].name
							? data.markLine[i][j].name
							: '{c}',
						position: 'end',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: markColor[j],
					},
				});
			}

			data.viewData[i].markLine = {
				data: markLineData,
				silent: true,
			};
		}
	}

	option.series = data.viewData;

	if (option.yAxis[0].data.length > 11) {
		option.grid.top = '2%';
		option.chartHeight = option.yAxis[0].data.length * 40;
	}

	return option;
};

/**
 * barYAxis（双坐标轴柱状图）
 */

const chartbarYAxis = (flag, data) => {
	let option = {
		animation: true,
		color: globalColor,
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		},
		legend: {
			textStyle: {
				color: '#8A94A6',
			},
			data: chartLegend(data),
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'category',
				axisLabel: {
					show: true,
					color: '#8A94A6',
					fontSize: 12,
				},
				axisTick: {
					show: true,
				},
				axisLine: {
					lineStyle: {
						color: '#8A94A6',
					},
				},
				data: data.axisNames,
			},
		],
		yAxis: [
			{
				type: 'value',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 12,
				},
				axisLine: {
					lineStyle: {
						color: '#8A94A6',
					},
				},
				splitLine: {
					show: false,
				},
			},
			{
				type: 'value',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 12,
				},
				axisLine: {
					lineStyle: {
						color: '#8A94A6',
					},
				},
				splitLine: {
					show: false,
				},
			},
		],
	};
	// 图表tooltip弹出层提示信息设置

	// if (flag === 'TARGETDETAIL' || flag === 'FIGHT') {
	option.tooltip = {
		trigger: 'axis',
		triggerOn: 'click',
		axisPointer: {
			axis: 'x',
			snap: true,
		},
		confine: true,
		position: function (point, params, dom, rect, size) {
			const axisValue = params[0].axisValue;
			dom.style.text = `${axisValue}\n${dom.style.text}`;
		},
	};
	// } // series数据组装

	for (let i = 0; i < data.viewData.length; i++) {
		data.viewData[i].yAxisIndex = i; // 设置不同系柱子间距离

		data.viewData[i].barGap = 0;
		data.viewData[i].itemStyle = {
			borderRadius: [0, 2, 2, 0],
		};
	}
	// 设置markLine中位线

	if (data.markLine && data.markLine.length) {
		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];

			for (let j = 0; j < data.markLine[i].length; j++) {
				markLineData.push({
					yAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].name
							? data.markLine[i][j].name
							: '{c}',
						position: 'middle',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: globalColor[j],
					},
				});
			}

			data.viewData[i].markLine = {
				data: markLineData,
				silent: true,
			};
		}
	}

	option.series = data.viewData;
	return option;
};
/**
 * barMix（条形图组合，正负坐标轴）
 */
const chartbarMix = (flag, data, otherOptions) => {
	let option = {
		animation: false,
		color: globalColor,
		grid: {
			top: '5%',
			left: '3%',
			right: '3%',
			bottom: '3%',
			containLabel: true,
		},
		legend: {
			textStyle: {
				color: '#8D96A3',
			},
			data: chartLegend(data),
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'value',
				axisLabel: {
					show: false,
					color: '#8A94A6',
					fontSize: 12,
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				type: 'category',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 16,
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				data: data.axisNames,
				inverse: otherOptions.inverse || false,
			},
		],
	};

	// series数据组装
	for (let i = 0; i < data.viewData.length; i++) {
		// 首页和报告详情，silent为true，去掉事件
		if (flag === 'HOME' || flag === 'REPORT') {
			data.viewData[i].silent = true;
		}

		// 设置柱条上数值样式
		data.viewData[i].label = {
			show: true,
			color: '#8A94A6',
			position: 'inside',
			fontSize: 14,
			formatter: params => {
				return Math.abs(params.value);
			},
		};

		// 设置数据堆叠
		data.viewData[i].stack = '总量';
		data.viewData[i].itemStyle = {
			borderRadius: [0, 2, 2, 0],
		};
	}

	// 设置markLine中位线
	if (data.markLine && data.markLine.length) {
		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];

			for (let j = 0; j < data.markLine[i].length; j++) {
				markLineData.push({
					xAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].name
							? data.markLine[i][j].name
							: params => {
									return Math.abs(params.value);
							  },
						position: 'end',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: globalColor[j],
					},
				});
			}

			data.viewData[i].markLine = {
				data: markLineData,
				silent: true,
			};
		}

		// 重新设置grid
		option.grid = {
			left: '3%',
			right: '3%',
			bottom: '3%',
			containLabel: true,
		};
	}

	option.series = data.viewData;

	if (option.yAxis[0].data.length > 10) {
		option.grid.top = '1%';
		option.chartHeight = option.yAxis[0].data.length * 40;
	}

	return option;
};

/**
 * pie（饼图，圆环）
 */
const chartPieDefaultLegend = data => {
	let legend = [];

	for (let i = 0; i < data.viewData[0].data.length; i++) {
		legend.push(data.viewData[0].data[i].name);
	}

	return legend;
};

const chartPieDefault = data => {
	let option = {
		animation: false,
		color: globalColor,
		grid: {
			left: '3%',
			right: '3%',
			containLabel: true,
		},
		title: {
			show: true,
			text: '1',
			textStyle: {
				fontSize: 0,
				width: 0,
				height: 0,
				lineHeight: 0,
				color: '#8A94A6',
			},
		},
		legend: {
			textStyle: {
				color: '#8A94A6',
			},
			data: chartPieDefaultLegend(data),
			left: -10000,
		},
	};

	// series数据组装
	data.viewData[0].label = {
		// formatter: '{d}%({c}个)\n{b}',
		formatter: params => {
			return `${params.percent}%(${params.value})\n${params.name.slice(
				0,
				6
			)}\n${params.name.slice(6)}`;
		},
		backgroundColor: 'transparent',
	};
	data.viewData[0].radius = ['0', '55%'];

	option.series = data.viewData;

	option.series = data.viewData[0];

	return option;
};

/**
 * pie（饼图，圆环）
 */
const chartPieLegend = data => {
	let legend = [];

	for (let i = 0; i < data.viewData[0].data.length; i++) {
		legend.push(data.viewData[0].data[i].name);
	}

	return legend;
};

const chartPie = (flag, data) => {
	let option = {
		animation: true,
		color: globalColor,
		clockwise: false,
		grid: {
			left: '3%',
			right: '3%',
			containLabel: true,
		},
		title: {
			show: true,
			text: '1',
			textStyle: {
				fontSize: 0,
				width: 0,
				height: 0,
				lineHeight: 0,
				color: '#fff',
			},
		},
		legend: {
			show: false,
			orient: 'vertical',
			right: 15,
			top: 'middle',
			itemWidth: 8,
			itemHeight: 8,
			textStyle: {
				color: '#fff',
			},
			borderRadius: 40,
			data: chartPieLegend(data),
		},
		tooltip: {
			trigger: 'item',
			confine: true,
			backgroundColor: 'rgba(0,0,0,0.8)',
			extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.87);',
			textStyle: {
				color: '#fff',
			},
			borderWidth: 0,
		},
	};

	// series数据组装
	data.viewMoreData[0].label = {
		formatter: '{d}%\n{b}',
		backgroundColor: 'transparent',
		textStyle: {
			lineHeight: 20,
			fontSize: 13,
		},
	};

	const resData = data.viewMoreData[0].data;

	resData.sort((a, b) => a.name.charCodeAt() - b.name.charCodeAt());

	data.viewMoreData[0].data = resData.reverse();
	data.viewMoreData[0].minAngle = 15;
	data.viewMoreData[0].avoidLabelOverlap = true;
	data.viewMoreData[0].radius = ['0', '68%'];
	data.viewMoreData[0].center = ['50%', '50%'];
	data.viewMoreData[1] = {
		type: 'pie',
		radius: '68%',
		center: ['50%', '50%'],

		label: {
			normal: {
				show: false,
				position: 'center',
			},
			emphasis: {
				show: true,
				textStyle: {
					color: '#fff',
					fontSize: '30',
					fontWeight: 'bold',
				},
			},
		},
		labelLine: {
			normal: {
				show: false,
			},
		},
		z: 1,
		silent: true,
	};

	option.series = data.viewMoreData;

	return option;
};

/**
 * gauge（仪表盘）
 */
const chartGauge = (flag, data) => {
	const gaugeColor = globalColor[0];
	let option = {
		animation: false,
		series: [
			{
				name: 'PK指数',
				type: 'gauge',
				radius: '100%',
				silent: true,
				detail: {
					formatter: '{value}%',
					offsetCenter: [0, '-65%'],
					fontFamily: 'Aril',
					fontWeight: 'bold',
					fontSize: 14,
				},
				splitNumber: 1,
				title: {
					offsetCenter: [0, '30%'],
					textStyle: {
						fontWeight: 'bolder',
						fontSize: 14,
						color: gaugeColor,
						shadowColor: 'rgba(0, 255, 255, 0.5)',
						shadowBlur: 1,
					},
				},
				data: [
					{
						value: data.viewData[0].data[0].value,
						name: '打败了' + data.viewData[0].data[0].value + '%的研发团队',
					},
				],
				axisLine: {
					lineStyle: {
						color: [
							[0, gaugeColor],
							[1, gaugeColor],
						],
						width: 2,
						shadowColor: 'rgba(0, 255, 255, 0.5)',
						shadowBlur: 0,
					},
				},
				splitLine: {
					show: false,
					length: 0,
				},
				axisTick: {
					show: false,
					length: 0,
				},
				axisLabel: {
					distance: 18,
				},
				pointer: {
					show: false,
				},
			},
			{
				type: 'gauge',
				radius: '95%',
				silent: true,
				axisLine: {
					show: false,
					lineStyle: {
						width: 0,
						opacity: 0,
					},
				},
				splitNumber: 50,
				splitLine: {
					show: true,
					length: 2,
					lineStyle: {
						color: gaugeColor,
						width: 3,
						shadowColor: 'rgba(0, 255, 255, 0.5)',
						shadowBlur: 10,
					},
				},
				axisTick: {
					show: false,
					length: 0,
				},
				axisLabel: {
					show: false,
				},
				pointer: {
					show: false,
				},
				detail: {
					show: false,
				},
			},
			{
				type: 'gauge',
				radius: '40%',
				startAngle: 180,
				endAngle: 0,
				silent: true,
				title: {
					show: false,
				},
				data: [
					{
						value: data.viewData[0].data[0].value,
					},
				],
				axisLine: {
					show: false,
					lineStyle: {
						color: [
							[0, gaugeColor],
							[1, gaugeColor],
						],
						width: 4,
						shadowColor: 'rgba(0, 255, 255, 0.5)',
						shadowBlur: 10,
						opacity: 0,
					},
				},
				splitNumber: 50,
				splitLine: {
					show: true,
					length: 4,
					lineStyle: {
						color: gaugeColor,
						width: 1,
						shadowColor: 'rgba(0, 255, 255, 0.5)',
						shadowBlur: 10,
					},
				},
				axisTick: {
					show: false,
					length: 0,
				},
				axisLabel: {
					show: false,
				},
				pointer: {
					show: true,
					length: '80%',
					width: 5,
				},
				detail: {
					show: false,
				},
			},
		],
	};

	return option;
};

/**
 * radar（雷达图）
 */

const chartRadarLegend = data => {
	let legend = [];

	for (let i = 0; i < data.viewData.length; i++) {
		legend.push(data.viewData[i].name);
	}

	return legend;
};

const chartRadar = (data, flag) => {
	let option = {};
	option = {
		animation: false,
		tooltip: {
			trigger: 'item',
			triggerOn: 'click',
			axisPointer: {
				axis: 'x',
				snap: true,
			},
			confine: true,
		},
		legend: {
			data: chartRadarLegend(data),
			left: -10000,
		},
		textStyle: {
			fontSize: 11,
		},
		radar: [
			{
				center: ['50%', '50%'],
				radius: '75%',
				name: {
					fontSize: 12,
				},
			},
		],
		series: [
			{
				name: '综合能力',
				type: 'radar',
				silent: false,
			},
		],
	};

	// 图表tooltip弹出层提示信息设置
	// if (flag === 'TARGETDETAIL' || flag === 'FIGHT') {
	option.tooltip = {
		trigger: 'item',
		triggerOn: 'click',
		axisPointer: {
			axis: 'x',
			snap: true,
		},
		confine: true,
	};
	// }

	if (flag === 'PK') {
		option.animation = true;
		option.legend = {
			data: chartRadarLegend(data),
			top: 0,
		};
	} // 雷达图维度组装

	let radarArr = [];

	for (let i = 0; i < data.indicator.length; i++) {
		radarArr.push({
			text: data.indicator[i].text,
			max: data.indicator[i].max,
			color: '#fff',
		});
	}

	option.radar[0].indicator = radarArr; // 雷达图series组装

	let seriesArr = [];

	for (let i = 0; i < data.viewData.length; i++) {
		let style = {
			areaStyle: {
				normal: {
					opacity: 0.9,
					color: 'rgba(46, 199, 200, 0.3)',
				},
			},
			lineStyle: {
				color: '#2EC7C8',
			},
			itemStyle: {
				color: '#2EC7C8',
			},
		};

		if (data.viewData.length > 1 && i === 0) {
			style = {
				lineStyle: {
					type: 'dashed',
					color: '#FF0000',
				},
				itemStyle: {
					color: '#FF0000',
					opacity: 0,
				},
			};
		}

		seriesArr.push({
			value: data.viewData[i].data
				? data.viewData[i].data
				: data.viewData[i].radarData,
			name: data.viewData[i].name,
			...style,
		});
	}

	option.series[0].data = seriesArr;
	return option;
};

/**
 * barAndLineMix（柱状图和折线图组合，可设置双坐标轴）
 */

const chartbarAndLineMix = (flag, data) => {
	let option = {
		animation: true,
		color: globalColor,
		grid: {
			top: '15%',
			left: '3%',
			right: '4%',
			bottom: 15,
			containLabel: true,
		},
		legend: {
			left: 'center',
			top: 'top',
			itemWidth: 9,
			itemHeight: 6,
			textStyle: {
				color: '#8A94A6',
			},
			show: true,
			type: 'scroll',
			data: chartLegend(data),
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'category',
				axisLabel: {
					show: true,
					color: '#8A94A6',
					fontSize: 12,
					interval: 0,
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: '#8A94A6',
					},
				},
				data: regexp.test(data.axisNames[0])
					? data.axisNames.map(item => moment(item).format('YY-MM'))
					: data.axisNames,
			},
		],
		yAxis: [
			{
				type: 'value',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 12,
				},
				axisLine: {
					lineStyle: {
						color: '#8A94A6',
					},
				},
				splitLine: {
					show: false,
				},
			},
		],
	}; // 图表tooltip弹出层提示信息设置
	// if (flag === 'TARGETDETAIL' || flag === 'FIGHT') {

	option.tooltip = {
		trigger: 'axis',
		triggerOn: 'click',
		axisPointer: {
			axis: 'x',
			snap: true,
		},
		position: function (point, params, dom, rect, size) {
			const axisValue = params[0].axisValue;
			dom.style.text = `${axisValue}\n${dom.style.text}`;
		},
		show: true,
		confine: true,
		backgroundColor: 'rgba(0,0,0,0.8)',
		extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.87);',
		textStyle: {
			color: '#fff',
		},
		borderWidth: 0,
	}; // }
	// series数据组装

	for (let i = 0; i < data.viewData.length; i++) {
		if (data.viewData[i].type === 'line') {
			data.viewData[i].lineStyle = {
				type: 'dotted',
			};
		} // 设置双坐标轴索引

		if (data.axisType === 'double') {
			if (data.viewData[i].type === 'line') {
				data.viewData[i].yAxisIndex = 0;
			} else {
				data.viewData[i].yAxisIndex = 1;
				data.viewData[i].itemStyle = {
					borderRadius: [2, 2, 0, 0],
				};
			}
			option.yAxis.push({
				type: 'value',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 12,
				},
				axisLine: {
					lineStyle: {
						color: '#8A94A6',
					},
				},
				splitLine: {
					show: false,
				},
			});

			// if (option.yAxis.length > 2) {
			// 	option.yAxis[2].show = false;
			// }
		}
	} // 设置markLine中位线

	if (data.markLine && data.markLine.length) {
		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];

			for (let j = 0; j < data.markLine[i].length; j++) {
				markLineData.push({
					yAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].name
							? data.markLine[i][j].name
							: '{c}',
						position: 'middle',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: globalColor[j + 1],
					},
				});
			}

			data.viewData[i].markLine = {
				data: markLineData,
				silent: true,
			};
		}
	}

	option.series = data.viewData;

	return option;
};

/**
 * 条形堆叠图
 */
const chartbarXAxisStacked = (data, flag, otherOptions) => {
	const dealData = data => {
		const resData = data.reduce((prev, curr) => {
			let obj = {
				type: 'bar',
				stack: '总量',
				barMaxWidth:'30%',
				barMinWidth:'30%',
				label: {
					show: true,
					position: 'insideLeft',
					color:'#fff',
					// fontSize:8,
					formatter: function (params) {
						if (params.value > 0) {
							return `${Number(params.value-7).toFixed(2)}`;
						} else {
							return '';
						}
					},
				},
				barMinHeight: 20,
				barWidth: '60%',
				itemStyle: {
					borderRadius: [0, 2, 2, 0],
				},
			};

			obj.name = curr.name;
			obj.data = curr.data;
			prev.push(obj);
			return prev;
		}, []);
		return resData;
	};

	//  处理定制指标数据
	// const dealIndicatData = data => {
	// 	let resArr = [];
	// 	let nameArr = [];
	// 	let fliteredData = data.indicatorList
	// 		? data.indicatorList.filter(item => !item.isIndicator)
	// 		: [];

	// 	if (!fliteredData.length > 0)
	// 		return {
	// 			resArr,
	// 			nameArr,
	// 		};

	// 	fliteredData[0].sons.forEach((_, index) => {
	// 		let obj = {
	// 			type: 'bar',
	// 			stack: '总量',
	// 			label: {
	// 				show: true,
	// 				position: 'inside',
	// 				formatter: '{b} {c}',
	// 			},
	// 			barMinHeight: 100,
	// 			barWidth: '60%',
	// 		};

	// 		obj.data = fliteredData.reduce((prev, curr) => {
	// 			if (index === 0) {
	// 				nameArr.push(curr.message);
	// 			}

	// 			let obj = {
	// 				filterValue: curr.message,
	// 				...curr.sons[index],
	// 				canToDetail: !data.isCompany,
	// 				smCondition: curr.smCondition,
	// 			};

	// 			obj.name = curr.sons[index].show;

	// 			prev.push(obj);
	// 			return prev;
	// 		}, []);

	// 		resArr.push(obj);
	// 	});

	// 	return {
	// 		resArr,
	// 		nameArr,
	// 	};
	// };

	let option = {
		animation: false,
		color: globalColor,
		tooltip: {
			show: false,
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'line', // 默认为直线，可选为：'line' | 'shadow'
			},
		},

		grid: {
			show: false,
			top: '8%',
			left: '1%',
			right: '6%',
			bottom: '2%',
			containLabel: true,
		},
		textStyle: {
			fontSize: 12,
		},
		xAxis: {
			type: 'value',
			axisLabel: {
				show: false,
			},
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
			},
			splitLine: {
				show: false,
			},
			max: function (value) {
				return value.max + 10;
				// if (flag === 'INDI') {
				// 	return value.max * 1.6;
				// } else {
				// 	return value.max + 10;
				// }
			},
		},
		yAxis: {
			inverse: true,
			type: 'category',
			data:  data.axisNames,
			axisLabel: {
				show: true,
				color: '#2B2E34',
				fontSize: 12,
				formatter: function (value) {
					if (value.length > 8) {
						return (
							value.substring(0, value.length / 2 + 1) +
							'\n' +
							value.substring(value.length / 2 + 1, value.length)
						);
					} else {
						return value;
					}
				},
			},
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
			},
		},

		series:dealData(data.viewData),
	};

	option.legend = {
		// show:false,
		type: 'scroll',
		textStyle: {
			color: '#2B2E34',
		},
		data: chartLegend(data),
	};

	// if (option.yAxis.data.length > 10) {
	// 	if (flag === 'DETAIL') {
	// 		option.grid.top = 30;
	// 	}

	// 	option.chartHeight = option.yAxis.data.length * 40;
	// }
	return option;
};

/**
 * barXAxisMore（条形图多根柱条）
 */
const chartbarXAxisMore = (flag, data) => {
	let maxObj = {
		max: data.maxValue ? Number(data.maxValue) + 1 : 0,
	};

	let option = {
		animation: true,
		color: globalColor,
		grid: {
			top: '10%',
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		},
		legend: {
			type: 'scroll',
			data: chartLegend(data),
			left: 'center',
			top: 'top',
			itemWidth: 9,
			itemHeight: 6,
			textStyle: {
				color: '#8A94A6',
			},
		},
		textStyle: {
			fontSize: 11,
		},
		xAxis: [
			{
				type: 'value',
				axisLabel: {
					show: false,
					color: '#8A94A6',
					fontSize: 12,
				},
				...maxObj,
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				type: 'category',
				axisLabel: {
					color: '#8A94A6',
					fontSize: 12,
					// formatter: function (value) {
					// 	if (value.length > 8) {
					// 		return (
					// 			value.substring(0, value.length / 2 + 1) +
					// 			'\n' +
					// 			value.substring(value.length / 2 + 1, value.length)
					// 		);
					// 	} else {
					// 		return value;
					// 	}
					// },
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				data: data.axisNames,
			},
		],
	};

	// series数据组装
	for (let i = 0; i < data.viewData.length; i++) {
		option.tooltip = {
			trigger: 'item',
			axisPointer: {
				axis: 'x',
				snap: true,
			},
			backgroundColor: 'rgba(0,0,0,0.8)',
			extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.87);',
			textStyle: {
				color: '#fff',
			},
			confine: true,
			position: function (point, params, dom, rect, size) {
				dom.style.text = `${dom.style.text}`;
			},
		};

		// 设置柱条间隙
		data.viewData[i].barGap = 0.2;

		if (option.yAxis[0].data.length === 1) {
			data.viewData[i].barWidth = '20%';
		}
		if (option.yAxis[0].data.length === 2) {
			data.viewData[i].barWidth = '30%';
		}
		data.viewData[i].barMinHeight = 20;
		data.viewData[i].itemStyle = {
			borderRadius: [0, 2, 2, 0],
		};
		// 设置柱条上数值样式
		data.viewData[i].label = {
			show: true,
			color: '#8A94A6',
			position: 'right',
		};
	}

	// 设置markLine中位线
	if (data.markLine && data.markLine.length) {
		// 如果有中位线重新设置grid
		option.grid = {
			top: '10%',
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		};

		for (let i = 0; i < data.markLine.length; i++) {
			let markLineData = [];

			for (let j = 0; j < data.markLine[i].length; j++) {
				markLineData.push({
					xAxis: data.markLine[i][j].value,
					name: 'standard',
					label: {
						formatter: data.markLine[i][j].value
							? data.markLine[i][j].value
							: '{c}',
						position: 'end',
					},
					lineStyle: {
						color: data.markLine[i][j].color
							? data.markLine[i][j].color
							: globalColor[j],
					},
				});
			}

			data.viewData[i].markLine = {
				data: markLineData,
				silent: true,
			};
		}
	}

	option.series = data.viewData;

	if (option.yAxis[0].data.length > 5) {
		if (flag === 'DETAIL') {
			option.grid.top = 30;
		}
		option.chartHeight = option.yAxis[0].data.length * 50;
	}

	return option;
};

/**
 * 图表数据组装
 * @params data 原数据 Object
 * @params flag 其他标示性参数 String
 * flag = HOME 首页
 * flag = REPORT 报告
 * flag = TARGETDETAIL 图表详情
 */
export const formatChartViewData = (data, flag, chartInverse = {}) => {
	for (let i = 0; i < data.length; i++) {
		switch (data[i].type) {
			case 'line': // 折线图
				data[i].option = chartLine(flag, data[i]);
				break;
			case 'barXAxis': // 条形图
				data[i].option = chartbarXAxis(data[i], chartInverse);
				break;
			case 'barXAxisMore': // 条形图多柱条
				data[i].option = chartbarXAxisMore(flag, data[i]);
				break;
			case 'barYAxis': // 柱状图
				data[i].option = chartbarYAxis(flag, data[i]);
				break;
			case 'barMix': // 条形图组合，正负轴，交付质量和交付效率
				data[i].option = chartbarMix(flag, data[i], chartInverse);
				break;
			case 'pieMore': // 饼图，圆环
				data[i].option = chartPie(flag, data[i]);
				break;
			case 'pie': // 饼图 默认形式
				data[i].option = chartPieDefault(data[i]);
				break;
			case 'gauge': // 仪表盘
				data[i].option = chartGauge(flag, data[i]);
				break;
			case 'radar': // 雷达图，综合能力
				data[i].option = chartRadar(data[i]);
				break;
			case 'barAndLineMix': // 柱状图和折线图组合，可设置多坐标轴
				data[i].option = chartbarAndLineMix(flag, data[i]);
				break;
			case 'barXAxisStacked': // 堆叠条形图
				data[i].option = chartbarXAxisStacked(data[i], flag,chartInverse);
				break;
			case 'lineMix': // 首页产出总数、资源点数和工作量组合
				for (let j = 0; j < data[i].lineMixList.length; j++) {
					// 不展示x轴和y轴
					data[i].lineMixList[j].xAxis = 'false';
					data[i].lineMixList[j].yAxis = 'false';

					data[i].lineMixList[j].option = chartLine(
						flag,
						data[i].lineMixList[j]
					);
				}
				break;
			default:
				break;
		}
	}

	return data;
};


