import React, { useState, useEffect } from "react";
import {
  Progress,
  Select,
  Spin,
  TreeSelect,
  DatePicker,
  message,
  notification,
  Button,
  Tooltip,
} from "antd";
import moment from "moment";
import { getModelId } from "./utils";
import { useHistory } from "react-router-dom";
import rankText from "./images/ranking-text.png";
import th1 from "./images/top-header-1.png";
import tf1 from "./images/top-footer-1.png";
import th2 from "./images/top-header-2.png";
import tf2 from "./images/top-footer-2.png";
import th3 from "./images/top-header-3.png";
import tf3 from "./images/top-footer-3.png";
import ECharts from "../../components/echarts";

import styles from "./styles.less";
const { TreeNode } = TreeSelect;
const RangePicker = DatePicker.RangePicker;

function RankInner({
  parentProps,
  params: { rankingId, rankingType },
  menuType,
}) {
  const [isDetail, setisDetail] = useState(false);
  const [isDept, setIsDept] = useState(false);
  const [isDeptPersonel, setIsDeptPersonel] = useState(false);
  const [deptParams, setDeptParams] = useState({
    rankingId,
  });
  const [deptList, setDeptList] = useState([]);
  const [allPeopleList, setAllPeopleList] = useState([]);
  const [groupTree, setGroupTree] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departType, setDepartType] = useState("全部");
  const [selectedId, setselectedId] = useState("");
  const [detailLoading, setDetailLoading] = useState(false);
  const [type] = useState("RANK");
  const histroy = useHistory();
  const [nodeId, setnodeId] = useState(void 0);
  const [topThree, settopThree] = useState([]);
  const [leftList, setleftList] = useState([]);
  const [chartDataOption] = useState([]);
  const [specialTargetData, setspecialTargetData] = useState([]);
  const [timeRange, settimeRange] = useState([
    `${moment().subtract(1, "M").startOf("M").format("YYYY-MM-DD")} 00:00:00`,
    `${moment().subtract(1, "M").endOf("M").format("YYYY-MM-DD")} 23:59:59`,
  ]);
  const [sortType, setsortType] = useState("1");
  const [lineOption, setlineOption] = useState({});
  const [radarOption, setradarOption] = useState({});
  const [orgTree, setorgTree] = useState([]);
  const [organization, setorganization] = useState([]);
  const [isTarget, setisTarget] = useState(false);
  const [portraitIndexMap, setportraitIndexMap] = useState([]);
  const [portraitMap, setportraitMap] = useState([]);
  const [dateCycle, setdateCycle] = useState("");
  const [structId, setstructId] = useState("");

  // 获取列表
  const { data: { teamSelection = [], groupsData, dateGroupCycleData } = {} } =
    parentProps.ajax.usePost(
      "/api/ranking/detail",
      {
        rankingId,
        nodeId,
        employId: parentProps.loginUser.id,
        rankingType: sortType,
        startTime: timeRange[0],
        endTime: timeRange[1],
      },
      [nodeId, timeRange, sortType],
      {
        formatResult: (res) => {
          if (!res?.data) return;

          const {
            groups = {},
            result = [],
            organization,
            settings = [],
            organizationMap = {},
            dateCycle,
            dateGroupCycle,
          } = res.data;

          let teamArr = [{ rankingId: "ALL", rankingName: "全部" }];
          if (rankingType === 2) {
            return;
          } else {
            detailRankData(result);
            setorgTree(organization || []);
          }

          setdateCycle(dateCycle);

          return {
            teamSelection: teamArr,
            groupsData: groups,
            dateGroupCycleData: dateGroupCycle,
          };
        },
      }
    );
  useEffect(() => {
    if (rankingType === 2) {
      // 获取部门排行榜数据
      parentProps.ajax
        .get(`/api/queryDepartmentRanking/${rankingId}`)
        .then((res) => {
          const { dateCycle, dataList } = res?.data;
          detailRankData(dataList);
          setDeptList(dataList);
          setdateCycle(dateCycle);
        });
      // 获取排行榜模型统计结构
      parentProps.ajax
        .get(`/api/queryModelOrgTreeNode/${rankingId}`)
        .then((res) => {
          setGroupTree([res?.data]);
        });
      // 获取员工绩效排行榜一级组织
      parentProps.ajax
        .get(`/api/firstLeveldepartmentList//${rankingId}`)
        .then((res) => {
          let teamArr = [{ departmentId: "ALL", departmentName: "全部" }];
          setDepartments([...teamArr, ...res?.data]);
        });
    }
  }, [rankingType]);
  // 获取当前部门下的人员或者全员
  const getDeptDetail = () => {
    parentProps.ajax
      .post("/api/queryEmployeeRanking", { ...deptParams })
      .then((res) => {
        setAllPeopleList(res?.data);
        // detailRankData(res?.data);
      });
  };
  useEffect(() => {
    if (rankingType === 2) {
      getDeptDetail();
    }
  }, [deptParams, rankingType]);
  useEffect(() => {
    if (menuType === "0") {
      const selectedName = sessionStorage.getItem("rank");
      const selectedId = sessionStorage.getItem("selectedRankId");
      const paramsData = selectedName ? JSON.parse(selectedName) : {};
      const {
        rankingId: selectedRankingId,
        rankingType,
        aId,
        employId,
        rankingTypes = [],
      } = paramsData;
    } else {
      const selectedName = sessionStorage.getItem("rank-achi");
      const selectedId = sessionStorage.getItem("selectedAchiId");
      const paramsData = selectedName ? JSON.parse(selectedName) : {};
      const {
        rankingId: selectedRankingId,
        rankingType,
        aId,
        employId,
        rankingTypes = [],
      } = paramsData;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuType, rankingId, isDetail, isDept]);
  const detailRankData = (list) => {
    const currentTopThree = list.slice(0, 3);
    const currentLeftList = list.slice(3, list.length);
    settopThree(currentTopThree);
    setleftList(currentLeftList);
  };

  const handleRadarOption = (dataArr) => {
    let indicator =
      dataArr.length > 0
        ? dataArr.map((item) => ({
            name: item.dimensionName,
            max: 100,
          }))
        : [{ name: "", max: 100 }];
    const radarData = [0, 0];
    radarData[0] = {
      value: dataArr.length > 0 ? dataArr.map((item) => item.indexScore) : [0],
      name: "得分",
      areaStyle: {
        normal: {
          opacity: 0.9,
          color: "#4D7CFE70",
        },
      },
      lineStyle: {
        color: "#4D7CFE",
      },
      itemStyle: {
        color: "#4D7CFE",
      },
      symbolSize: 3,
    };
    radarData[1] = {
      value:
        dataArr.map((item) => item.indexScoreAvg).length > 0
          ? dataArr.map((item) => item.indexScoreAvg)
          : [0],
      name: "平均值",
      lineStyle: {
        type: "dashed",
        color: "#E2675B",
        width: 1,
      },
      symbol: "none",
      itemStyle: {
        color: "#E2675B",
      },
      areaStyle: {
        opacity: 0.1,
      },
    };

    return {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        axisPointer: {
          axis: "x",
          snap: true,
        },
        confine: true,
      },
      legend: {
        data: ["得分", "平均值"],
        bottom: 0,
      },
      radar: {
        center: ["50%", "45%"],
        radius: "65%",
        tooltip: {
          show: true,
        },
        indicator,
      },
      series: [
        {
          type: "radar",
          data: radarData,
        },
      ],
    };
  };
  const handlePushDetail = (id) => {
    histroy.push(
      `/front/target-detail?name=${
        [...topThree, ...leftList].filter(
          (item) => item.employeeId === selectedId
        )[0]?.employeeName || "-"
      }&structId=${structId}&indexIds=${id}&userId=${selectedId}&dateCycle=${
        dateCycle || timeRange[0] + "~" + timeRange[1]
      }`
    );
  };
  const getDetail = (params) => {
    setDetailLoading(true);
    parentProps.ajax
      .post("/api/ranking/employeeDetail", { ...params })
      .then((res) => {
        if (!res.data) {
          message.info("暂无数据");
          return false;
        }
        const pivotalIndexMap = res.data?.pivotalIndexResult || [];
        const portraitMap = res.data?.portraitMap || [];
        const rankingHistory = res.data?.rankingHistory || {};
        const currentPortraitIndexMap = res.data?.portraitIndexMap || [];
        const currentStructId = res.data?.statisticsId || "organization";

        let resArr = pivotalIndexMap.map((item) => ({
          name: item.indexName,
          value: item.indexResult,
          id: item.indexId,
        }));

        const lineCategory = [];
        const lineSeriesData = [];
        for (const key in rankingHistory) {
          const ele = rankingHistory[key];
          lineCategory.push(key);
          lineSeriesData.push(ele);
        }

        const currentLineOption = {
          color: "#7196FE",
          tooltip: {
            show: true,
            trigger: "axis",
          },
          grid: {
            top: "8%",
            left: "2%",
            right: "2%",
            bottom: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: lineCategory,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            type: "value",
            inverse: true,
          },
          series: [
            {
              data: lineSeriesData,
              type: "line",
            },
          ],
        };

        if (res.data.error) {
          notification.info({
            message: "提示",
            description: res.data.error,
            duration: 2,
          });
        }
        if (rankingType === 2) {
          setisDetail(true);
          setIsDeptPersonel(false);
        } else {
          setisDetail(true);
        }
        setspecialTargetData(resArr);
        setlineOption(currentLineOption);
        setportraitMap(portraitMap);
        setradarOption(handleRadarOption(portraitMap));
        setportraitIndexMap(currentPortraitIndexMap);
        setstructId(currentStructId);
        setDetailLoading(false);
      })
      .catch(() => {
        setDetailLoading(false);
      });
  };

  async function handleGetDetail({ id, aId, rankingType, rankingId }) {
    let rankingTypeIds = [];
    const rankingTypes = sessionStorage.getItem("rankingTypes");
    try {
      rankingTypeIds =
        JSON.parse(rankingTypes) === null ? [] : JSON.parse(rankingTypes);
    } catch (error) {
      rankingTypeIds = [];
    }
    if (rankingTypeIds && !rankingTypeIds.includes(rankingId)) {
      rankingTypeIds.push(rankingId);
    }
    if (menuType === "0") {
      sessionStorage.setItem("selectedRankId", id);
      sessionStorage.setItem(
        "rank",
        JSON.stringify({
          employId: id,
          rankingType,
          rankingId,
          rankingTypes: rankingTypeIds,
          aId,
        })
      );
    } else {
      sessionStorage.setItem("selectedAchiId", id);
      sessionStorage.setItem(
        "rank-achi",
        JSON.stringify({
          employId: id,
          rankingType,
          rankingId,
          rankingTypes: rankingTypeIds,
          aId,
        })
      );
    }
    sessionStorage.setItem("selectedId", selectedId);
    const params = {
      rankingId,
      achievementId: aId,
      employId: id,
      startTime: timeRange[0],
      endTime: timeRange[1],
    };
    await getDetail(params);
  }
  const handleClickItem = ({ id, aId, rankingType }) => {
    setselectedId(id);
    handleGetDetail({
      id,
      aId,
      rankingType,
      rankingId,
    });
  };

  const handleChangeShowType = () => {
    let currentIsTarget = !isTarget;
    setradarOption(
      handleRadarOption(currentIsTarget ? portraitIndexMap : portraitMap)
    );
    setisTarget(currentIsTarget);
  };

  const tranColor = (name) => {
    let code = "";
    let colorStr = "";
    let backColorStr = "";
    if (name) {
      for (var i = 0; i < name.length; i++) {
        code += name.charAt(i).charCodeAt().toString(16).substring(1, 4);
      }
      if (code.length > 6) {
        code = code.substring(0, 6);
      }
      if (code.length < 6 && code.length > 3) {
        code = code.substring(0, 3);
      }
      if (code.length < 3) {
        code = "5680fa";
      }
      colorStr = `#${code}`;
    }

    backColorStr = `#${code}33`;

    return {
      colorStr,
      backColorStr,
    };
  };

  const subName = (name) => {
    let res = "";
    if (name) {
      res = name.substring(1, 0);
    }
    return res;
  };

  // 遍历树选择
  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={item.id}
            value={item.id}
            disabled={item.disabled}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          value={item.id}
          title={item.title}
          key={item.id}
          disabled={item.disabled}
        />
      );
    });

  const renderFilterCom = () => {
    let node = null;
    switch (rankingType) {
      case 0:
        node = (
          <>
            <div className={styles.lable}>组织</div>
            <TreeSelect
              placeholder="选择所属组织"
              showSearch={true}
              style={{ width: 200 }}
              allowClear={true}
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={(id) => setnodeId(id)}
            >
              {renderTreeNodes(orgTree)}
            </TreeSelect>
            <div className={styles.filterItem}>
              <div className={styles.lable}>类型</div>
              <Select
                style={{ width: 200 }}
                bordered={false}
                onChange={(e) => setsortType(e)}
                defaultValue="1"
              >
                <Select.Option key="1" value="1">
                  光荣榜
                </Select.Option>
                <Select.Option key="2" value="0">
                  垫底榜
                </Select.Option>
              </Select>
            </div>
            <div className={styles.lable}>时间范围</div>
            <RangePicker
              format="YYYY-MM-DD"
              bordered={false}
              defaultValue={[
                moment().subtract(1, "M").startOf("M"),
                moment().subtract(1, "M").endOf("M"),
              ]}
              onChange={(value) => {
                if (!value) return;
                settimeRange([
                  `${moment(value[0]).format("YYYY-MM-DD")} 00:00:00`,
                  `${moment(value[1]).format("YYYY-MM-DD")} 23:59:59`,
                ]);
              }}
            />
          </>
        );
        break;
      case 1:
        node = (
          <>
            <div className={styles.lable}>组织</div>
            <TreeSelect
              placeholder="选择所属组织"
              showSearch={true}
              style={{ width: 200 }}
              allowClear={true}
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={(id) => setnodeId(id)}
            >
              {renderTreeNodes(orgTree)}
            </TreeSelect>
            <div className={styles.filterItem}>
              <div className={styles.lable}>类型</div>
              <Select
                style={{ width: 200 }}
                bordered={false}
                onChange={(e) => setsortType(e)}
                defaultValue="1"
              >
                <Select.Option key="1" value="1">
                  光荣榜
                </Select.Option>
                <Select.Option key="2" value="0">
                  垫底榜
                </Select.Option>
              </Select>
            </div>
            <div className={styles.lable}>周期</div>
            <span>{dateCycle}</span>
          </>
        );
        break;
      case 2:
        // 如果是分组榜，开始时候不展示筛选
        if (!isDept) {
          node = (
            <>
              <div className={styles.lable}>周期</div>
              <span>{dateCycle}</span>
            </>
          );
        } else {
          node = (
            <>
              <div className={styles.lable}>模型统计结构</div>
              <TreeSelect
                placeholder="选择所属组织"
                showSearch={true}
                style={{ width: 200 }}
                allowClear={true}
                bordered={false}
                dropdownMatchSelectWidth={false}
                onChange={(id) => {
                  const modelTree = getModelId(id, groupTree);
                  setDeptParams({
                    ...deptParams,
                    modelTree,
                  });
                  setnodeId(id);
                }}
                value={nodeId}
              >
                {renderTreeNodes(groupTree)}
              </TreeSelect>
              <div className={styles.lable}>一级组织</div>
              <Select
                bordered={false}
                style={{ width: 200 }}
                value={departType}
                onChange={(val, option) => {
                  setDepartType(val);
                  const { children } = option;
                  setDeptParams({
                    ...deptParams,
                    departmentName: children,
                  });
                }}
              >
                {departments.map((item, index) => (
                  <Select.Option key={index} value={item.departmentName}>
                    {item.departmentName}
                  </Select.Option>
                ))}
              </Select>
              <div className={styles.lable}>周期</div>
              <span>{dateCycle}</span>
            </>
          );
        }
        break;
      default:
        break;
    }

    return node;
  };

  const renderFn = () => {
    if (isDetail) {
      return rankDetailCompent();
    } else {
      return rankMianCompent();
    }
  };
  const renderDeptFn = () => {
    if (!isDept) {
      return rankDeptCompent();
    }
    if (isDetail) {
      return rankDetailCompent();
    }
    if (isDeptPersonel) {
      return rankMianCompent();
    }
  };
  // 当点击部门下某一个部门进入部门里面的员工排行榜
  const handleClickdept = ({ departmentName }) => {
    setIsDept(true);
    setIsDeptPersonel(true);
    setDepartType(departmentName);
    setDeptParams({
      rankingId,
      departmentName,
    });
    detailRankData(allPeopleList);
  };
  const handleSwitchChange = (value) => {
    // 当value为false时展示全员榜,否则是部门榜
    if (value) {
      // 渲染部门榜的数据
      setIsDept(false);
      setisDetail(false);
      setIsDeptPersonel(false);
      setnodeId(void 0);
      detailRankData(deptList);
    } else {
      // 渲染全员榜数据
      setIsDept(true);
      setisDetail(false);
      setIsDeptPersonel(true);
      setDepartType("全部");
      setnodeId(void 0);
      setDeptParams({
        rankingId,
      });
      detailRankData(allPeopleList);
    }
  };
  const rankMianCompent = () => (
    <div className={styles.rankContainer}>
      <img src={rankText} alt="text" className={styles.rankText} />
      <div className={styles.rankContext}>
        <div className={styles.topThree}>
          <div
            className={styles.topLeft}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                id: topThree[1]?.employeeId,
                aId: topThree[1]?.achievementId,
                rankingType,
              });
            }}
          >
            <img src={th2} alt="th2" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[1]?.employeeName)}
            </div>
            <img src={tf2} alt="tf2" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[1]?.employeeName}</p>
            <p className={styles.score}>{topThree[1]?.avgScore || 0}</p>
          </div>
          <div
            className={styles.topCenter}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                id: topThree[0]?.employeeId,
                aId: topThree[0]?.achievementId,
                rankingType,
              });
            }}
          >
            <img src={th1} alt="th1" className={styles.topIcon} />
            <div className={styles.topRankWarp}>
              <div className={styles.portrait}>
                {subName(topThree[0]?.employeeName)}
              </div>
            </div>
            <img src={tf1} alt="tf1" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[0]?.employeeName}</p>
            <p className={styles.score}>{topThree[0]?.avgScore || 0}</p>
          </div>
          <div
            className={styles.topRight}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClickItem({
                id: topThree[2]?.employeeId,
                aId: topThree[2]?.achievementId,
                rankingType,
              });
            }}
          >
            <img src={th3} alt="th3" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[2]?.employeeName)}
            </div>
            <img src={tf3} alt="tf3" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[2]?.employeeName}</p>
            <p className={styles.score}>{topThree[2]?.avgScore || 0}</p>
          </div>
        </div>
        <div className={styles.other}>
          {leftList.map((item, index) => (
            <div
              className={styles.otherRankItem}
              style={{
                cursor: "pointer",
              }}
              key={index}
              onClick={() => {
                handleClickItem({
                  id: item.employeeId,
                  aId: item.achievementId,
                  rankingType,
                });
              }}
            >
              {/* <div className={styles.num}>{item.order}</div> */}
              <div className={styles.num}>{index + 4}</div>
              <div
                className={styles.avtor}
                style={{
                  border: `1px solid ${tranColor(item.employeeName).colorStr}`,
                  backgroundColor: tranColor(item.employeeName).backColorStr,
                  color: tranColor(item.employeeName).colorStr,
                }}
              >
                {subName(item.employeeName)}
              </div>
              <div className={styles.name}>{item.employeeName}</div>
              <div className={styles.pro}>
                <Progress
                  percent={item?.avgScore}
                  showInfo={false}
                  strokeWidth={4}
                />
              </div>
              <div className={styles.score}>{item?.avgScore || 0}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  const rankDetailCompent = () => (
    <div className={styles.rankDetailContainer}>
      <div className={styles.leftRank}>
        <div className={styles.topThree}>
          <div
            className={`${styles.topLeft} ${
              selectedId === topThree[1]?.employeeId ? styles.topActive : ""
            }`}
            onClick={() =>
              handleClickItem({
                id: topThree[1]?.employeeId,
                aId: topThree[1]?.achievementId,
                rankingType,
              })
            }
          >
            <img src={th2} alt="th2" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[1]?.employeeName)}
            </div>
            <img src={tf2} alt="tf2" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[1]?.employeeName}</p>
            <p className={styles.score}>{topThree[1]?.avgScore || 0}</p>
          </div>
          <div
            className={`${styles.topCenter} ${
              selectedId === topThree[0]?.employeeId ? styles.topActive : ""
            }`}
            onClick={() =>
              handleClickItem({
                id: topThree[0]?.employeeId,
                aId: topThree[0]?.achievementId,
                rankingType,
              })
            }
          >
            <img src={th1} alt="th1" className={styles.topIcon} />
            <div className={styles.topRankWarp}>
              <div className={styles.portrait}>
                {subName(topThree[0]?.employeeName)}
              </div>
            </div>
            <img src={tf1} alt="tf1" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[0]?.employeeName}</p>
            <p className={styles.score}>{topThree[0]?.avgScore || 0}</p>
          </div>
          <div
            className={`${styles.topRight} ${
              selectedId === topThree[2]?.employeeId ? styles.topActive : ""
            }`}
            onClick={() =>
              handleClickItem({
                id: topThree[2]?.employeeId,
                aId: topThree[2]?.achievementId,
                rankingType,
              })
            }
          >
            <img src={th3} alt="th3" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[2]?.employeeName)}
            </div>
            <img src={tf3} alt="tf3" className={styles.bottomIcon} />
            <p className={styles.name}>{topThree[2]?.employeeName}</p>
            <p className={styles.score}>{topThree[2]?.avgScore || 0}</p>
          </div>
        </div>
        <div className={styles.other}>
          {leftList.map((item, index) => (
            <div
              className={`${styles.otherRankItem} ${
                selectedId === item.employeeId ? styles.otherActive : ""
              }`}
              key={index}
              onClick={() =>
                handleClickItem({
                  id: item.employeeId,
                  aId: item.achievementId,
                  rankingType,
                })
              }
            >
              {/* <div className={styles.num}>{item.order}</div> */}
              <div className={styles.num}>{index + 4}</div>
              <div
                className={styles.avtor}
                style={{
                  border: `1px solid ${tranColor(item.employeeName).colorStr}`,
                  backgroundColor: tranColor(item.employeeName).backColorStr,
                  color: tranColor(item.employeeName).colorStr,
                }}
              >
                {subName(item.employeeName)}
              </div>
              <div className={styles.name}>{item.employeeName}</div>
              <div className={styles.pro}>
                <Progress
                  percent={item?.avgScore || 0}
                  showInfo={false}
                  strokeWidth={4}
                />
              </div>
              <div className={styles.score}>{item?.avgScore || 0}</div>
              {selectedId === item.employeeId && (
                <div className={styles.arrow}></div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.bottomMask}></div>
      </div>
      <div className={styles.reportRight}>
        <Spin spinning={detailLoading}>
          {/* <div className={styles.tabBtnWarp}>
							{tabGroup.map(item => (
								<div
									className={`${styles.btnItem} ${
										type === item.key ? styles.btnSel : ''
									}`}
									key={item.key}
									onClick={() => settype(item.key)}
								>
									{item.name}
								</div>
							))}
						</div> */}
          {renderRankorTar()}
        </Spin>
      </div>
    </div>
  );
  // 绩效榜的部门榜
  const rankDeptCompent = () => (
    <div className={styles.rankContainer}>
      <img src={rankText} alt="text" className={styles.rankText} />
      <div className={styles.rankContext}>
        <div className={styles.topThree}>
          <div
            className={styles.topLeft}
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              handleClickdept({
                departmentName: topThree[1]?.departmentName,
              })
            }
          >
            <img src={th2} alt="th2" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[1]?.departmentName)}
            </div>
            <img src={tf2} alt="tf2" className={styles.bottomIcon} />
            <Tooltip placement="topLeft" title={topThree[1]?.departmentName}>
              <div className={styles.name}>{topThree[1]?.departmentName}</div>
            </Tooltip>
            <p className={styles.score}>{topThree[1]?.avgScore}</p>
          </div>
          <div
            className={styles.topCenter}
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              handleClickdept({
                departmentName: topThree[0]?.departmentName,
              })
            }
          >
            <img src={th1} alt="th1" className={styles.topIcon} />
            <div className={styles.topRankWarp}>
              <div className={styles.portrait}>
                {subName(topThree[0]?.departmentName)}
              </div>
            </div>
            <img src={tf1} alt="tf1" className={styles.bottomIcon} />
            <Tooltip placement="topLeft" title={topThree[0]?.departmentName}>
              <div className={styles.name}>{topThree[0]?.departmentName}</div>
            </Tooltip>
            <p className={styles.score}>{topThree[0]?.avgScore}</p>
          </div>
          <div
            className={styles.topRight}
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              handleClickdept({
                departmentName: topThree[2]?.departmentName,
              })
            }
          >
            <img src={th3} alt="th3" className={styles.topIcon} />
            <div className={styles.portrait}>
              {subName(topThree[2]?.departmentName)}
            </div>
            <img src={tf3} alt="tf3" className={styles.bottomIcon} />
            <Tooltip placement="topLeft" title={topThree[2]?.departmentName}>
              <div className={styles.name}>{topThree[2]?.departmentName}</div>
            </Tooltip>
            <p className={styles.score}>{topThree[2]?.avgScore}</p>
          </div>
        </div>
        <div className={styles.other}>
          {leftList.map((item, index) => (
            <div
              className={styles.otherRankItem}
              style={{ cursor: "pointer" }}
              key={index}
              onClick={() =>
                handleClickdept({
                  departmentName: item.departmentName,
                })
              }
            >
              <div className={styles.num}>{index + 4}</div>
              <div
                className={styles.avtor}
                style={{
                  border: `1px solid ${
                    tranColor(item.departmentName).colorStr
                  }`,
                  backgroundColor: tranColor(item.departmentName).backColorStr,
                  color: tranColor(item.departmentName).colorStr,
                }}
              >
                {subName(item.departmentName)}
              </div>
              <Tooltip placement="topLeft" title={item.departmentName}>
                <div className={styles.name}>{item.departmentName}</div>
              </Tooltip>
              <div className={styles.pro}>
                <Progress
                  percent={item?.avgScore}
                  showInfo={false}
                  strokeWidth={4}
                />
              </div>
              <div className={styles.score}>{item?.avgScore || 0}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  const renderRankorTar = () => {
    let node = (
      <React.Fragment>
        <div className={styles.rankTab}>
          <div className={styles.chartWarp} style={{ borderTop: "none" }}>
            <div className={styles.chartItem}>
              <div className={styles.chartContent}>
                <div className={styles.chartTitle}>
                  <div>总体画像</div>
                  <Button
                    onClick={handleChangeShowType}
                    type="primary"
                    className={styles.chartBtn}
                  >
                    {isTarget ? "维度" : "指标"}
                  </Button>
                </div>
                <div className={styles.chartTitle}>
                  {
                    [...topThree, ...leftList].filter(
                      (item) => item.employeeId === selectedId
                    )[0]?.employeeName
                  }
                </div>
              </div>
              <div style={{ height: "80%", width: "100%" }}>
                <ECharts option={radarOption} />
              </div>
            </div>
          </div>
          <div className={styles.chartWarp}>
            <div className={styles.chartItem} style={{ borderRight: "none" }}>
              <div className={styles.chartContent}>历史排名</div>
              <div style={{ height: "80%", width: "100%" }}>
                <ECharts option={lineOption} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.specialTarget}>
          <div className={styles.chartContent}>绩效指标</div>
          <div className={styles.tableWarp}>
            {specialTargetData.map((item, index) => (
              <div className={styles.tableItem} key={index}>
                <div className={styles.tableTh}>
                  <div className={styles.text}>{item.name}</div>
                </div>
                <div
                  className={styles.tableTd}
                  onClick={() => handlePushDetail(item.id)}
                >
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );

    if (type === "TAR") {
      node = (
        <div className={styles.rankTab}>
          <div className={styles.chartWarp}>
            {chartDataOption
              .filter((ele) => !!ele.option)
              .map((item, index) => (
                <div
                  className={styles.chartItem}
                  key={index}
                  style={
                    index % 2 === 0
                      ? {
                          borderBottom: "1px solid #00000023",
                        }
                      : {
                          borderRight: "none",
                          borderBottom: "1px solid #00000023",
                        }
                  }
                >
                  <div className={styles.chartTitle}>{item.title}</div>
                  {item && item.option ? (
                    <ECharts
                      style={{ height: "80%", width: "100%" }}
                      option={item.option}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    }

    return node;
  };

  return (
    <div className={styles.rankInner}>
      <div className={styles.filterWarp}>
        <div className={styles.filterItem}>{renderFilterCom()}</div>
        {rankingType === 2 && (
          <div className={styles.filterRight}>
            {/*todo 来回展示切换*/}
            {/* <Switch checkedChildren="部门榜" unCheckedChildren="全员榜" checked={switchChecked}  onChange={handleSwitchChange}/> */}
            {!isDept && (
              <Button onClick={() => handleSwitchChange(false)}>全员榜</Button>
            )}
            {isDetail && (
              <Button onClick={() => handleSwitchChange(true)}>部门榜</Button>
            )}
            {isDeptPersonel && (
              <Button onClick={() => handleSwitchChange(true)}>部门榜</Button>
            )}
          </div>
        )}
      </div>
      <div className={styles.container}>
        {rankingType === 2 ? renderDeptFn() : renderFn()}
      </div>
    </div>
  );
}

export default RankInner;
