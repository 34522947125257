import { createFromIconfontCN } from "@ant-design/icons";
import PropTypes from "prop-types";

/**
 * font icon使用方法：
 * <Icon type="icon-kafka"/>
 * 参考：
 * https://ant.design/components/icon-cn/#components-icon-demo-iconfont
 * https://www.iconfont.cn/
 * */
const IconFont = createFromIconfontCN({
    // 具体项目自己在 iconfont.cn 上创建 scriptUrl   
    // scriptUrl: ["//at.alicdn.com/t/c/font_3754220_mtecw6rgzvr.js"],
    // 这里防止服务器无法访问外网，改为本地引用
    scriptUrl: '/icon-font.js',
});

IconFont.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    rotate: PropTypes.number,
    spin: PropTypes.bool,
    twoToneColor: PropTypes.string,
};

export default IconFont;
