/**
 * echarts基础组件
 * @param option 数据 typeof Object
 * @param style 样式 typeof Object
 * @param clickEvent 数据是否绑定点击事件，如钻取时会用到 typeof Function
 * @param onClick 整体点击事件 typeof Function
 */
import React, { Component } from 'react';
import * as echarts from 'echarts';

class ECharts extends Component {
	componentDidMount() {
		const { option, clickEvent, getNodes, flag } = this.props;

		this.farEcharts = echarts.init(this.echarts, null, { devicePixelRatio: 1.5 });

		if (flag === 'INDEX') {
			if (option.series[0].type === 'line' || option.series[0].type === 'pie') {
				// 动态显示tootip
				let faultByHourIndex = 0;
				this.faultByHourTime = setInterval(() => {
					// tootip每隔三秒自动显示
					this.farEcharts.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: faultByHourIndex,
					});
					this.farEcharts.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: faultByHourIndex,
					});
					this.farEcharts.dispatchAction({
						type: 'downplay',
						seriesIndex: 0,
						dataIndex: Array.from({
							length: option.series[0].data.length,
						})
							.map((_, index) => index)
							.filter(item => item !== faultByHourIndex),
					});

					faultByHourIndex++;

					if (faultByHourIndex > option.series[0].data.length) {
						faultByHourIndex = 0;
					}
				}, 5000);
			}
		}

		option && this.farEcharts.setOption(option);

		if (getNodes) {
			getNodes(this.echarts);
		}
		if (clickEvent) {
			this.bindClickEvent();
		}
	}

	componentWillUnmount() {
		this.farEcharts = null;
		clearInterval(this.faultByHourTime);
		this.faultByHourTime = null;
	}

	componentWillReceiveProps = nextProps => {
		if (this.props.loading !== nextProps.loading && nextProps.loading) {
			this.farEcharts.showLoading({
				textColor: '#fff',
				maskColor: 'rgba(0, 0, 0, 0.4)',
			});
		}
		if (this.props.loading !== nextProps.loading && !nextProps.loading) {
			this.farEcharts.hideLoading();
		}
		if (this.props.otherOption !== nextProps.otherOption && !!nextProps.otherOption) {
			this.setOptionFn(nextProps.otherOption)
		}
		if (this.props.option !== nextProps.option) { 
			nextProps.option && this.farEcharts.setOption(nextProps.option, true);
		}
	};

	echarts = null;

	faultByHourTime = null;

	farEcharts = null;

	/**
	 * 绑定echarts点击事件
	 */
	bindClickEvent = () => {
		this.farEcharts.on('click', params => {
			this.props.clickEvent(params);
		});
	};

	setOptionFn = (opt) => {
		this.farEcharts.setOption(opt);
	}

	render() {
		const { style, onClick = () => {}, otherOption  } = this.props;
		if (this.farEcharts) {
			otherOption && this.farEcharts.setOption(otherOption);
			setTimeout(() => {
				if (this.farEcharts) {
					this.farEcharts.resize();
				}
			}, 30);
		}
		return (
			<div
				ref={fn => (this.echarts = fn)}
				style={style}
				onClick={onClick}
			></div>
		);
	}
}

export default ECharts;
