import React, { useState, useEffect } from "react";
import { Modal, Spin, message, Breadcrumb, Empty } from "antd";
import { handleData, handleDate } from "./utils";
import style from "./style.less";
import { UniveralEcharts } from "../../components";
import { formatChartViewData } from "../../commons/chart-view-templete-new";

const TargetDetail = (props) => {
  const { visible, targetCondition, onCancel, date, tabSel, titleProps } =
    props;
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [breadList, setBreadList] = useState([]);
  const getData = (params, bread) => {
    const {
      data: { blockIndexId, nodeId, down, nodeName, structId, indexId },
    } = params;
    // 可以下钻
    if (down) {
      setLoading(true);
      return new Promise((reolve, reject) => {
        props.ajax
          .get("/cockpit/block", {
            endTime: date,
            blockId: tabSel,
            blockIndexId,
            nodeId,
          })
          .then((res) => {
            if (res.code === 0) {
              const isEmpty = res.data.length;
              const chartList = isEmpty ? handleData(res.data) : [];
              //bread为true 需要设置
              if (bread) {
                setBreadList([...breadList, { data: params, title: nodeName }]);
              }
              setDataSource(isEmpty ? formatChartViewData(chartList) : []);
              setLoading(false);
              reolve();
            } else {
              message.error(res.message);
              setLoading(false);
              reject();
            }
          })
          .catch(() => {
            setLoading(false);
            reject();
          });
      });
    } else {
      const newDate = handleDate(date);
      let isCockit = true;
      window.open(
        `/front/target-detail?name=${nodeName}&isCockit=${isCockit}?indexIds=${indexId}&userId=${nodeName}&dateCycle=${
          newDate[0] + "~" + newDate[1]
        }&structId=${structId}`,
        "_blank"
      );
    }
  };
  const handleDrill = (params, bread) => {
    getData(params, bread);
  };
  const handleClickBread = (params, index) => {
    if (index === 0) {
      handleDrill(params, false);
      setBreadList([titleProps]);
    } else {
      const currentList = breadList.slice(0, index + 1);
      setBreadList(currentList);
      handleDrill(params, false);
    }
  };
  const renderCharts = (dataSource) => {
    return dataSource.map((item, index) => {
      return (
        <div className={style.chartItem} key={index}>
          <div className={style.chartTitle}>
            <p className={style.name}>{item.title}</p>
          </div>
          <div className={style.chartContent}>
            {item.option ? (
              <UniveralEcharts
                clickEvent={(params) => handleDrill(params, true)}
                style={{ height: "90%", width: "100%" }}
                option={item.option}
              />
            ) : null}
          </div>
        </div>
      );
    });
  };
  const handleClose = () => {
    setDataSource([]);
    onCancel();
  };

  /**
   *  渲染面包屑数据
   */
  const renderBread = (title) => {
    return (
      <Breadcrumb separator="|">
        {breadList.map((item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                handleClickBread(item.data, index);
              }}
              style={{ cursor: "pointer" }}
            >
              {item.title}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };
  const renderTitle = (title) => {
    return (
      <div className={style.topWarp}>
        <p className={style.leftTitle}>{title}</p>
      </div>
    );
  };
  const renderEmpty = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "300px",
          width: "100%",
        }}
      >
        <Empty style={{ width: "100%" }} />
      </div>
    );
  };
  useEffect(() => {
    if (targetCondition.length) {
      setLoading(false);
      setBreadList([{ ...titleProps }]);
      setDataSource(targetCondition);
    } else {
      setLoading(true);
    }
  }, [targetCondition, titleProps]);
  return (
    <div>
      <Modal
        visible={visible}
        footer={null}
        width={1000}
        onCancel={handleClose}
        destroyOnClose={true}
        title={
          breadList.length ? renderBread() : renderTitle(titleProps?.title)
        }
      >
        {dataSource.length ? (
          <Spin spinning={loading}>
            <div className={style.modalWarp}>
              <div className={style.chartContainer}>
                {renderCharts(dataSource)}
              </div>
            </div>
          </Spin>
        ) : (
          renderEmpty()
        )}
      </Modal>
    </div>
  );
};

export default TargetDetail;
