import React, { useState, useEffect } from "react";
import config from "src/commons/config-hoc";
import { PageContent, getQuery } from "@ra-lib/admin";
import { dateyTypeEnum } from './constant'
import { Breadcrumb, Spin } from "antd";
import moment from "moment";
import styles from "./style.less";
import RankListDetail from "./component/ListDetail";
export default config({
  path: "/front/achievement-detail",
  title: "绩效方案数据分析",
})(function AchievementDetail(props) {
  const [breadList, setBreadList] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [loading,setLoading]= useState(false)
  const [detailParams, setDetailParams] = useState({});
  const [employeeParams,setEmployeeParams] = useState({})
  const {
    cycleType,
    cycleValue,
    employId: dataId,
    schemeId,
    structId,
    startTime,
    endTime,
    schemeName,
    employeeName,
    recordsId,
  } = getQuery();
  const getInitailData = async () => {
    setLoading(true)
    const query = {
      schemeId,
      cycleType,
      cycleValue,
      departmentId: "",
      positionId: "",
    };
    let res
    if(recordsId){
      res = await props.ajax.post(`/api/achievement/scheme/querySchemeCalculateDetail`,{
        recordsId,
        pageNum:1,
        pageSize:1000,
      })
      setFullData([...res?.data?.data]);
      setInitialData([...res?.data?.data]);
    }else{
       res = await props.ajax.post(`/api/achievement/scheme/queryRanking`, {
      ...query,
    });
    setFullData([...res.data]);
    setInitialData([...res.data]);
    }
    setEmployeeParams({
      employId:dataId,
      employeeName:employeeName,
    })
    handleClickItem(
      {
        employId: dataId,
        employeeName: employeeName,
      },
      true
    );
    setLoading(false)
    setBreadList([
      {
        name: "默认",
        cycleType,
        cycleValue,
        schemeId,
      },
    ]);
  };
  const handleBreadList = (params) => {
    setBreadList([...breadList, { ...params }]);
  };
  /***
   * @params
   * item 员工的属性
   * isFirst 是否第一次加载，使用不同的schemeId
   */
  const handleClickItem = (item, isFirst) => {
    const { employId, employeeName } = item;
    setEmployeeParams({
      employId,
      employeeName
    })
    setDetailParams({
      ...detailParams,
      schemeId: isFirst ? schemeId : breadList.at(-1).schemeId,
      cycleType,
      cycleValue,
      structId,
      employeeName,
      startTime: moment(moment(startTime, "YYYY-MM-DD").toDate()).format(
        "YYYY-MM-DD"
      ),
      endTime: moment(moment(endTime, "YYYY-MM-DD").endOf("D").toDate()).format(
        "YYYY-MM-DD"
      ),
      employId,
    });
  };
  useEffect(() => {
    getInitailData();
  }, []);

  const handleClickBread = (item, index) => {
    console.log(breadList,'breadList')
    console.log(employeeParams,'sss')
    const currentList = breadList.slice(0, index + 1);
    if (index === 0) {
      getInitailData();
    } else if (index === breadList.length - 1) {
      return;
    } else {
      setDetailParams({
        ...detailParams,
        ...item,
        ...employeeParams,
        structId,
        startTime: moment(moment(startTime, "YYYY-MM-DD").toDate()).format(
          "YYYY-MM-DD"
        ),
        endTime: moment(
          moment(endTime, "YYYY-MM-DD").endOf("D").toDate()
        ).format("YYYY-MM-DD"),
      });
      setFullData(initialData);
    }
    setBreadList(currentList);
  };
  /**
   *  渲染面包屑数据
   */
  const renderBread = () => {
    return (
      <Breadcrumb separator="/">
        {breadList.map((item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                handleClickBread(item, index);
              }}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };
  const renderHeader = () => {
    return (
      <div className={styles.header}>
       <div className={styles.headerItem} style={{marginLeft:0}}>
          <span className={styles.label}>绩效方案:</span>
          <span className={styles.value}>{schemeName}</span>
        </div>
        {/* <div className={styles.schemeName}>绩效方案{schemeName}</div> */}
        <div className={styles.headerItem}>
          <span className={styles.label}>周期类型:</span>
          <span className={styles.value}>{dateyTypeEnum[cycleType]}</span>
        </div>
        <div className={styles.headerItem}>
          <span className={styles.label}>周期:</span>
          <span className={styles.value}>{cycleValue}</span>
        </div>
        <div className={styles.headerItem}>
          <span className={styles.label}>周期:</span>
          <span className={styles.value}>
            {startTime}~{endTime}
          </span>
        </div>
      </div>
    );
  };
  return (
    <PageContent fitHeight className={styles.achievementDetail}>
    <Spin spinning={loading}>
      <div className={styles.achievementDetailContent}>
        <div>{renderHeader()}</div>
        <div style={{ marginTop: 10 }}>{renderBread()}</div>
        <div>
          <RankListDetail
            parentProps={props}
            fullData={fullData}
            handleClickItem={handleClickItem}
            detailParams={detailParams}
            handleBreadList={handleBreadList}
            setFullData={setFullData}
          ></RankListDetail>
        </div>
      </div>
      </Spin>
    </PageContent>
  );
});
