import moment from "moment";
export const getDate = (type) => {
  let date = [];
  switch (type) {
    case "currentWeek":
      date = [moment().startOf("W"), moment().endOf("W")];
      break;
    case "lastWeek":
      date = [
        moment().subtract(1, "weeks").startOf("W"),
        moment().subtract(1, "weeks").endOf("W"),
      ];
      break;
    case "currentMonth":
      date = [moment().startOf("M"), moment().endOf("M")];
      break;
    case "lastMonth":
      date = [
        moment().subtract(1, "months").startOf("M"),
        moment().subtract(1, "months").endOf("M"),
      ];
      break;
    case "currentQuarter":
      date = [moment().startOf("Q"), moment().endOf("Q")];
      break;
    case "lastQuarter":
      const startDate = moment()
        .quarter(moment().quarter() - 1)
        .startOf("quarter");
      const endDate = moment()
        .quarter(moment().quarter() - 1)
        .endOf("quarter");
      date = [startDate, endDate];
      break;
    default:
      break;
  }
  return date;
};

export const handleColumnsData = (columns, dataSource) => {
  if (dataSource && dataSource.length) {
    columns.forEach((item) => {
      item.value = dataSource[0][item.dataIndex];
      item.employeeId = dataSource[0]["employeeId"];
      item.employeeName = dataSource[0]["employeeName"];
    });
  }
  return columns;
};
