export const hanldeRoot = (res) => {
  let obj = new Object();
  let random = Math.random();
  obj.id = `${random}-${res.nodeId}`;
  obj.nodeType = "root" || res.nodeType;
  obj.nodeName = res.nodeName;
  obj.indexResult = res.indexResult || "";
  obj.nodeWeight = res.nodeWeight;
  obj.structId = res.structId;
  obj.parentId = res.parentId;
  obj.triggerCondition = res.triggerCondition;
  if (res !== undefined && res !== null && res.length !== 0) {
    obj.children = handleData(res.next);
  }
  return obj;
};

export const handleData = (res) => {
  let ret = [];
  for (let index = 0; index < res.length; index++) {
    let struct = res[index];
    let random = Math.random();
    let obj = new Object();
    obj.id = `${struct.nodeId}`;
    obj.parentid = struct.parentId;
    obj.nodeType = struct.nodeType;
    obj.nodeName = struct.nodeName;
    obj.indexResult = struct.indexResult || "";
    obj.nodeWeight = struct.nodeWeight;
    obj.structId = struct.structId;
    obj.type = struct.nodeType === 1?'topic-child':'topic'
    obj.width = struct.nodeType === 1 ? 200:86
    obj.height = struct.nodeType === 1 ? 43:27
    obj.triggerCondition = struct.triggerCondition;
    if (
      struct.nodeType === 0 &&
      struct.next !== undefined &&
      struct.next !== null &&
      struct.next.length !== 0
    ) {
      obj.children = handleData(struct.next);
    }
    ret.push(obj);
  }
  return ret;
};

export const handleAssembleData = (res) => {
  let obj = new Object();
  obj.id = "root";
  obj.nodeType = "root";
  obj.nodeName = "制度";
  obj.type = 'topic';
  obj.width = 86;
  obj.height = 27
  if (res !== undefined && res !== null && res.length !== 0) {
    obj.children = handleData(res);
  }
  return obj;
};


export const findNodeById = (id,treeNodes) => {
  for (let i = 0; i < treeNodes.length; i++) {
    const node = treeNodes[i];

    if (node.structId === id) {
      return node;
    }
    if (node.next && node.next.length > 0) {
      const newNode = findNodeById(id, node.next);
      if (newNode) {
        return newNode;
      }
    }
  }
  return null;
}