const dateType = {
    month:"1",
    quarter:"2",
    halfYear:"3",
    year:"4",
    custom:"5"
}
export const dateyTypeEnum = {
    [dateType.month]:"月份",
    [dateType.quarter]:"季度",
    [dateType.halfYear]:"半年",
    [dateType.year]:"年",
    [dateType.custom]:"自定义"
}