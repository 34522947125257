import React, { useEffect } from "react";
import { Spin } from "antd";
import { setLoginUser, getQuery, setToken } from "@ra-lib/admin";
import config from "src/commons/config-hoc";
import { locationHref } from "src/commons";

export default config({
  path: "/front/empty-login",
  auth: false,
  layout: false,
})(function EmptyLogin(props) {
  const { sso_login = "" } = getQuery();
  const goHome = (menus) => {
    if (!menus.length) {
      locationHref("/front/empty");
    } else {
      const { config } = menus[0];
      locationHref(JSON.parse(config).link);
    }
  };
  const getResourceData = async () => {
    let token = ''
    if(sso_login){
      const data = JSON.parse(decodeURIComponent(atob(sso_login))) || {};
      token = data.token
    }
    console.log(decodeURIComponent(atob(sso_login)),'sss')
    console.log(token,'token')
    try {
      const res = await props.ajax.get(
        `/sso/login?token=${token || ""}`,
        {},
        {
          errorTip: false,
        }
      );
      if (res.code === 0) {
        const {
          data: { userId, username, ...others },
          map
        } = res;

        const userInfo = {
          id: userId,
          name: username, // 必须字段
          ...others,
        };

        if (map?.token) {
          userInfo.token = map.token;
          setToken(map.token);
        }
        setLoginUser(userInfo);
        goHome(res?.data?.frontResources);
      } else {
        props.history.push("/front/error-login");
      }
    } catch (error) {
      const { code ,msg } = error;
      if (code === 301) {
        window.location.href = msg;
      } else {
        props.history.push("/front/error-login");
      }
    }
  };
  useEffect(() => {
    getResourceData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Spin tip="登录中" size="large"></Spin>
    </div>
  );
});
